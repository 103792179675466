import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import * as yup from "yup";
import { useMutation } from "@apollo/client";
import {
    NewPassword,
    NewPasswordVariables,
} from "gql/types/operation-result-types";
import { useTranslationList } from "../use-translations-list";
import NEW_PASSWORD from "./new-password.gql";
import { useObjectValidations } from "validation/validaion";
import { errorHandler } from "services/error-handler";
import { successHandler, SuccessTypes } from "services/success-handler";

export interface INewPassword {
    password: string;
    rePassword: string;
}

export function useNewPassword(queryId: string, onSuccess?: () => void) {
    const initialValues = {
        password: "",
        rePassword: "",
    };
    const { push } = useHistory();
    const [__] = useTranslation();
    const { FIELD_SHOULD_BE_FILLED, DOES_NOT_MATCH_WITH_PASSWORD } =
        useTranslationList();
    const { passwordRule } = useObjectValidations();

    const [mutation] = useMutation<NewPassword, NewPasswordVariables>(
        NEW_PASSWORD,
    );

    const [validationSchema] = useState(() =>
        yup.object().shape({
            password: passwordRule(),
            rePassword: yup
                .string()
                .required(FIELD_SHOULD_BE_FILLED)
                .test({
                    message: DOES_NOT_MATCH_WITH_PASSWORD,
                    test(value) {
                        return value === this.parent.password;
                    },
                }),
        }),
    );

    const onSubmit = async ({ password, rePassword }: typeof initialValues) => {
        try {
            await mutation({
                variables: { password, rePassword, id: queryId },
            });
            successHandler(SuccessTypes.EDIT_ACCOUNT_PASSWORD);
            if (!onSuccess) {
                push("/sign-in");
            } else {
                onSuccess();
            }
        } catch (e: any) {
            void errorHandler(e, () => __("Ошибка смены пароля"));
        }
    };

    return {
        validationSchema,
        onSubmit,
        initialValues,
    };
}
