import { useTranslation } from "react-i18next";
import { MobileProducts } from "./mobile-products";
import { DesktopProducts } from "./desktop-products";
import { ProductsWrapper } from "./styled";
interface IInjectedProps {}

interface IProps extends IInjectedProps {}

export const AnimatedProducts = React.memo((props: IProps) => {
    const [__] = useTranslation();
    const data = {
        pc: {
            pcTitle: __("Лендинги"),
            pcText: __("Более 100 готовых лендинг страниц"),
        },
        tablet1: {
            tabletTitle: __("Баннера"),
            tabletText: __("Большой выбор баннеров любых размеров"),
        },
        tablet2: {
            tabletTitle: __("Ссылки"),
            tabletText: __(
                "Привлекайте новых пользователей ссылками на актуальные события",
            ),
        },
        phone: {
            phoneTitle: __("Реклама в мобильных"),
            phoneText: __("Большой выбор баннеров любых размеров"),
        },
    };
    return (
        <ProductsWrapper>
            <DesktopProducts data={data} />
            <MobileProducts data={data} />
        </ProductsWrapper>
    );
}) as React.ComponentType<Subtract<IProps, IInjectedProps>>;
