/* eslint-disable no-nested-ternary */
import React, { useState } from "react";
import { AuthLayout } from "../auth-layout";
import { Utils } from "@webx/utils";
import { SingUpForm } from "./form/by-partner/default";
import { SignUpHelmet } from "components/helmet/sign-up-helmet";
import { Stage } from "hooks/sign-up/use-on-submit";
import { useDefinedContext } from "hooks/use-context-exist";
import { PartnersProgramGeneralInformationContext } from "views";
import { DefaultTheme, StyledComponent } from "styled-components";

interface IProps {
    signBox?: StyledComponent<"div", DefaultTheme>;
}

export const SignUp = React.memo(({ signBox }: IProps) => {
    const [stage, setStage] = useState(Stage.SIGN_UP);
    const partnersGeneralInformation = useDefinedContext(
        PartnersProgramGeneralInformationContext,
    );

    return (
        <AuthLayout signBox={signBox}>
            <SignUpHelmet
                script={
                    (stage === Stage.ERROR &&
                        partnersGeneralInformation.seoScriptsAndTags
                            .unsuccessfullRegistrationScript) ||
                    null
                }
            />
            <SingUpForm setStage={setStage} />
            {stage === Stage.SUCCESS && <Redirect />}
        </AuthLayout>
    );
});

const Redirect = () => {
    if (Utils.browser.hasWindow()) {
        document.location.replace(`${window.location.origin}/sign-up-complete`);
    }
    return <></>;
};
