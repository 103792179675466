import {
    GetNewsList,
    GetNewsListVariables,
} from "gql/types/operation-result-types";
import { usePooling } from "hooks/use-pooling";
import { useQuery } from "@apollo/client";
import GET_NEWS_LIST from "../gql/get-news-list.gql";

export const useNews = (code: string, typeNews: string) => {
    const queryResult = useQuery<GetNewsList, GetNewsListVariables>(
        GET_NEWS_LIST,
        {
            ssr: true,
            variables: {
                params: {
                    culture: code,
                    typeNews,
                },
            },
        },
    );

    const status = g(
        queryResult,
        "data",
        "partnersProgram",
        "news",
        "getNewsList",
        "statusID",
    );
    const newsList = g(
        queryResult,
        "data",
        "partnersProgram",
        "news",
        "getNewsList",
        "news",
    );

    usePooling(queryResult, status, 500);

    return {
        status,
        newsList,
        error: queryResult.error,
        loading: queryResult.loading,
    };
};
