import { ConditionalList } from "components/conditional-list";
import { GetNewsList_partnersProgram_news_getNewsList_news } from "gql/types/operation-result-types";
import { useLanguage } from "components/language-provider";
import * as _ from "lodash";
import { useTranslation } from "react-i18next";
import { useNews } from "../hooks/use-news";
import { NewsCard } from "./news-card";
import { NoData as NoDataText } from "components/no-data";
import {
    CategoryAnnotation,
    CategoryTitle,
    NewsContainer,
    NoDataContainer,
    NoData,
} from "./styled";
import Helmet from "react-helmet-with-visor";
import { useDefinedContext } from "hooks/use-context-exist";
import { PartnersProgramGeneralInformationContext } from "views";
import { _1XBET_PARTNERS_IRELAND } from "server/partner-programs";
interface IInjectedProps {}

interface IProps extends IInjectedProps {}

export const AllNewsList = React.memo((props: IProps) => {
    const [__] = useTranslation();
    const { language } = useLanguage();
    const [intropart1, intropart2] = __("Новости {separator}компании").split(
        "{separator}",
    );
    const { loading, error, newsList } = useNews(language.code, "blog");
    const {
        partnerProgramCompanyName,
        agentSiteInformation,
        partnersProgramId,
    } = useDefinedContext(PartnersProgramGeneralInformationContext);

    let description = format(
        __(
            "Новости {PartnerCompanyName}. Рекомендации по заработку в онлайн-режиме.",
        ),
        {
            PartnerCompanyName: partnerProgramCompanyName,
        },
    );

    if (partnersProgramId === _1XBET_PARTNERS_IRELAND) {
        description = format(
            __(
                "Новости о {PartnerCompanyName} и рекомендации по получению дохода в Интернете.",
            ),
            { PartnerCompanyName: partnerProgramCompanyName },
        );
    }

    return (
        <>
            <Helmet>
                <title>
                    {format(
                        __(
                            "Новости | {PartnerCompanyName} | Партнерская программа {CompanyName}",
                        ),
                        {
                            PartnerCompanyName: partnerProgramCompanyName,
                            CompanyName: agentSiteInformation.companyName,
                        },
                    )}
                </title>
                <meta name="description" content={description} />
            </Helmet>
            <CategoryTitle
                as={
                    partnersProgramId === _1XBET_PARTNERS_IRELAND
                        ? "h1"
                        : undefined
                }
            >
                <span>{intropart1}</span>
                <span>{intropart2}</span>
            </CategoryTitle>
            <CategoryAnnotation>
                {__("Последние новости и акции, проводимые у нас")}
            </CategoryAnnotation>

            <ConditionalList
                error={error}
                loading={loading}
                data={newsList}
                renderEmpty={() => (
                    <NoDataContainer>
                        <NoData size={50} />
                        <NoDataText />
                    </NoDataContainer>
                )}
                renderExist={(
                    data: GetNewsList_partnersProgram_news_getNewsList_news[],
                ) => (
                    <NewsContainer>
                        {_.map(data, item => (
                            <NewsCard
                                id={item.id}
                                key={item.id}
                                newsLink={item.url}
                                newsDataTime={item.datePublick}
                                newsTitle={item.title}
                                newsExcerpt={item.shortText}
                                language={language.code}
                                newsImgUrl={item.PImage}
                            />
                        ))}
                    </NewsContainer>
                )}
            />
        </>
    );
}) as React.ComponentType<Subtract<IProps, IInjectedProps>>;
