import { SelectSC } from "components/formik/universal-field/fields/select/styled";
import { StyledUniversalField } from "components/formik/universal-field/styled";
import { textInputs } from "polished";
import { media } from "services/styled-components/variables";
import styled from "styled-components";
import { ButtonToolbar } from "styled/button";
import { Title } from "services/styled-components/typogoraphy";
import {
    backgroundColor0,
    disabledColor,
} from "services/styled-components/themes";

export const SignBox = styled.div`
    max-width: 1200px;
    margin: 10px;
    padding: 20px;
    color: ${props => props.theme.authTextColor};
    background-color: ${props => props.theme.authFormColor};
    ${media.xs`
        padding: 30px 50px;
    `}

    ${StyledUniversalField} {
        width: 325px;
        max-width: 100%;
        margin: 0;
        border: 0;
        ${media.xsMax`
            width: 100%;
        `}/* > input,
        > ${SelectSC} {
            margin-bottom: 1.5em;
        } */
    }
    a {
        text-decoration: underline;
        &:hover {
            text-decoration: none;
        }
    }
    ${textInputs()} {
        color: ${props => props.theme.primaryColor};
        background-color: ${backgroundColor0};
    }

    input[disabled] {
        background-color: ${disabledColor};
    }

    ${ButtonToolbar} {
        display: flex;
        justify-content: space-around;
        a {
            color: ${props => props.theme.authTextColor};
            &:hover,
            &.active {
                color: ${props => props.theme.activeColor};
            }
        }
    }
    ${Title} {
        color: ${props => props.theme.authTextColor};
    }
`;
