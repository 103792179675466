import { PrivacyPolicy } from "gql/types/operation-result-types";
import { useQuery, gql } from "@apollo/client";

const GET_PRIVACY_POLICY = gql`
    query PrivacyPolicy {
        partnersProgram {
            privacyPolicy
        }
    }
`;

export const usePrivacyPolicy = () => {
    const { data, loading } = useQuery<PrivacyPolicy>(GET_PRIVACY_POLICY, {
        ssr: true,
    });
    let text = data?.partnersProgram?.privacyPolicy || "";
    text = text.replace(/<p>&nbsp;<\/p>/gm, "").replace(/&nbsp;/gm, "");

    return {
        text,
        loading,
    };
};
