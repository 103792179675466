import { CountryFlag, CountryFlagFieldSC } from "./styled";

interface IInjectedProps {}

interface IProps extends IInjectedProps {
    country: any;
}

export const CountryFlagField = React.memo((props: IProps) => {
    return (
        <CountryFlagFieldSC>
            <CountryFlag className={props.country} />
        </CountryFlagFieldSC>
    );
}) as React.ComponentType<Subtract<IProps, IInjectedProps>>;
