import { ApolloClient } from "@apollo/client";
import { PageRoute } from "components/check-roles/guard";
import { NotFoundHandler } from "components/not-found-handler";
import React from "react";
import { RouteComponentProps, Switch } from "react-router";
import { Wrapper, WrapperFullWidth } from "styled/layouts";
import urljoin from "url-join";
import { AllNewsList } from "./all-news";
import { CurrentNews } from "./current-news";

interface IInjectedProps
    extends RouteComponentProps<Record<string, string | undefined>> {}

interface IProps extends IInjectedProps {
    client: ApolloClient<unknown>;
}

export const News = React.memo((props: IProps) => {
    const { url } = props.match;

    return (
        <WrapperFullWidth>
            <Wrapper>
                <Switch>
                    <PageRoute path={urljoin(url)} component={AllNewsList} />
                    <PageRoute
                        path={urljoin(url, ":name")}
                        component={CurrentNews}
                    />
                    <PageRoute component={NotFoundHandler} path="*" />
                </Switch>
            </Wrapper>
        </WrapperFullWidth>
    );
}) as React.ComponentType<Subtract<IProps, IInjectedProps>>;
