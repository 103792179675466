import { SelectSC } from "components/formik/universal-field/fields/select/styled";
import { StyledUniversalField } from "components/formik/universal-field/styled";
import { textInputs } from "polished";
import { Paragraph } from "services/styled-components/typogoraphy";
import { media } from "services/styled-components/variables";
import styled from "styled-components";

export const SignUpWrapper = styled.div`
    position: relative;
    margin-bottom: 10px;
    ${Paragraph} {
        ${media.sm`
            margin-left: -5px;
            margin-right: -5px;
            display: flex;
        `}
        /* stylelint-disable */
        > div,
        > button,
        > a {
            vertical-align: bottom;
            width: 100%;
            ${media.sm`
                margin: 5px;
                flex: 1 1 auto;
                width: 50%;
            `}
        }
        ${StyledUniversalField} {
            ${textInputs()} {
                width: 100%;
            }
        }
        ${SelectSC} {
            width: 100%;
        }
    }
    label {
        font-size: 14px;
        margin-bottom: 5px;
        display: inline-block;
    }
`;

export const SignUpContainer = styled.div`
    display: flex;
    flex-direction: column;
    ${SignUpWrapper} {
        width: 100%;
    }
    ${media.md`
        flex-direction: row;
        margin-left: -15px;
        margin-right: -15px;
        ${SignUpWrapper} {
            margin-left: 15px;
            margin-right: 15px;
            flex: 1;
        }
    `}
`;

export const SignUpSubmit = styled.div`
    text-align: center;
    button {
        max-width: 240px !important;
    }
    label {
        font-size: 13px;
    }
    ${StyledUniversalField} {
        width: 100%;
    }
`;
