import styled from "styled-components";

export const InfoMessageWrapper = styled.div`
    max-width: 25em;
    background-color: #253b51;
    color: #fff;
    font-family: Arial, Helvetica, sans-serif;
    border-radius: 0 0 8px 8px;
    box-shadow: rgba(0, 0, 0, 0.9) 1px 2px 7px;
`;

export const InfoMessageTitle = styled.h2`
    font-size: 1.5em;
    font-weight: bold;
    padding: 0.5em;
    background: #fff;
    color: #000;
    border-radius: 8px 8px 0 0;
`;

export const InfoMessageMainBlock = styled.div`
    padding: 0.5em;
`;

export const InfoMessageSubTitle = styled.h3`
    font-size: 0.8em;
    font-weight: bold;
    padding: 0 0.2em 0.5em;
    text-align: left;
`;

export const ListItems = styled.ul`
    list-style: disc;
    text-align: left;
    padding: 0 0 1em 1.5em;
    font-size: 0.8em;
`;

export const ConfirmButton = styled.button`
    background: #85c23b;

    & svg {
        padding-right: 0.2em;
    }
`;
