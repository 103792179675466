import { Handshake } from "@styled-icons/fa-solid/Handshake/Handshake";
import { Attention } from "components/attention";
import { Access } from "components/check-roles/access";
import { exceptRoles, auth } from "components/check-roles/rules";
import { useTranslation } from "react-i18next";
import { WrapperFullWidth } from "styled/layouts";
import { WrapperFullWidthPicture, WrapperGeography } from "./styled";
import { AnimateWhenInViewport } from "components/components-common/animate";
import {
    IButtonGradientProps,
    SingUpButton,
} from "components/header/components/sing-up-button";

interface IProps {
    title: JSX.Element;
    picture: JSX.Element;
    textColor: string;
    bgColor?: string;
    buttonGradientProps?: IButtonGradientProps;
}

export const BecomeAnAffilate = React.memo((props: IProps) => {
    const [__] = useTranslation();

    return (
        <>
            <WrapperFullWidth>
                <WrapperGeography textColor={props.textColor}>
                    {props.title}
                </WrapperGeography>
            </WrapperFullWidth>
            <Access guards={[exceptRoles([auth])]}>
                <Attention>
                    <SingUpButton
                        id="become-an-affiliate_sign-up"
                        {...props.buttonGradientProps}
                    >
                        <Handshake size={20} />
                        {__("Стать партнером")}
                    </SingUpButton>
                </Attention>
            </Access>
            <WrapperFullWidthPicture bgColor={props.bgColor}>
                <WrapperGeography textColor={props.textColor}>
                    <AnimateWhenInViewport animateType="fadeInUpShort">
                        {props.picture}
                    </AnimateWhenInViewport>
                </WrapperGeography>
            </WrapperFullWidthPicture>
        </>
    );
}) as React.ComponentType<IProps>;
