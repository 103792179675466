import { Newspaper } from "@styled-icons/fa-regular/Newspaper/Newspaper";
import { AnimateWhenInViewport } from "components/components-common/animate";
import { Attention } from "components/attention";
import { ConditionalList } from "components/conditional-list";
import { LocalizedNavLinkWithOptionalBackground } from "components/routing/localized-navlink-with-optional-background";
import { GetNewsList_partnersProgram_news_getNewsList_news } from "gql/types/operation-result-types";
import { useLanguage } from "components/language-provider";
import * as _ from "lodash";
import { useTranslation } from "react-i18next";
import { Wrapper, WrapperFullWidth } from "styled/layouts";
import { useNews } from "views/landing/news/hooks/use-news";
import { OneNews } from "./one-news";
import { NoData } from "components/no-data";
import { CompanyNewsAnnotation, CompanyNewsSC } from "./styled";
import { Title } from "styled/layouts";
import { IButtonGradientProps } from "components/header/components/sing-up-button";

interface IInjectedProps {}

interface IProps extends IInjectedProps {
    textColor?: string;
    buttonGradientProps?: IButtonGradientProps;
}

export const CompanyNews = React.memo((props: IProps) => {
    const [__] = useTranslation();
    const { language } = useLanguage();
    const { loading, error, newsList } = useNews(language.code, "actual");
    const [intropart1, intropart2] = __("Новости {separator}компании").split(
        "{separator}",
    );

    return (
        <WrapperFullWidth>
            <Wrapper>
                <Title color={props?.textColor}>
                    <span>{intropart1}</span>
                    <span>{intropart2}</span>
                </Title>
                <CompanyNewsAnnotation>
                    {__("Последние новости и акции")}
                </CompanyNewsAnnotation>
                <Attention>
                    <LocalizedNavLinkWithOptionalBackground
                        showBackground={true}
                        to="/news"
                        {...props.buttonGradientProps}
                    >
                        <Newspaper size={20} /> {__("Все новости")}
                    </LocalizedNavLinkWithOptionalBackground>
                </Attention>
                <ConditionalList
                    loading={loading}
                    error={error}
                    data={newsList}
                    textColor={props?.textColor}
                    renderEmpty={() => <NoData textColor={props.textColor} />}
                    renderExist={(
                        data: GetNewsList_partnersProgram_news_getNewsList_news[],
                    ) => (
                        <CompanyNewsSC>
                            {_.map(data, (item, id) => (
                                <AnimateWhenInViewport
                                    animateType="fadeInUpShort"
                                    animateDelay={250 * id}
                                    key={id}
                                >
                                    <OneNews
                                        oneNewsLink={`/news/${item.url}`}
                                        oneNewsImg={item.PImage}
                                        language={language.code}
                                        oneNewsTitle={item.title}
                                        oneNewsTime={item.datePublick}
                                        oneNewsIntroduction={item.shortText}
                                        textColor={props?.textColor}
                                    />
                                </AnimateWhenInViewport>
                            ))}
                        </CompanyNewsSC>
                    )}
                />
            </Wrapper>
        </WrapperFullWidth>
    );
}) as React.ComponentType<Subtract<IProps, IInjectedProps>>;
