import { ColorTypes, Spiner } from "components/loaders/spiner";
import { ReactElement } from "react";
import { useTranslation } from "react-i18next";
import { NoData } from "components/no-data";
import { ApolloError } from "@apollo/client";
import { ErrorText } from "./styled";

interface IInjectedProps {}

interface IProps extends IInjectedProps {
    data?: unknown[] | null;
    renderExist: (arr: unknown[]) => ReactElement;
    renderEmpty: (options?: unknown) => ReactElement;
    error: ApolloError | undefined;
    loading: boolean;
    spinnerColor?: ColorTypes;
    textColor?: string;
}

export const ConditionalList = React.memo((props: IProps): ReactElement => {
    const [__] = useTranslation();
    const { data, renderExist, renderEmpty, error, loading, spinnerColor } =
        props;

    if (loading) {
        return <Spiner color={spinnerColor} />;
    }

    if (error) {
        return (
            <ErrorText style={{ color: props?.textColor }}>
                {__("При получении данных произошла ошибка")}
            </ErrorText>
        );
    }

    if (!data) {
        return <NoData />;
    }

    if (data && data.length > 0) {
        return renderExist(data);
    } else {
        return renderEmpty();
    }
}) as React.ComponentType<Subtract<IProps, IInjectedProps>>;

export { ErrorText };
