import { useTranslation } from "react-i18next";

export const LabelWithLink = () => {
    const [__] = useTranslation();
    const [part1, part2, part3] = __(
        "Я согласен с правилами и условиями и {separator} подтверждаю своё согласие{separator} на обработку и передачу третьим лицам своих персональных данных.",
    ).split("{separator}");

    return (
        <label htmlFor="CheckboxField">
            {part1}
            <a href="/config-files/terms.pdf" target="_blank">
                {part2}
            </a>
            {part3}
        </label>
    );
};
