import { useTranslation } from "react-i18next";
import { Paragraph } from "services/styled-components/typogoraphy";
import { MessageBox } from "styled/message-box";
import { RouteComponentProps } from "react-router-dom";
import { AuthLayout } from "views/auth/auth-layout";
import { useMutation, gql } from "@apollo/client";
import {
    UnsubscribeFromEmail,
    UnsubscribeFromEmailVariables,
} from "gql/types/operation-result-types";
import { useEffect, useState } from "react";
import { errorHandler } from "services/error-handler";

interface IInjectedProps extends RouteComponentProps<{ oneOffToken: string }> {}

interface IProps extends IInjectedProps {}

const UNSUBSCRIBE_FROM_EMAIL = gql`
    mutation UnsubscribeFromEmail($oneOffToken: String!) {
        partnersProgram {
            unsubscribeFromEmail(oneOffToken: $oneOffToken)
        }
    }
`;

export const Unsubscribe = ((props: IProps) => {
    const [__] = useTranslation();
    const [unsubscribe] = useMutation<
        UnsubscribeFromEmail,
        UnsubscribeFromEmailVariables
    >(UNSUBSCRIBE_FROM_EMAIL);
    const [unsubscribed, setUnsubscribed] = useState(false);
    useEffect(() => {
        const unsubscribeFromEmail = async () => {
            try {
                const oneOffToken = props.match.params.oneOffToken;
                if (oneOffToken) {
                    await unsubscribe({
                        variables: { oneOffToken },
                    });
                    setUnsubscribed(true);
                }
            } catch (e: any) {
                void errorHandler(e);
            }
        };
        void unsubscribeFromEmail();
    }, [unsubscribe, props.match.params.oneOffToken, __]);

    return (
        <AuthLayout>
            <MessageBox>
                {!unsubscribed ? (
                    <Paragraph align="center">
                        {__("Отписка от рассылки...")}
                    </Paragraph>
                ) : (
                    <>
                        <Paragraph align="center">
                            {__("Вы успешно отписаны от этой рассылки.")}
                        </Paragraph>
                        <Paragraph align="center">
                            {__(
                                "Вы больше не будете получать информацию об обновлении продукта и объявления только из этой рассылки.",
                            )}
                        </Paragraph>
                        <Paragraph align="center">
                            {__(
                                "Вы можете управлять своими подписками со страницы своего аккаунта.",
                            )}
                        </Paragraph>
                    </>
                )}
            </MessageBox>
        </AuthLayout>
    );
}) as React.ComponentType<Subtract<IProps, IInjectedProps>>;
