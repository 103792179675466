import { CookiesPolicy } from "gql/types/operation-result-types";
import { useQuery, gql } from "@apollo/client";

const GET_COOKIES_POLICY = gql`
    query CookiesPolicy {
        partnersProgram {
            cookiesPolicy
        }
    }
`;

export const useCookiesPolicy = () => {
    const { data, loading } = useQuery<CookiesPolicy>(GET_COOKIES_POLICY, {
        ssr: true,
    });
    let policy = data?.partnersProgram?.cookiesPolicy || "";
    policy = policy.replace(/<p>&nbsp;<\/p>/gm, "").replace(/&nbsp;/gm, "");

    return {
        policy,
        loading,
    };
};
