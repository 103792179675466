import { useTranslation } from "react-i18next";
import { Field } from "components/formik";

export function WebsiteField() {
    const [__] = useTranslation();

    return (
        <Field
            name="website"
            type="text"
            placeholder={"https://domain.com"}
            label={`${__("Сайт")} *`}
        />
    );
}
