import { titleColor } from "services/styled-components/themes";
import styled from "styled-components";
import { rtl } from "services/styled-components/rtl";

export const OtherNewsSC = styled.aside`
    flex: 1 1 35%;
    ${rtl`margin-left: 2rem;`}
`;
export const OtherNewsTitle = styled.div`
    padding: 20px 0;
    line-height: 1.43;
    font-size: 0.875em;
    font-weight: 700;
    text-transform: uppercase;
    color: ${titleColor};
`;
