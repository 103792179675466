import { centerBlock } from "services/styled-components/variables";
import { media } from "services/styled-components/variables";
import styled from "styled-components";

export const CompanyNewsAnnotation = styled.p`
    text-align: center;
    color: ${props => props.theme.mutedTextColor};
    max-width: 450px;
    margin-bottom: 2em;
    ${centerBlock}
`;

export const CompanyNewsSC = styled.div`
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 30px;

    > * {
        padding-bottom: 45px;
        ${media.xs`
        width: calc(100% / 2);
        &:nth-of-type(2n + 1) {
            padding-right: 15px;
        }
        &:nth-of-type(2n + 2) {
            padding-left: 15px;
        }
    `}
        ${media.sm`
        width: calc(100% / 3);
        &:nth-of-type(3n + 1) {
            padding-right: 20px;
            padding-left: 0;
        }
        &:nth-of-type(3n + 2) {
            padding-right: 10px;
            padding-left: 10px;
        }
        &:nth-of-type(3n + 3) {
            padding-right: 0;
            padding-left: 20px;
        }
    `}
    }
`;
