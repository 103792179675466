import { Skype } from "@styled-icons/fa-brands/Skype/Skype";
import { TelegramPlane } from "@styled-icons/fa-brands/TelegramPlane/TelegramPlane";
import { Envelope } from "@styled-icons/fa-solid/Envelope/Envelope";
import { LocalizedNavLinkWithOptionalBackground } from "components/routing/localized-navlink-with-optional-background";
import { useDefinedContext } from "hooks/use-context-exist";
import { useTranslation } from "react-i18next";
import { PartnersProgramGeneralInformationContext } from "views";
import { Sep } from "./styled";
import { formatTGName } from "services/project-utils";
import { getSpecialTelegramName } from "./helpers";

interface IInjectedProps {}

interface IProps extends IInjectedProps {
    inMobileMenu?: boolean;
}

export const Messengers = React.memo((props: IProps) => {
    const [__] = useTranslation();
    const { email, telegramm, skype, partnersProgramId } = useDefinedContext(
        PartnersProgramGeneralInformationContext,
    );

    const specialTelegramName = getSpecialTelegramName(partnersProgramId);

    return (
        <>
            {email && (
                <li>
                    <a
                        href={`mailto:${email}`}
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        <Envelope size={props.inMobileMenu ? 17 : 13} />
                        {email}
                    </a>
                </li>
            )}
            {skype && (
                <>
                    {!props.inMobileMenu && <Sep />}
                    <li>
                        <a
                            href={skype}
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            <Skype size={props.inMobileMenu ? 20 : 13} />
                            {skype.replace("skype:", "")}
                        </a>
                    </li>
                </>
            )}
            {telegramm && (
                <>
                    {!props.inMobileMenu && <Sep />}
                    <li>
                        <a
                            href={telegramm}
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            <TelegramPlane
                                size={props.inMobileMenu ? 20 : 13}
                            />
                            {specialTelegramName || formatTGName(telegramm)}
                        </a>
                    </li>
                </>
            )}
            {!props.inMobileMenu && (
                <>
                    <Sep />
                    <li>
                        <LocalizedNavLinkWithOptionalBackground to="/terms">
                            {__("Правила и условия")}
                        </LocalizedNavLinkWithOptionalBackground>
                    </li>
                </>
            )}
        </>
    );
}) as React.ComponentType<Subtract<IProps, IInjectedProps>>;
