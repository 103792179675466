import { useDefinedContext } from "hooks/use-context-exist";
import { PartnersProgramGeneralInformationContext } from "views";
import { Capabilities } from "../capabilities";
import { Features } from "../features";
import { FirstScreen } from "../first-screen";
import { WelcomePanel } from "../first-screen/welcome-panel";
import { BecomeAnAffilate } from "../become-an-affilate";
import { Partner } from "../partner";
import { BecomeAnAffiliateTitle } from "../become-an-affilate/become-an-affilate-title";
import { useTranslation } from "react-i18next";
import { AnimatedProducts } from "../become-an-affilate/products";
import { ContentBg } from "./styled";
import { TestimonialsSlider } from "components/components-common/slider";
import { Title } from "styled/layouts";

interface IProps {}

export const Home = React.memo((props: IProps) => {
    const partnersGeneralInformation = useDefinedContext(
        PartnersProgramGeneralInformationContext,
    );
    const [__] = useTranslation();

    const landing = partnersGeneralInformation.landingMainPage;
    // этот раздел относиться только к регулярному лендингу,
    // без этой инфы нет смысла продолжать
    if (!landing) {
        return null;
    }
    const colorPartner = partnersGeneralInformation.stylesTheme.accentColor;

    return (
        <ContentBg>
            <FirstScreen>
                <WelcomePanel textColor={"#ffffff"} bgColor={"#373737"} />
            </FirstScreen>
            <Features termsIcons={landing.termsIcons} />
            <Capabilities
                capabilities={landing.capabilities}
                textColor={"#ffffff"}
            />
            <BecomeAnAffilate
                title={
                    <BecomeAnAffiliateTitle
                        firstPartTitle={""}
                        secondPartTitle={__("Продукты на любой вкус")}
                        annotation={__(
                            "Наши продукты позволяют привлекать большой траффик и серьезную прибыль!",
                        )}
                    />
                }
                picture={<AnimatedProducts />}
                textColor={"#ffffff"}
            />
            <Partner
                importantPartnerTitle={landing.importantPartnerTitle}
                currencyName={landing.currencyName}
                colorPartner={colorPartner}
            />
            <TestimonialsSlider
                textColor={"#ffffff"}
                componentTitle={<Title>{__("Отзывы")}</Title>}
                activeColor={partnersGeneralInformation.stylesTheme.accentColor}
                numberOfSlides={1}
            />
        </ContentBg>
    );
}) as React.ComponentType<IProps>;
