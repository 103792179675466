import { useTranslation } from "react-i18next";
import { LanguageField as _LanguageField } from "components/filters/fields/language";

export function LanguageField() {
    const [__] = useTranslation();
    return (
        <_LanguageField
            name="language"
            key="language"
            label={`${__("Предпочитаемый язык")} *`}
            selectFirst={true}
            isClearable={false}
        />
    );
}
