import { useTranslation } from "react-i18next";
import { Wrapper, DecorLine } from "styled/layouts";
import { PartnerAmount, PartnerTitle, WrapperFullWidthPartner } from "./styled";
import { ButtonStartEarning } from "./button-start-earning";
import { IButtonGradientProps } from "components/header/components/sing-up-button";
interface IProps {
    importantPartnerTitle: string;
    currencyName: string | null;
    colorPartner: string;
    buttonGradientProps?: IButtonGradientProps;
}

export const Partner = React.memo((props: IProps) => {
    const [__] = useTranslation();
    const [intropart1, intropart2] =
        props.importantPartnerTitle.split("{separator}");

    return (
        <WrapperFullWidthPartner>
            <Wrapper>
                <img src={"/config-files/human"} />
                <PartnerTitle>
                    <span>{intropart1}</span>
                    <span>{intropart2}</span>
                </PartnerTitle>
                <DecorLine />
                {props.currencyName && (
                    <PartnerAmount colorPartner={props.colorPartner}>
                        <span>{props.currencyName}</span>
                        {__("в месяц")}
                    </PartnerAmount>
                )}
                <ButtonStartEarning {...props.buttonGradientProps} />
            </Wrapper>
        </WrapperFullWidthPartner>
    );
}) as React.ComponentType<IProps>;
