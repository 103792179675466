import styled from "styled-components";

export const WrapperCenter = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
`;

export const PartnerAmount = styled.div`
    margin-bottom: 1.875em;
    font-style: italic;

    span {
        font-style: normal;
        margin: 0 10px 0 0;
        font-size: 3em;
        color: ${props => props.theme.accentColor};
    }
`;
