import { GetPartnersProgramGeneralInfo_partnersProgram_generalInformation_landingMainPage_termsIcons } from "gql/types/operation-result-types";
import { Wrapper, WrapperFullWidth } from "styled/layouts";
import { FeaturesSC, FeatureTitle, FeatureAnnotation } from "./styled";
import { FeaturesItems } from "../../features/features-items";
import { useDefinedContext } from "hooks/use-context-exist";
import { PartnersProgramGeneralInformationContext } from "views";

interface IInjectedProps {}

interface IProps extends IInjectedProps {
    termsIcons: GetPartnersProgramGeneralInfo_partnersProgram_generalInformation_landingMainPage_termsIcons[];
}

export const Features = React.memo((props: IProps) => {
    const { agentSiteInformation } = useDefinedContext(
        PartnersProgramGeneralInformationContext,
    );
    return (
        <WrapperFullWidth>
            <Wrapper>
                <FeatureTitle>Основные преимущества</FeatureTitle>
                <FeatureAnnotation>
                    Получите Больше от вашего трафика с партнерской программой
                    от букмекерской компании «{agentSiteInformation.companyName}
                    »
                </FeatureAnnotation>
                <FeaturesSC>
                    <FeaturesItems termsIcons={props.termsIcons} />
                </FeaturesSC>
            </Wrapper>
        </WrapperFullWidth>
    );
}) as React.ComponentType<IProps>;
