import { Lock } from "@styled-icons/fa-solid/Lock/Lock";
import { User } from "@styled-icons/fa-solid/User/User";
import { SubmitButton } from "../formik/submit-button/submit-button";
import { LocalizedNavLinkWithOptionalBackground } from "../routing/localized-navlink-with-optional-background";
import { Field, Form } from "components/formik";
import { useTranslation } from "react-i18next";
import { ButtonToolbar } from "styled/button";
import {
    FormDirectionColumn,
    FormGroup,
    FormGroupButton,
} from "styled/layouts";
import React, { useRef } from "react";
import { SignInType } from "hooks/sign-in/use-sign-in";
import { ReCAPTCHA, TReCAPTCHA } from "components/recaptcha";
import { FormikConsoleErrors } from "components/formik/formik-console-errors";

interface IProps {
    login: SignInType["login"];
}

export const SimpleSignIn = ({ login }: IProps) => {
    const [__] = useTranslation();
    const recaptchaRef = useRef<TReCAPTCHA>(null);

    return (
        <>
            <FormikConsoleErrors
                initialValues={login.initialValues}
                onSubmit={async (...args) => {
                    //  onSubmit нужно сначала создать т.к. ему нужен recaptcha компонент
                    const onSubmit = login.createOnSubmit(recaptchaRef.current);
                    await onSubmit(...args);
                }}
                validationSchema={login.validationSchema}
            >
                {() => (
                    <Form>
                        <FormDirectionColumn>
                            <FormGroup>
                                <User size={18} />
                                <Field
                                    name="login"
                                    type="text"
                                    placeholder={__("Имя пользователя")}
                                    autoComplete="on"
                                    autoFocus={true}
                                />
                            </FormGroup>
                            <FormGroup>
                                <Lock size={18} />
                                <Field
                                    name="password"
                                    type="password"
                                    placeholder={__("Пароль")}
                                />
                            </FormGroup>
                            <FormGroup>
                                <ReCAPTCHA
                                    ref={recaptchaRef}
                                    size="invisible"
                                />
                            </FormGroup>
                            <FormGroupButton>
                                <SubmitButton>{__("Вход")}</SubmitButton>
                            </FormGroupButton>
                        </FormDirectionColumn>
                    </Form>
                )}
            </FormikConsoleErrors>
            <ButtonToolbar>
                <LocalizedNavLinkWithOptionalBackground to="/reset-password">
                    {__("Забыли пароль ?")}
                </LocalizedNavLinkWithOptionalBackground>
                <LocalizedNavLinkWithOptionalBackground to="/sign-up">
                    {__("Регистрация")}
                </LocalizedNavLinkWithOptionalBackground>
            </ButtonToolbar>
        </>
    );
};
