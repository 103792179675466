import styled, { css } from "styled-components";
import { media } from "services/styled-components/variables";

export const DesktopProductsWrapper = styled.div`
    height: 575px;
    position: relative;
    ${media.mdMax`
        display: none;
    `}
`;
export const Mask = styled.div`
    width: 100%;
    height: 100%;
    position: absolute;
    background: rgba(255, 255, 255, 0.95);
    opacity: 0;
    z-index: -1;
    will-change: opacity, z-index;
`;

const imgStyles = css`
    transform: scale(0.95);
    height: auto;
    display: block;
    position: absolute;
`;
export const Bg = styled.img`
    ${imgStyles};
    width: 995px;
    top: 65px;
    left: 181px;
`;
export const Pc = styled.img`
    ${imgStyles};
    width: 581px;
    top: 225px;
    left: 145px;
`;
export const Tablet = styled.img`
    ${imgStyles};
    width: 322px;
    top: 60px;
    left: 567px;
`;
export const Phone = styled.img`
    ${imgStyles};
    width: 206px;
    top: 270px;
    left: 680px;
`;

const imgText = css`
    font-family: Arial, sans-serif;
    position: absolute;
    font-weight: bold;
    div {
        font-size: 16px;
        color: ${props => props.theme.accentColor};
    }
    span {
        font-size: 14px;
        color: #000;
    }
`;
export const PcText = styled.span`
    top: 245px;
    left: 20px;
    max-width: 135px;
    text-align: right;
    ${imgText};
`;
export const TabletText = styled.span`
    top: 20px;
    left: 760px;
    max-width: 150px;
    ${imgText};
`;
export const TabletText2 = styled.span`
    top: 220px;
    left: 880px;
    max-width: 160px;
    ${imgText};
`;
export const PhoneText = styled.span`
    top: 400px;
    left: 880px;
    max-width: 160px;
    ${imgText};
`;

const groupStyles = css`
    transform: scale(1) translate(0, 0);
    transition: 0.5s;
    &:hover {
        z-index: 9999;
        position: relative;
        transition: 0.5s;
        will-change: transform, z-index;

        & ~ ${Mask} {
            opacity: 1;
            transition: 0.5s;
            z-index: 2;
            will-change: transition, opacity;
        }

        ${/* sc-selector */ Pc},
        ${/* sc-selector */ Tablet}, 
        ${/* sc-selector */ Phone} {
            box-sizing: content-box;
        }
    }
`;

export const PcGroup = styled.div`
    ${groupStyles};
    &:hover {
        transform: scale(1.3) translate(120px, -100px);

        ${Pc} {
            padding: 50px 100px 40px 180px;
            top: 175px;
            left: 25px;
        }
        ${PcText} {
            left: 80px;
        }
    }
`;

export const TabletGroup = styled.div`
    ${groupStyles};
    &:hover {
        transform: scale(1.3) translate(-100px, 60px);

        ${Tablet} {
            padding: 90px 190px 80px 120px;
            top: -30px;
            left: 447px;
        }
    }
`;

export const PhoneGroup = styled.div`
    ${groupStyles};
    &:hover {
        transform: scale(1.3) translate(-170px, -190px);

        ${Phone} {
            padding: 80px 180px 80px 120px;
            top: 190px;
            left: 560px;
        }
    }
`;
