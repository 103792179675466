import { media } from "services/styled-components/variables";
import styled from "styled-components";
import { BaseTitle, Wrapper } from "styled/layouts";

export const WrapperMainInfo = styled(Wrapper)`
    max-width: 800px;
`;

export const ItemWrapper = styled.div`
    display: flex;
    padding-bottom: 3em;
    gap: 2em;
    /* stylelint-disable */
    &:nth-child(2n) {
        flex-direction: row-reverse;
    }

    ${media.smMax`
        flex-direction: column;
        align-items: center;
        justify-content: center;
        gap: 0.5em;

        &:nth-child(2n) {
            flex-direction: column;
        }
    `}
`;

export const RightContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    ${media.smMax`
        align-items:center;
    `}
`;

export const Title = styled.h2`
    ${BaseTitle};
    padding: 0;
    color: #000;
    ${media.smMax`
       text-align: center;
       padding-bottom: 0.5em;
       
  `}
`;

export const MessengersBlockContainer = styled.div`
    display: flex;
    gap: 7px;
    img {
        width: 32px;
    }
`;
