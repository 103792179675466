import { useInitialValues } from "./use-initial-values";
import { useValidationRules } from "./use-validation-rules";
import { useCreateSignUpSubmit, Stage } from "./use-on-submit";
import { useState } from "react";
import * as yup from "yup";

export const useSignUp = (
    setStage: React.Dispatch<React.SetStateAction<Stage>>,
    // eslint-disable-next-line @typescript-eslint/ban-types
    overwrittenRules?: yup.ObjectSchemaDefinition<object>,
) => {
    const initialValues = useInitialValues();
    const validationRules = useValidationRules();

    const [validationSchema] = useState(() => {
        const validationSchemaTemp = yup.object().shape(validationRules);
        if (overwrittenRules) {
            return validationSchemaTemp.shape(overwrittenRules);
        }

        return validationSchemaTemp;
    });
    const createSubmit = useCreateSignUpSubmit(validationSchema, setStage);

    return {
        initialValues,
        validationSchema,
        createSubmit,
    };
};
