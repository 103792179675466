import { ReactChild } from "react";

interface IInjectedProps {}

export interface IAnimateProps extends IInjectedProps {
    children: ReactChild;
    animateType: string;
    play?: boolean;
    animateDelay?: number;
}

export const Animate = React.memo((props: IAnimateProps) => {
    const { children, animateType, play, animateDelay } = props;

    return (
        <div
            className={`animated ${animateType} ${
                play && "go"
            } delay-${animateDelay}`}
        >
            {children}
        </div>
    );
}) as React.ComponentType<Subtract<IAnimateProps, IInjectedProps>>;

Animate.defaultProps = {
    play: true,
    animateDelay: 0,
};
