import { useMemo } from "react";
import { useLanguage } from "components/language-provider";
import { useCountries } from "hooks/query/use-countries";
import { useGetLanguages } from "hooks/query/use-language";
import {
    GetCountries_countries,
    GetLanguages_languages,
    GetMessengers_messengers,
} from "gql/types/operation-result-types";
import { find } from "lodash";
import { usePaymentSystemsForRegistration } from "./use-payment-systems-registration";
import { usePaymentSystemsWithFields } from "hooks/query/use-payment-systems-with-fields";
import { useSelector } from "react-redux";
import { settingsSelectors } from "services/redux/root/settings/selectors";

const initialValues = {
    login: "",
    password: "",
    confirmPassword: "",
    website: "",
    websiteCategory: null as null | number,
    language: null as null | GetLanguages_languages,
    howDidYouKnow: null as null | number,
    firstname: "",
    surname: "",
    email: "",
    messenger: null as null | GetMessengers_messengers,
    messengerLogin: "",
    country: null as null | GetCountries_countries,
    phone: "",
    rulesConfirmed: false,
    paymentSystem: null as
        | ReturnType<typeof usePaymentSystemsWithFields>["paymentSystems"][0]
        | null,
    mailingConfirmed: false,
};

export type SignUpValues = typeof initialValues;

export function useInitialValues() {
    // Если используете initialValues, которые могут меняться с течением времени,
    // тогда передавайте enableReinitialize формику.

    const { language: userLanguage } = useLanguage();
    const { languages, loading: languagesLoading } = useGetLanguages();
    const { countries, loading: countriesLoading } = useCountries();
    const { getInitialPaymentSystem } = usePaymentSystemsForRegistration();
    const settings = useSelector(state => settingsSelectors.getSettings(state));

    return useMemo(() => {
        if (languagesLoading || countriesLoading) {
            return initialValues;
        }

        const initialValuesCpd = { ...initialValues };
        const userCountryFromList = find(
            countries,
            country => country.code === settings.geoCountryCode,
        );
        if (userCountryFromList) {
            initialValuesCpd.country = userCountryFromList;
        }

        const userLanguageFromList = find(
            languages,
            language => language.code === userLanguage.code,
        );
        const defaultEngLamguageFromList = find(
            languages,
            language => language.code === "en-GB",
        );
        if (userLanguageFromList) {
            initialValuesCpd.language = userLanguageFromList;
        } else if (defaultEngLamguageFromList) {
            initialValuesCpd.language = defaultEngLamguageFromList;
        }

        initialValuesCpd.paymentSystem = getInitialPaymentSystem(
            initialValuesCpd.country,
        );

        return initialValuesCpd;
    }, [
        languagesLoading,
        countriesLoading,
        countries,
        languages,
        getInitialPaymentSystem,
        settings.geoCountryCode,
        userLanguage.code,
    ]);
}
