import { useTranslation } from "react-i18next";
import { Field } from "components/formik";

export function FirstnameField() {
    const [__] = useTranslation();
    return (
        <Field
            name="firstname"
            type="text"
            label={`${__("Имя")} *`}
            autoComplete="on"
            autoCapitalize="on"
            autoCorrect="on"
        />
    );
}
