/* eslint-disable @typescript-eslint/ban-types */
import * as yup from "yup";
import { useCookies } from "react-cookie";
import { MutationFunction, useMutation, useQuery } from "@apollo/client";
import { errorHandler } from "../../services/error-handler";
import { Variables } from "../../services/variables";
import { onSubmitWrapper } from "components/formik/on-submit-wrapper";
import {
    RegistrationData,
    RegistrationDataVariables,
    RegistrationPaymentSystem,
    SignUp as SignUpMutation,
    SignUpVariables,
} from "gql/types/operation-result-types";
import SIGN_UP_QUERY from "./sign-up.gql";
import GET_REGISTRATION_DATA from "./get-registration-data.gql";
import { omit, get } from "lodash";
import ReCAPTCHA from "react-google-recaptcha";

export enum Stage {
    SIGN_UP,
    SUCCESS,
    ERROR,
}

export function useCreateSignUpSubmit<T extends object>(
    validationSchema: yup.ObjectSchema<yup.Shape<any, T>>,
    setStage: React.Dispatch<React.SetStateAction<Stage>>,
) {
    const [cookies] = useCookies([Variables.cookies.tag]);
    const [mutation] = useMutation<SignUpMutation, SignUpVariables>(
        SIGN_UP_QUERY,
    );
    const { data } = useQuery<RegistrationData, RegistrationDataVariables>(
        GET_REGISTRATION_DATA,
        {
            variables: {
                tag: cookies[Variables.cookies.tag],
            },
        },
    );
    return (recaptchaComponent: ReCAPTCHA | null) => {
        return onSubmitWrapper(
            createOnSubmit(
                mutation,
                setStage,
                recaptchaComponent,
                g(data, "authorization", "registrationData", "RI") || undefined,
            ),
            validationSchema,
        );
    };
}

export const createOnSubmit =
    (
        mutation: MutationFunction,
        setStage: (stage: Stage) => void,
        recaptchaComponent: ReCAPTCHA | null,
        refId?: number,
    ) =>
    async (values: object) => {
        try {
            // игнорируем капчу на деве
            const recaptcha = __ENVIRONMENT__.development
                ? ""
                : await recaptchaComponent?.executeAsync();
            const fetchResult = await mutation({
                variables: {
                    ...createVariables(values, refId),
                    recaptcha: recaptcha || "",
                },
            });
            if (
                fetchResult &&
                fetchResult.data &&
                fetchResult.data.authorization.signUp
            ) {
                setStage(Stage.SUCCESS);
            }
        } catch (e: any) {
            void errorHandler(e);
            setStage(Stage.ERROR);

            // пробрасываем ошибку для дальнейшей обработки.
            throw e;
        } finally {
            recaptchaComponent?.reset();
        }
    };

function createVariables(values: object, refId?: number) {
    const paymentSystem: RegistrationPaymentSystem = {
        id: get(values, "paymentSystem.id"),
        fields: get(values, "paymentSystem.fields").map((field: any) => ({
            id: get(field, "id"),
            value: get(field, "value"),
        })),
    };

    return {
        registrationInfo: omit(
            {
                ...values,
                paymentSystem,
                // TODO: просто  values.messenger не работает, т.к. если мессенджер сборшен
                // там объект с неопределенными полями, а не null
                messenger: get(values, "messenger.id")
                    ? {
                          id: get(values, "messenger.id"),
                          login: get(values, "messengerLogin"),
                      }
                    : null,
                language: get(values, "language.id"),
                country: get(values, "country.id"),
                refId,
            },
            "messengerLogin",
            "confirmPassword",
        ),
    };
}
