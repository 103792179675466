import type { GetFaq } from "gql/types/operation-result-types";
import { useQuery, gql } from "@apollo/client";

const GET_FAQ = gql`
    query GetFaq {
        partnersProgram {
            faq {
                partitions {
                    sectionName
                    questions {
                        question
                        answer
                        image
                    }
                }
            }
        }
    }
`;

export const useFaq = () => {
    const { data, loading } = useQuery<GetFaq>(GET_FAQ, {
        ssr: true,
    });
    const faqContent = data?.partnersProgram?.faq?.partitions;
    return {
        faqContent,
        loading,
    };
};
