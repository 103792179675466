import { Stage } from "hooks/sign-up/use-on-submit";
import React, { useRef } from "react";
import { FormWrapper } from "./form-wrapper/index";
import { FormWrapper as FormWrapperLinebet } from "./form-wrapper/form-wrapper-linebet";
import { FormWrapper as FormWrapperCoinplay } from "./form-wrapper/form-wrapper-coinplay";
import { FormWrapper as FormWrapperIreland } from "./form-wrapper/form-wrapper-ireland";
import { usePartnersProgram } from "hooks/use-partners-program-id";
import {
    LINEBET_PARTNERS,
    COINPLAY_PARTNERS,
    BETANDYOU_AFFILIATES,
    _1XBET_PARTNERS,
    _1XBET_PARTNERS_IRELAND,
    AFROPARI_PARTNERS,
    PLANBET_PARTNERS,
} from "server/partner-programs";
import { useModalState } from "hooks/use-modal-state";
import { Modal } from "components/components-common/modal";
import { ModalSize } from "components/components-common/modal/modal-size";
import { PrivacyPolicyInformation } from "views/landing/privacy-policy/privacy-policy-information";
import { TermsAndConditions } from "views/landing/terms/terms-and-conditions";
import { useSignUp } from "hooks/sign-up/use-sign-up";
import { FormikConsoleErrors } from "components/formik/formik-console-errors";
import { emptyField, useObjectValidations } from "validation/validaion";
import { FormikHelpers } from "formik";
import { ReCAPTCHA, TReCAPTCHA } from "components/recaptcha";
import { useMessengers } from "../../../../../../hooks/query/use-messengers";

interface IInjectedProps {}

interface IProps extends IInjectedProps {
    setStage: React.Dispatch<React.SetStateAction<Stage>>;
}

export const SingUpForm = React.memo((props: IProps) => {
    const partnersProgramId = usePartnersProgram()?.programId;
    const rulesModalState = useModalState({ size: ModalSize.Large });
    const privacyPolicyModalState = useModalState({ size: ModalSize.Large });
    const { messengerRules } = useObjectValidations();
    const { messenger, messengerLogin } = messengerRules(true);
    const recaptchaRef = useRef<TReCAPTCHA>(null);
    const { messengers } = useMessengers();

    const getOverwrittenRules = () => {
        if (partnersProgramId === COINPLAY_PARTNERS) {
            return {
                phone: emptyField(),
                websiteCategory: emptyField(),
                howDidYouKnow: emptyField(),
            };
        }
        if (partnersProgramId === LINEBET_PARTNERS) {
            return {
                websiteCategory: emptyField(),
                website: emptyField(),
                howDidYouKnow: emptyField(),
            };
        }
        if (
            partnersProgramId === _1XBET_PARTNERS ||
            partnersProgramId === BETANDYOU_AFFILIATES
        ) {
            return {
                messenger,
                messengerLogin,
            };
        }
    };

    const getWrapper = () => {
        if (partnersProgramId === COINPLAY_PARTNERS) {
            return (
                <FormWrapperCoinplay
                    setStage={props.setStage}
                    onPrivacyClick={privacyPolicyModalState.open}
                    onRulesClick={rulesModalState.open}
                />
            );
        }
        if (partnersProgramId === LINEBET_PARTNERS) {
            return (
                <FormWrapperLinebet
                    setStage={props.setStage}
                    onPrivacyClick={privacyPolicyModalState.open}
                    onRulesClick={rulesModalState.open}
                />
            );
        }
        if (
            partnersProgramId === _1XBET_PARTNERS_IRELAND ||
            partnersProgramId === AFROPARI_PARTNERS
        ) {
            return (
                <FormWrapperIreland
                    onPrivacyClick={privacyPolicyModalState.open}
                    onRulesClick={rulesModalState.open}
                />
            );
        }
        return (
            <FormWrapper
                requiredMessenger={
                    partnersProgramId === _1XBET_PARTNERS ||
                    partnersProgramId === BETANDYOU_AFFILIATES
                }
                onPrivacyClick={privacyPolicyModalState.open}
                onRulesClick={rulesModalState.open}
            />
        );
    };

    const overwrittenRules = getOverwrittenRules();
    const Wrapper = getWrapper();

    // eslint-disable-next-line prefer-const
    let { initialValues, validationSchema, createSubmit } = useSignUp(
        props.setStage,
        overwrittenRules,
    );

    if (partnersProgramId === PLANBET_PARTNERS) {
        initialValues = {
            ...initialValues,
            messenger:
                messengers?.find(msngr => msngr.name === "Telegram") || null,
        };
    }

    const onSubmitHandler = (
        values: typeof initialValues,
        formikHelpers: FormikHelpers<typeof initialValues>,
    ) => {
        if (
            (partnersProgramId === _1XBET_PARTNERS_IRELAND ||
                partnersProgramId === AFROPARI_PARTNERS) &&
            values.paymentSystem &&
            values.paymentSystem.fields[0]
        ) {
            values.paymentSystem.fields[0].value = "000000";
        }
        const onSubmit = createSubmit(recaptchaRef.current);
        return onSubmit(values, formikHelpers);
    };

    return (
        <>
            <FormikConsoleErrors<typeof initialValues>
                enableReinitialize={true}
                initialValues={initialValues}
                onSubmit={onSubmitHandler}
                validationSchema={validationSchema}
            >
                <>
                    {Wrapper}
                    <Modal {...rulesModalState.modalProps}>
                        <TermsAndConditions />
                    </Modal>
                    <Modal {...privacyPolicyModalState.modalProps}>
                        <PrivacyPolicyInformation />
                    </Modal>
                </>
            </FormikConsoleErrors>
            <ReCAPTCHA ref={recaptchaRef} size="invisible" />
        </>
    );
}) as React.ComponentType<Subtract<IProps, IInjectedProps>>;
