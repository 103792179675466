import flags from "assets/images/flags-sprite-full.jpg";

import {
    adminBorderColor2,
    backgroundColor0,
} from "services/styled-components/themes";
import styled, { css } from "styled-components";
import { rtl } from "services/styled-components/rtl";
import { media } from "services/styled-components/variables";

export const CountryFlagFieldSC = styled.div`
    position: relative;
    z-index: 0;
    line-height: normal;
    background: ${backgroundColor0};
    border-color: transparent ${adminBorderColor2} transparent transparent;
    border-width: 1px;
    border-style: solid;
    ${props => css`
        color: ${props.theme.primaryColor};
    `}
    padding: 0.563em;
    ${rtl`margin-right: 100%;`};
    margin-bottom: -2.35em;
    width: 45px;
    /* float: left; */
    & + input[type="text"] {
        ${rtl`padding-left: 51px;`};
        background: transparent;
        z-index: 1;
    }

    ${media.ltMax`
        padding: 10px;
        margin-bottom: -38px;
    `}
`;

export const CountryFlag = styled.span`
    background: url(${flags}) no-repeat;
    height: 11px;
    width: 16px;
    display: inline-block;
    /* background-position: -16px; */
    box-shadow: 0 0 2px rgba(0, 0, 0, 0.75);
    background-position: 0 -2249px;
    &.RU {
        background-position: 0 0;
    }
    &.UA {
        background-position: 0 -11px;
    }

    &.KZ {
        background-position: 0 -22px;
    }

    &.MD {
        background-position: 0 -33px;
    }

    &.BY {
        background-position: 0 -44px;
    }

    &.KG {
        background-position: 0 -55px;
    }

    &.AZ {
        background-position: 0 -66px;
    }

    &.GE {
        background-position: 0 -77px;
    }

    &.AM {
        background-position: 0 -88px;
    }

    &.TJ {
        background-position: 0 -99px;
    }

    &.PA {
        background-position: 0 -110px;
    }

    &.GB {
        background-position: 0 -121px;
    }

    &.LT {
        background-position: 0 -132px;
    }

    &.IL {
        background-position: 0 -143px;
    }

    &.TR {
        background-position: 0 -154px;
    }

    &.VN {
        background-position: 0 -165px;
    }

    &.LV {
        background-position: 0 -176px;
    }

    &.IN {
        background-position: 0 -187px;
    }

    &.TH {
        background-position: 0 -198px;
    }

    &.US {
        background-position: 0 -209px;
    }

    &.EE {
        background-position: 0 -220px;
    }

    &.JP {
        background-position: 0 -231px;
    }

    &.UZ {
        background-position: 0 -242px;
    }

    &.KR {
        background-position: 0 -253px;
    }

    &.ES {
        background-position: 0 -264px;
    }

    &.IR {
        background-position: 0 -275px;
    }

    &.DE {
        background-position: 0 -286px;
    }

    &.IT {
        background-position: 0 -297px;
    }

    &.BG {
        background-position: 0 -308px;
    }

    &.TM {
        background-position: 0 -319px;
    }

    &.FR {
        background-position: 0 -330px;
    }

    &.SK {
        background-position: 0 -341px;
    }

    &.PL {
        background-position: 0 -352px;
    }

    &.CZ {
        background-position: 0 -363px;
    }

    &.GR {
        background-position: 0 -374px;
    }

    &.AU {
        background-position: 0 -385px;
    }

    &.RO {
        background-position: 0 -396px;
    }

    &.AB {
        background-position: 0 -407px;
    }

    &.BM {
        background-position: 0 -418px;
    }

    &.NO {
        background-position: 0 -430px;
    }

    &.MN {
        background-position: 0 -442px;
    }

    &.PT {
        background-position: 0 -455px;
    }

    &.CN {
        background-position: 0 -467px;
    }

    &.BR {
        background-position: 0 -479px;
    }

    &.KE {
        background-position: 0 -489px;
    }

    &.CM {
        background-position: 0 -500px;
    }

    &.CY {
        background-position: 0 -511px;
    }

    &.ID {
        background-position: 0 -522px;
    }
    /**********************************************************************************************************************/
    /**********************************************************************************************************************/
    /**********************************************************************************************************************/
    /**********************************************************************************************************************/
    /**********************************************************************************************************************/
    &.AT {
        /*Австрия*/
        background-position: 0 -533px;
    }

    &.AL {
        /*Албания*/
        background-position: 0 -544px;
    }

    &.DZ {
        /*Алжир*/
        background-position: 0 -555px;
    }

    &.EN {
        /*Англия*/
        background-position: 0 -566px;
    }

    &.AO {
        /*Ангола*/
        background-position: 0 -577px;
    }

    &.A {
        /*Андорра*/
        background-position: 0 -588px;
    }

    &.AG {
        /*Антигуа и Барбуда*/
        background-position: 0 -599px;
    }

    &.AR {
        /*Аргентина*/
        background-position: 0 -610px;
    }

    &.AW {
        /*Аруба*/
        background-position: 0 -621px;
    }

    &.AF {
        /*Афганистан*/
        background-position: 0 -632px;
    }

    &.BS {
        /*Багамские о-ва*/
        background-position: 0 -643px;
    }

    &.BD {
        /*Бангладеш*/
        background-position: 0 -654px;
    }

    &.BB {
        /*Барбадос*/
        background-position: 0 -665px;
    }

    &.BH {
        /*Бахрейн*/
        background-position: 0 -676px;
    }

    &.BZ {
        /*Белиз*/
        background-position: 0 -687px;
    }

    &.BE {
        /*Бельгия*/
        background-position: 0 -698px;
    }

    &.BJ {
        /*Бенин*/
        background-position: 0 -709px;
    }

    &.BO {
        /*Боливия*/
        background-position: 0 -720px;
    }

    &.BA {
        /*Босния/Герцеговина*/
        background-position: 0 -731px;
    }

    &.BW {
        /*Ботсвана*/
        background-position: 0 -742px;
    }

    &.BN {
        /*Бруней*/
        background-position: 0 -753px;
    }

    &.BF {
        /*Буркина Фасо*/
        background-position: 0 -764px;
    }

    &.BI {
        /*Бурунди*/
        background-position: 0 -775px;
    }

    &.BT {
        /*Бутан*/
        background-position: 0 -786px;
    }

    &.VU {
        /*Вануату*/
        background-position: 0 -797px;
    }

    &.HU {
        /*Венгрия*/
        background-position: 0 -808px;
    }

    &.VE {
        /*Венесуэла*/
        background-position: 0 -819px;
    }

    &.TL {
        /*Восточный Тимор*/
        background-position: 0 -830px;
    }

    &.GA {
        /*Габон*/
        background-position: 0 -841px;
    }

    &.HT {
        /*Гаити*/
        background-position: 0 -852px;
    }

    &.GY {
        /*Гайана*/
        background-position: 0 -863px;
    }

    &.GM {
        /*Гамбия*/
        background-position: 0 -874px;
    }

    &.GH {
        /*Гана*/
        background-position: 0 -885px;
    }

    &.GP {
        /*Гваделупа*/
        background-position: 0 -896px;
    }

    &.GT {
        /*Гватемала*/
        background-position: 0 -907px;
    }

    &.GN {
        /*Гвинея*/
        background-position: 0 -918px;
    }

    &.GW {
        /*Гвинея-Бисау*/
        background-position: 0 -929px;
    }

    &.GI {
        /*Гибралтар*/
        background-position: 0 -940px;
    }

    &.NL {
        /*Голландия*/
        background-position: 0 -951px;
    }

    &.HN {
        /*Гондурас*/
        background-position: 0 -962px;
    }

    &.HK {
        /*Гонконг*/
        background-position: 0 -973px;
    }

    &.GD {
        /*Гренада*/
        background-position: 0 -984px;
    }

    &.GL {
        /*Гренландия*/
        background-position: 0 -995px;
    }

    &.GU {
        /*Гуам*/
        background-position: 0 -1006px;
    }

    &.DK {
        /*Дания*/
        background-position: 0 -1017px;
    }

    &.DJ {
        /*Джибути*/
        background-position: 0 -1028px;
    }

    &.DO {
        /*Доминиканская республика*/
        background-position: 0 -1039px;
    }

    &.EG {
        /*Египет*/
        background-position: 0 -1050px;
    }

    &.ZM {
        /*Замбия*/
        background-position: 0 -1061px;
    }

    &.EH {
        /*Западная Сахара*/
        background-position: 0 -1072px;
    }

    &.ZW {
        /*Зимбабве*/
        background-position: 0 -1083px;
    }

    &.JO {
        /*Иордания*/
        background-position: 0 -1094px;
    }

    &.IQ {
        /*Ирак*/
        background-position: 0 -1105px;
    }

    &.IE {
        /*Ирландия*/
        background-position: 0 -1116px;
    }

    &.IS {
        /*Исландия*/
        background-position: 0 -1127px;
    }

    &.YE {
        /*Йемен*/
        background-position: 0 -1138px;
    }

    &.CV {
        /*Кабо-Верде*/
        background-position: 0 -1149px;
    }

    &.KH {
        /*Камбоджа*/
        background-position: 0 -1160px;
    }

    &.CA {
        /*Канада*/
        background-position: 0 -1171px;
    }

    &.QA {
        /*Катар*/
        background-position: 0 -1182px;
    }

    &.KI {
        /*Кирибати*/
        background-position: 0 -1193px;
    }

    &.CO {
        /*Колумбия*/
        background-position: 0 -1204px;
    }

    &.KM {
        /*Коморские о-ва*/
        background-position: 0 -1215px;
    }

    &.CG {
        /*Конго (Brazzaville)*/
        background-position: 0 -1226px;
    }

    &.CD {
        /*Конго (Kinshasa)*/
        background-position: 0 -1237px;
    }

    &.CR {
        /*Коста-Рика*/
        background-position: 0 -1248px;
    }

    &.CI {
        /*Кот-д'Ивуар*/
        background-position: 0 -1259px;
    }

    &.CU {
        /*Куба*/
        background-position: 0 -1270px;
    }

    &.KW {
        /*Кувейт*/
        background-position: 0 -1281px;
    }

    &.CW {
        /*Кюрасао*/
        background-position: 0 -1292px;
    }

    &.LA {
        /*Лаос*/
        background-position: 0 -1303px;
    }

    &.LS {
        /*Лесото*/
        background-position: 0 -1314px;
    }

    &.LR {
        /*Либерия*/
        background-position: 0 -1325px;
    }

    &.LB {
        /*Ливан*/
        background-position: 0 -1336px;
    }

    &.LY {
        /*Ливия*/
        background-position: 0 -1347px;
    }

    &.LI {
        /*Лихтенштейн*/
        background-position: 0 -1358px;
    }

    &.LU {
        /*Люксембург*/
        background-position: 0 -1369px;
    }

    &.MU {
        /*Маврикий*/
        background-position: 0 -1380px;
    }

    &.MR {
        /*Мавритания*/
        background-position: 0 -1391px;
    }

    &.MG {
        /*Мадагаскар*/
        background-position: 0 -1402px;
    }

    &.MO {
        /*Макао*/
        background-position: 0 -1413px;
    }

    &.MK {
        /*Македония*/
        background-position: 0 -1424px;
    }

    &.MW {
        /*Малави*/
        background-position: 0 -1435px;
    }

    &.MY {
        /*Малайзия*/
        background-position: 0 -1446px;
    }

    &.ML {
        /*Мали*/
        background-position: 0 -1457px;
    }

    &.MV {
        /*Мальдивские о-ва*/
        background-position: 0 -1468px;
    }

    &.MT {
        /*Мальта*/
        background-position: 0 -1479px;
    }

    &.MA {
        /*Марокко*/
        background-position: 0 -1490px;
    }

    &.MX {
        /*Мексика*/
        background-position: 0 -1501px;
    }

    &.MZ {
        /*Мозамбик*/
        background-position: 0 -1512px;
    }

    &.MC {
        /*Монако*/
        background-position: 0 -1523px;
    }

    &.NA {
        /*Намибия*/
        background-position: 0 -1534px;
    }

    &.NR {
        /*Науру*/
        background-position: 0 -1545px;
    }

    &.NP {
        /*Непал*/
        background-position: 0 -1556px;
    }

    &.NE {
        /*Нигер*/
        background-position: 0 -1567px;
    }

    &.NG {
        /*Нигерия*/
        background-position: 0 -1578px;
    }

    &.NI {
        /*Никарагуа*/
        background-position: 0 -1589px;
    }

    &.NZ {
        /*Новая Зеландия*/
        background-position: 0 -1600px;
    }

    &.AE {
        /*О.А.Э.*/
        background-position: 0 -1611px;
    }

    &.OM {
        /*Оман*/
        background-position: 0 -1622px;
    }

    &.PK {
        /*Пакистан*/
        background-position: 0 -1633px;
    }

    &.PS {
        /*Палестина*/
        background-position: 0 -1644px;
    }

    &.PG {
        /*Папуа Новая Гвинея*/
        background-position: 0 -1655px;
    }

    &.PY {
        /*Парагвай*/
        background-position: 0 -1666px;
    }

    &.PE {
        /*Перу*/
        background-position: 0 -1677px;
    }

    &.PR {
        /*Пуэрто Рико*/
        background-position: 0 -1688px;
    }

    &.RE {
        /*Реюньон*/
        background-position: 0 -1699px;
    }

    &.RW {
        /*Руанда*/
        background-position: 0 -1710px;
    }

    &.SV {
        /*Сальвадор*/
        background-position: 0 -1721px;
    }

    &.WS {
        /*Самоа*/
        background-position: 0 -1732px;
    }

    &.SM {
        /*Сан-Марино*/
        background-position: 0 -1743px;
    }

    &.ST {
        /*Сан-Томе и Принсипи*/
        background-position: 0 -1754px;
    }

    &.SA {
        /*Саудовская Аравия*/
        background-position: 0 -1765px;
    }

    &.SZ {
        /*Свазиленд*/
        background-position: 0 -1776px;
    }

    &.SC {
        /*Сейшеллы*/
        background-position: 0 -1787px;
    }

    &.SN {
        /*Сенегал*/
        background-position: 0 -1798px;
    }

    &.KN {
        /*Сент Китс и Невис*/
        background-position: 0 -1809px;
    }

    &.VC {
        /*Сент-Винсент и Гренадины*/
        background-position: 0 -1820px;
    }

    &.LC {
        /*Сент-Люсия*/
        background-position: 0 -1831px;
    }

    &.RS {
        /*Сербия*/
        background-position: 0 -1842px;
    }

    &.SG {
        /*Сингапур*/
        background-position: 0 -1853px;
    }

    &.SY {
        /*Сирия*/
        background-position: 0 -1864px;
    }

    &.SI {
        /*Словения*/
        background-position: 0 -1875px;
    }

    &.SO {
        /*Сомали*/
        background-position: 0 -1886px;
    }

    &.SD {
        /*Судан*/
        background-position: 0 -1897px;
    }

    &.SR {
        /*Суринам*/
        background-position: 0 -1908px;
    }

    &.SL {
        /*Сьерра-Леоне*/
        background-position: 0 -1919px;
    }

    &.PF {
        /*Таити*/
        background-position: 0 -1930px;
    }

    &.TW {
        /*Тайвань*/
        background-position: 0 -1941px;
    }

    &.TZ {
        /*Танзания*/
        background-position: 0 -1952px;
    }

    &.TG {
        /*Того*/
        background-position: 0 -1963px;
    }

    &.TO {
        /*Тонга*/
        background-position: 0 -1974px;
    }

    &.TT {
        /*Тринидад и Тобаго*/
        background-position: 0 -1985px;
    }

    &.TV {
        /*Тувалу*/
        background-position: 0 -1996px;
    }

    &.TN {
        /*Тунис*/
        background-position: 0 -2007px;
    }

    &.TC {
        /*Туркс и Кейкос*/
        background-position: 0 -2018px;
    }

    &.UG {
        /*Уганда*/
        background-position: 0 -2029px;
    }

    &.UY {
        /*Уругвай*/
        background-position: 0 -2040px;
    }

    &.FJ {
        /*Фиджи*/
        background-position: 0 -2051px;
    }

    &.PH {
        /*Филиппины*/
        background-position: 0 -2062px;
    }

    &.FI {
        /*Финляндия*/
        background-position: 0 -2073px;
    }

    &.GF {
        /*Французская Гвинея*/
        background-position: 0 -2084px;
    }

    &.HR {
        /*Хорватия*/
        background-position: 0 -2095px;
    }

    &.CF {
        /*ЦАР*/
        background-position: 0 -2106px;
    }

    &.TD {
        /*Чад*/
        background-position: 0 -2117px;
    }

    &.ME {
        /*Черногория*/
        background-position: 0 -2128px;
    }

    &.CL {
        /*Чили*/
        background-position: 0 -2139px;
    }

    &.CH {
        /*Швейцария*/
        background-position: 0 -2150px;
    }

    &.SE {
        /*Швеция*/
        background-position: 0 -2161px;
    }

    &.LK {
        /*Шри-Ланка*/
        background-position: 0 -2172px;
    }

    &.EC {
        /*Эквадор*/
        background-position: 0 -2183px;
    }

    &.GQ {
        /*Экваториальная Гвинея*/
        background-position: 0 -2194px;
    }

    &.ER {
        /*Эритрея*/
        background-position: 0 -2205px;
    }

    &.ET {
        /*Эфиопия*/
        background-position: 0 -2216px;
    }

    &.SS {
        /*Южный Судан*/
        background-position: 0 -2227px;
    }

    &.JM {
        /*Ямайка*/
        background-position: 0 -2238px;
    }

    &.MM {
        /*Мьянма*/
        background-position: 0 -2260px;
    }
`;
