import styled from "styled-components";

export const ErrorText = styled.span`
    display: block;
    font-size: 2rem;
    line-height: 3.3rem;
    margin: 1.34rem 0;
    font-weight: 700;
    text-align: center;
`;
