import { GetPartnersProgramGeneralInfo_partnersProgram_generalInformation_stylesTheme } from "gql/types/operation-result-types";
import styled, { css } from "styled-components";

interface IProps {
    ourPartnerBG: string;
    secondBG: boolean;
    theme: GetPartnersProgramGeneralInfo_partnersProgram_generalInformation_stylesTheme;
}

const getType = (props: IProps) => {
    return css`
        a {
            background-color: ${props.ourPartnerBG};
            ${props.secondBG &&
            css`
                span {
                    background-color: ${props.theme.textColor};
                }
            `}
        }
    `;
};

export const OurPartnerWrapper = styled.div<IProps>`
    ${props => getType(props)}
    position: relative;
    box-sizing: border-box;
    padding: 0 0.625em;
    /* font-size: 0.9rem; */
    text-align: center;
    width: 100%;

    a {
        position: relative;
        display: block;
        font-size: 1.6rem;
        width: 100%;
        padding: 30px 0;
        border-radius: 16px;
        text-align: center;
        cursor: pointer;
        transition: transform 0.25s ease;
        box-shadow: 1px 2px 7px rgba(0, 0, 0, 0.1);
        &:hover {
            transform: translateY(-5px);
        }
    }
    img {
        width: 100%;
        height: auto;
    }
`;

export const OurPartnerImgWrapper = styled.span`
    display: block;
    width: 100px;
    height: 100px;
    margin-left: auto;
    margin-right: auto;
    padding: 12px;
    box-shadow: inset 0 0 0 4px ${props => props.theme.textColor};
    border-radius: 50%;
    transform: rotate(360deg);
`;
