import styled from "styled-components";
import { media } from "services/styled-components/variables";

export const TabsWrapper = styled.div`
    display: flex;
    gap: 5em;
    ${media.mdMax`
        flex-direction: column;
        gap: 1em;
    `}
`;

export const Tabs = styled.div`
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    flex-basis: 30%;
    gap: 1em;
    max-width: 13em;
    margin-top: 2em;
    ${media.mdMax`
        flex-direction: row;
        max-width: none;
    `}
`;

interface ITab {
    tabTextColor: string;
    tabBgColor: string;
    tabActiveBgColor: string;
    active?: boolean;
}

export const Tab = styled.button<ITab>`
    cursor: pointer;
    padding: 0.78em 1em;
    font-size: 0.8125em;
    color: ${({ tabTextColor }) => tabTextColor};
    background: ${props =>
        props.active ? props.tabActiveBgColor : props.tabBgColor};
    border-radius: 100px;
    outline: none;
    border: none;

    &:hover {
        background: ${({ tabActiveBgColor }) => tabActiveBgColor};
    }
`;

export const TabContent = styled.div`
    flex-basis: 70%;
    margin-top: 2em;

    & .tab-content {
        &-enter {
            opacity: 0;
        }
        &-enter-active {
            opacity: 1;
            transition: opacity 700ms;
        }
        &-exit {
            display: none;
        }
    }

    ${media.mdMax`
        padding-left: 1em;
    `}
`;
