import { useTranslation } from "react-i18next";
import { Wrapper } from "styled/layouts";
import { PartnerTitle, WrapperFullWidthPartner } from "../../partner/styled";
import { ButtonStartEarning } from "../../partner/button-start-earning";
import { WrapperCenter, PartnerAmount } from "./styled";
interface IProps {
    importantPartnerTitle: string;
    currencyName: string | null;
}

export const Partner = React.memo((props: IProps) => {
    const [__] = useTranslation();
    const [intropart1, intropart2] =
        props.importantPartnerTitle.split("{separator}");

    return (
        <WrapperFullWidthPartner>
            <Wrapper>
                <WrapperCenter>
                    <PartnerTitle>
                        <span>{intropart1}</span>
                        <span>{intropart2}</span>
                    </PartnerTitle>
                    {props.currencyName && (
                        <PartnerAmount>
                            <span>{props.currencyName}</span>
                            {__("в месяц")}
                        </PartnerAmount>
                    )}
                    <ButtonStartEarning />
                </WrapperCenter>
            </Wrapper>
        </WrapperFullWidthPartner>
    );
}) as React.ComponentType<IProps>;
