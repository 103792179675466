import {
    FeatureAnnotation,
    FeatureImg,
    FeatureItem,
    FeatureTitle,
} from "./styled";

interface IInjectedProps {}

interface IProps extends IInjectedProps {
    featureImg: JSX.Element;
    featureTitle: string;
    featureAnnotation?: string;
    bgColor?: string;
    color?: string;
}

export const Feature = React.memo((props: IProps) => {
    return (
        <FeatureItem bgColor={props.bgColor} color={props.color}>
            <div>
                <FeatureImg>{props.featureImg}</FeatureImg>
                <FeatureTitle color={props.color}>
                    {props.featureTitle}
                </FeatureTitle>
            </div>
            <FeatureAnnotation>{props.featureAnnotation}</FeatureAnnotation>
        </FeatureItem>
    );
}) as React.ComponentType<Subtract<IProps, IInjectedProps>>;
