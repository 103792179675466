import VisibilitySensor from "react-visibility-sensor";
import { Animate, IAnimateProps } from "../base-animate";
import React, { ReactElement, useState, useCallback } from "react";
import { Utils } from "@webx/utils";

interface IProps {
    children: (isVisibe: boolean) => ReactElement;
}

type AnimationProps = Omit<IAnimateProps, "play">;

let ie11 = false;
if (Utils.browser.hasWindow()) {
    try {
        // В IE Сенсор будет падать.
        // https://github.com/joshwnj/react-visibility-sensor/issues/99
        // В IE есть getBoundingClientRect. Он даже работает для body.
        // Но для обычных элементов он возвращает "Unexpected error". В случае, если они не смонтированны на страницу.
        // Поэтому не пока не вызовешь эту функцию не поймёшь что что-то пошло не так.
        document.createElement("div").getBoundingClientRect();
    } catch (e) {
        ie11 = true;
    }
}

export const AnimateWhenInViewport = React.memo(
    (props: IProps | AnimationProps) => {
        const [isVisible, setVisibility] = useState(true);
        const [sensorIsActive, setSensorActivity] = useState(true);
        const onChangeVisibility = useCallback((visibility: boolean) => {
            setVisibility(visibility);
            if (visibility) {
                setSensorActivity(false);
            }
        }, []);

        if (ie11) {
            return <AnimateThunk isVisible={true} animateProps={props} />;
        } else {
            return (
                <Sensor
                    sensorIsActive={sensorIsActive}
                    onChangeVisibility={onChangeVisibility}
                >
                    <AnimateThunk isVisible={isVisible} animateProps={props} />
                </Sensor>
            );
        }
    },
);

interface IAnimateThunkProps {
    isVisible: boolean;
    animateProps: IProps | AnimationProps;
}

function AnimateThunk(props: IAnimateThunkProps) {
    const { isVisible, animateProps } = props;
    let c: ReactElement;
    if (isIPrpops(animateProps)) {
        c = animateProps.children(isVisible);
    } else {
        const { children, ...rest } = animateProps;
        c = (
            <Animate play={isVisible} {...rest}>
                {children}
            </Animate>
        );
    }

    return c;
}

interface ISensorProps {
    sensorIsActive: boolean;
    onChangeVisibility: (visibility: boolean) => void;
    children: ReactElement;
}

const Sensor = React.memo((props: ISensorProps) => (
    <VisibilitySensor
        active={props.sensorIsActive}
        onChange={props.onChangeVisibility}
        partialVisibility={true}
        offset={{ top: 100 }}
        scrollCheck={true}
        scrollThrottle={250}
    >
        {props.children}
    </VisibilitySensor>
));

function isIPrpops(input: any): input is IProps {
    return typeof input.children === "function";
}
