import {
    CapabilityNumber,
    CapabilityWrapper,
    CapabilityDescription,
} from "./styled";
interface IInjectedProps {}

interface IProps extends IInjectedProps {
    capabilityNumber: string;
    capabilityTitle: string;
    textColor: string;
}

export const Capability = React.memo((props: IProps) => {
    return (
        <CapabilityWrapper textColor={props.textColor}>
            <CapabilityNumber>{props.capabilityNumber}</CapabilityNumber>
            <CapabilityDescription>
                {props.capabilityTitle}
            </CapabilityDescription>
        </CapabilityWrapper>
    );
}) as React.ComponentType<Subtract<IProps, IInjectedProps>>;
