import { AnimateWhenInViewport } from "components/components-common/animate";
import { Feature } from "../feature";
import { GetPartnersProgramGeneralInfo_partnersProgram_generalInformation_landingMainPage_termsIcons } from "gql/types/operation-result-types";
import { IconsDict } from "../iconsDictionary";

interface IProps {
    termsIcons: GetPartnersProgramGeneralInfo_partnersProgram_generalInformation_landingMainPage_termsIcons[];
    bgColor?: string;
    color?: string;
}
export const FeaturesItems = (props: IProps) => (
    <>
        {props.termsIcons.map((item, index) => {
            const Icon = IconsDict[item.iconFamily][item.icon];
            return (
                <AnimateWhenInViewport
                    animateType="fadeInUpShort"
                    animateDelay={index * 250}
                    key={index}
                >
                    <Feature
                        featureImg={<Icon size={item.size} />}
                        featureTitle={item.title}
                        featureAnnotation={item.annotation}
                        bgColor={props.bgColor}
                        color={props.color}
                    />
                </AnimateWhenInViewport>
            );
        })}
    </>
);
