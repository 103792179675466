import styled from "styled-components";

interface ITabQuestion {
    questionTextColor: string;
}

export const TabQuestion = styled.a<ITabQuestion>`
    && {
        display: block;
        margin-bottom: 1.875em;
        font-weight: bold;
        text-decoration: underline;
        color: ${({ questionTextColor }) => questionTextColor};
        cursor: pointer;
        &:hover {
            text-decoration: none;
        }
    }
`;

interface IAnswer {
    answerTextColor: string;
}

export const Answer = styled.div<IAnswer>`
    margin-bottom: 2em;
    color: ${({ answerTextColor }) => answerTextColor};
    &.answer-content {
        &-enter {
            opacity: 0;
            transform: translateY(-20%);
        }
        &-enter-active {
            opacity: 1;
            transform: translateY(0);
            transition: opacity 300ms, transform 300ms;
        }
        &-exit {
            opacity: 1;
        }
        &-exit-active {
            opacity: 0;
            transform: translateY(-20%);
            transition: opacity 300ms, transform 300ms;
        }
    }
`;
