import { onSubmitWrapper } from "components/formik/on-submit-wrapper";
import {
    SignIn as SignInMutation,
    SignInVariables,
} from "gql/types/operation-result-types";
import { useMutation } from "@apollo/client";
import { useTranslation } from "react-i18next";
import { useValidationSchema } from "./use-validation-schema";
import { errorHandler } from "services/error-handler";
import { ErrorType } from "server/errors/error-type";
import SIGN_IN from "./query.gql";
import { useTranslationList } from "hooks/use-translations-list";
import { SignInStage } from "../stage/use-stage";
import { InvalidCSRFToken } from "services/long-running-issues/invalid-csrf-token";
import ReCAPTCHA from "react-google-recaptcha";
import { useSignInLikeAPartner } from "./use-sign-in-like-a-partner";

type SetStage = (stage: SignInStage) => void;

export function useLogin(setStage: SetStage, partnerId?: number) {
    const initialValues = { login: "", password: "" };
    const validationSchema = useValidationSchema();
    const [__] = useTranslation();
    const { SIGN_IN_LIKE_A_PARTNER_ERROR } = useTranslationList();
    const [singInMutation] = useMutation<SignInMutation, SignInVariables>(
        SIGN_IN,
    );
    const { signInLikeAPartner } = useSignInLikeAPartner();

    const createOnSubmit = (recaptchaComponent: ReCAPTCHA | null) => {
        return onSubmitWrapper(async ({ login, password }) => {
            try {
                // игнорируем капчу на деве
                const recaptcha = __ENVIRONMENT__.development
                    ? ""
                    : await recaptchaComponent?.executeAsync();
                const data = await singInMutation({
                    variables: {
                        login,
                        password,
                        recaptcha: recaptcha || "",
                    },
                });
                const twoFaNeeded =
                    data.data?.authorization.signIn.twoFactorAuthNeeded;
                if (twoFaNeeded) {
                    // управление перейдёт в TwoFA компонент.
                    // Там же и будет разрулен логин под партнёром из акка менеджера (с помощью partnerId).
                    setStage(SignInStage.TWO_FA);
                } else if (partnerId) {
                    void InvalidCSRFToken.logCSRFTokenSecurly(
                        "Setting csrf token before signInLikeAPartner at login",
                        data.data?.authorization.signIn.user?.csrf,
                    );
                    // пробовать логиниться под партнером после успешного логина под менеджером.
                    await signInLikeAPartner(
                        partnerId,
                        data.data?.authorization.signIn.user?.csrf,
                    );

                    setStage(SignInStage.SUCCESS);
                } else {
                    setStage(SignInStage.SUCCESS);
                }
            } catch (e: any) {
                void errorHandler(e, error => {
                    switch (error.data.code) {
                        case ErrorType.INVALID_USER_OR_PASSWORD:
                        case ErrorType.UNEXPECTED_ERROR:
                            return __("Неверное имя пользователя или пароль");
                        case ErrorType.USER_ON_MODERATION:
                            return __(
                                "Мы все еще модерируем Вашу заявку, пожалуйста, подождите. По окончании модерации Вам должно прийти письмо.",
                            );
                        case ErrorType.SIGN_IN_LIKE_A_PARTNER_ERROR:
                            return SIGN_IN_LIKE_A_PARTNER_ERROR();
                    }
                });
                setStage(SignInStage.SIMPLE_SIGN_IN_ERROR);
            } finally {
                recaptchaComponent?.reset();
            }
        }, validationSchema);
    };

    return {
        initialValues,
        validationSchema,
        createOnSubmit,
    };
}
