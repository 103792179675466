import { CSSTransition, TransitionGroup } from "react-transition-group";
import { Tab, TabContent, Tabs, TabsWrapper } from "./styled";
import { useMemo, useState } from "react";
import { QuestionContent } from "./question-content";
import { ContentLoader } from "components/components-common/content-loader";
import { List } from "react-content-loader";
import { useFaq } from "hooks/query/use-faq";

interface IProps {
    questionTextColor: string;
    answerTextColor: string;
    tabTextColor: string;
    tabBgColor: string;
    tabActiveBgColor: string;
}

export const Faq = React.memo((props: IProps) => {
    const { faqContent, loading } = useFaq();
    const [tabIndex, setTabIndex] = useState(0);

    const tabsComp = useMemo(() => {
        return (
            faqContent &&
            faqContent.map((partition, i) => (
                <Tab
                    tabTextColor={props.tabTextColor}
                    tabBgColor={props.tabBgColor}
                    tabActiveBgColor={props.tabActiveBgColor}
                    active={i === tabIndex}
                    key={i}
                    onClick={() => setTabIndex(i)}
                >
                    {partition.sectionName}
                </Tab>
            ))
        );
    }, [
        faqContent,
        tabIndex,
        props.tabTextColor,
        props.tabBgColor,
        props.tabActiveBgColor,
    ]);

    const tabContent =
        faqContent &&
        faqContent[tabIndex]?.questions?.map((item, i) => (
            <QuestionContent
                questionTextColor={props.questionTextColor}
                answerTextColor={props.answerTextColor}
                key={i}
                defaultState={false}
                question={item.question}
                answer={item.answer}
                image={item.image}
            />
        ));
    return (
        <ContentLoader
            component={List}
            times={10}
            loading={loading}
            text={faqContent && faqContent.length > 0 ? " " : ""}
        >
            {faqContent && (
                <TabsWrapper>
                    <Tabs>{tabsComp}</Tabs>
                    <TabContent>
                        <TransitionGroup>
                            <CSSTransition
                                key={tabIndex}
                                timeout={700}
                                classNames="tab-content"
                            >
                                <div>{tabContent}</div>
                            </CSSTransition>
                        </TransitionGroup>
                    </TabContent>
                </TabsWrapper>
            )}
        </ContentLoader>
    );
});
