import { media } from "services/styled-components/variables";
import styled from "styled-components";

export const FeaturesSC = styled.div`
    position: relative;
    display: flex;
    flex-wrap: wrap;
    z-index: 1;
    margin-bottom: 70px;
    justify-content: space-between;
    margin-top: -110px;
    ${media.smMax`
        margin-top: 60px;
    `}
    > * {
        position: relative;
        margin-bottom: 20px;
        width: calc((100% - 60px) / 4);
        ${media.smMax`
            width: calc((100% - 20px) / 2);
        `}
        ${media.xsMax`
            width: 100%;
        `}
    }
`;
