import React from "react";
import { useTranslation } from "react-i18next";
import {
    MobileProductsWrapper,
    MobileProductsImage,
    MobileProductsList,
} from "./styled";

interface IInjectedProps {}
interface IProps extends IInjectedProps {
    data: any;
}

export const MobileProducts = React.memo(({ data }: IProps) => {
    const [__] = useTranslation();

    return (
        <MobileProductsWrapper>
            <MobileProductsImage
                src={"/config-files/mobile"}
                alt={__("Продукты на любой вкус")}
            />

            <MobileProductsList>
                {[
                    data.pc.pcText,
                    data.tablet1.tabletText,
                    data.tablet2.tabletText,
                    data.phone.phoneText,
                ].map((el, index) => (
                    <li key={`list + ${index}`}>{el}</li>
                ))}
            </MobileProductsList>
        </MobileProductsWrapper>
    );
}) as React.ComponentType<Subtract<IProps, IInjectedProps>>;
