import { ReactNode } from "react";

interface IProps {
    children: ReactNode;
    className?: string;
}

export const EmptyDiv = (props: IProps) => (
    <div className={props.className}>{props.children}</div>
);
