import { cover } from "polished";
import { pseudoElem } from "services/styled-components/variables";
import styled from "styled-components";

export const OneNewsHoverMark = styled.div`
    position: absolute;
    top: 50%;
    left: 50%;
    width: 15%;
    height: 1px;
    z-index: 2;
    transform: translate(-50%, -50%);
    opacity: 0;
    transition: opacity 0.25s ease-in-out;
    &::before,
    &::after {
        content: "";
        position: absolute;
        height: 1px;
        width: 100%;
        background: #fff;
        top: 50%;
        left: 0;
        margin-top: -0.5px;
    }
    &::before {
        transform: rotate(90deg);
    }
`;
export const OneNewsImgSC = styled.div`
    position: relative;
    margin-bottom: 20px;
    &::after {
        ${pseudoElem}
        ${cover()}
        transition: background-color 0.25s ease-in-out;
    }
    &:hover {
        &::after {
            background-color: rgba(39, 58, 76, 0.33);
        }
        ${OneNewsHoverMark} {
            opacity: 1;
        }
    }
`;
