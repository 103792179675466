import { usePermissions } from "components/check-roles/use-permissions";
import {
    manager,
    adAgent,
    partner,
    anyRole,
} from "components/check-roles/rules";

export function useAccountType() {
    const { checkPermissions } = usePermissions();

    if (checkPermissions([partner])) {
        return "partner";
    }
    if (checkPermissions([anyRole([adAgent, manager])])) {
        return "agent";
    }

    return null;
}
