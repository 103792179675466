import { ReactElement } from "react";
import { ChevronRight } from "@styled-icons/boxicons-regular/ChevronRight/ChevronRight";
import { useTranslation } from "react-i18next";
import { LocalizedNavLinkWithOptionalBackground } from "../routing/localized-navlink-with-optional-background/index";
import { BreadcrumbsItem, BreadcrumbsSC } from "./styled";

interface IProps {
    params?: string;
    title?: string;
    separator?: ReactElement;
    className?: string;
    newsListTitle?: string;
}

export const Breadcrumbs = React.memo((props: IProps) => {
    const [__] = useTranslation();
    const {
        params,
        title,
        separator: customSeparator,
        className,
        newsListTitle,
    } = props;
    const separator = customSeparator || <ChevronRight size="14" />;

    return (
        <BreadcrumbsSC className={className}>
            <BreadcrumbsItem>
                <LocalizedNavLinkWithOptionalBackground exact to="/">
                    {__("Главная")}
                </LocalizedNavLinkWithOptionalBackground>
                {separator}
            </BreadcrumbsItem>
            <BreadcrumbsItem>
                <LocalizedNavLinkWithOptionalBackground exact to="/news">
                    {newsListTitle ? newsListTitle : __("Новости")}
                </LocalizedNavLinkWithOptionalBackground>
                {params && separator}
            </BreadcrumbsItem>
            {params && title && (
                <BreadcrumbsItem>
                    <LocalizedNavLinkWithOptionalBackground
                        exact
                        to={`/news/${params}`}
                    >
                        {title}
                    </LocalizedNavLinkWithOptionalBackground>
                </BreadcrumbsItem>
            )}
        </BreadcrumbsSC>
    );
});
