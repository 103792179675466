import React from "react";
import { ModalNoStyles } from "components/components-common/modal";
import { ModalSize } from "components/components-common/modal/modal-size";
import { ModalStyle } from "components/components-common/modal/modal-style";
import { CheckCircle } from "@styled-icons/bootstrap/CheckCircle";
import {
    InfoMessageWrapper,
    InfoMessageTitle,
    InfoMessageMainBlock,
    InfoMessageSubTitle,
    ListItems,
    ConfirmButton,
} from "./styled";
interface IProps {
    companyName: string;
    modalProps: {
        isOpen: boolean;
        onRequestClose: () => void;
        modalSize: ModalSize | undefined;
        modalStyle: ModalStyle | undefined;
    };
}

export const InfoMessage = (props: IProps) => {
    return (
        <ModalNoStyles {...props.modalProps} preventCloseWrapper>
            <InfoMessageWrapper>
                <InfoMessageTitle>Обратите внимание!</InfoMessageTitle>
                <InfoMessageMainBlock>
                    <InfoMessageSubTitle>
                        {format(
                            "Согласно Закону РБ “О рекламе”, вы не можете стать партнером {CompanyName} если ваш ресурс:",
                            { CompanyName: props.companyName },
                        )}
                    </InfoMessageSubTitle>
                    <ListItems>
                        <li>предназначен для несовершеннолетних;</li>
                        <li>
                            специализируется на вопросах экологии, образования
                            либо охраны здоровья;
                        </li>
                        <li>является запрещенным в Республике Беларусь.</li>
                    </ListItems>
                    <ConfirmButton onClick={props.modalProps.onRequestClose}>
                        <CheckCircle size={15} />
                        хорошо
                    </ConfirmButton>
                </InfoMessageMainBlock>
            </InfoMessageWrapper>
        </ModalNoStyles>
    );
};
