import { useTranslation } from "react-i18next";
import { useChangeCountryPhoneCode } from "hooks/sign-up/use-change-country-phone-code";
import { Field, Form, useFormikContext } from "components/formik";
import { SubmitButton } from "components/formik/submit-button/submit-button";
import React from "react";
import { Paragraph, Title } from "services/styled-components/typogoraphy";
import {
    SignUpContainer,
    SignUpSubmit,
    SignUpWrapper,
} from "../../../../styled";
import { LoginField } from "../../../components/fields/login";
import { PasswordField } from "../../../components/fields/password";
import { ConfirmPasswordField } from "../../../components/fields/confirm-password";
import { WebsiteField } from "../../../components/fields/website";
import { WebsiteCategoryField } from "../../../components/fields/website-category";
import { LanguageField } from "../../../components/fields/language";
import { HowDidYouKnowField } from "../../../components/fields/how-did-you-know";
import { FirstnameField } from "../../../components/fields/firstname";
import { LastnameField } from "../../../components/fields/lastname";
import { EmailField } from "../../../components/fields/email";
import { CountryField } from "../../../components/fields/country";
import { PhoneField } from "../../../components/fields/phone";
import { AgreementsMeta } from "../../../components/agreements";
import { PrivacyDisclosure } from "../../../components/privacy-disclosure";
import { SignUpValues } from "hooks/sign-up/use-initial-values";
import {
    PARTNERS_1XSTAVKA,
    AFFILIATES_1XSTAVKA,
    _1XBET_PARTNERS,
    PARTNERS_1XBET,
} from "server/partner-programs";
import { PartnersProgramGeneralInformationContext } from "views";
import { useDefinedContext } from "hooks/use-context-exist";
import { LabelWithLink } from "./label-with-link";
import { InfoMessage } from "components/info-message-registration-form";
import { useModalState } from "hooks/use-modal-state";
import { ModalSize } from "components/components-common/modal/modal-size";
import { MessengerField } from "components/filters/fields/messenger";
import { MessengerLoginField } from "components/filters/fields/messengerLoginField";
import { usePaymentSystemsForRegistration } from "hooks/sign-up/use-payment-systems-registration";

interface FormWrapperProps {
    onPrivacyClick: () => void;
    onRulesClick: () => void;
    requiredMessenger?: boolean;
}

function FormWrapper(props: FormWrapperProps) {
    const { onPrivacyClick, onRulesClick, requiredMessenger } = props;
    const [__] = useTranslation();
    const [DT] = useTranslation();
    const { setFieldValue, values } = useFormikContext<SignUpValues>();
    const selectedCountry = useChangeCountryPhoneCode(setFieldValue, values);

    const { getOptions, changePaymentSystemIfUkraine, validateField } =
        usePaymentSystemsForRegistration();
    const paymentSystemOptions = getOptions(values.country);
    const paymentSystemField = values.paymentSystem?.fields[0];

    const partnersGeneralInformation = useDefinedContext(
        PartnersProgramGeneralInformationContext,
    );
    const InfoMessageState = useModalState({ size: ModalSize.Medium });

    return (
        <Form>
            <SignUpContainer>
                <SignUpWrapper>
                    <Title>{__("Логин и пароль")}</Title>
                    <Paragraph>
                        <LoginField />
                    </Paragraph>
                    <Paragraph>
                        <PasswordField />
                    </Paragraph>
                    <Paragraph>
                        <ConfirmPasswordField />
                    </Paragraph>
                    <Title>{__("Дополнительная информация")}</Title>
                    <Paragraph>
                        <WebsiteField />
                        <WebsiteCategoryField />
                    </Paragraph>
                    <Paragraph>
                        <LanguageField />
                        <HowDidYouKnowField />
                    </Paragraph>
                </SignUpWrapper>
                <SignUpWrapper>
                    <Title>{__("Контактная информация")}</Title>
                    <Paragraph>
                        <FirstnameField />
                        <LastnameField />
                    </Paragraph>
                    <Paragraph>
                        <EmailField />
                    </Paragraph>
                    <Paragraph>
                        <MessengerField required={requiredMessenger} />
                        <MessengerLoginField required={requiredMessenger} />
                    </Paragraph>
                    <Paragraph>
                        <CountryField
                            fieldOnChange={(e: any) => {
                                // показ спец. оповещения для Беларуси
                                const newSelectedCountry = e.target?.value;
                                if (
                                    (partnersGeneralInformation.partnersProgramId ===
                                        _1XBET_PARTNERS ||
                                        partnersGeneralInformation.partnersProgramId ===
                                            PARTNERS_1XBET) &&
                                    newSelectedCountry?.id === 22
                                ) {
                                    InfoMessageState.open();
                                }
                                void setFieldValue(
                                    "paymentSystem",
                                    changePaymentSystemIfUkraine(
                                        values.paymentSystem,
                                        newSelectedCountry,
                                    ),
                                );
                            }}
                        />
                        <PhoneField
                            countryCode={
                                selectedCountry && selectedCountry.code
                            }
                        />
                    </Paragraph>
                    <Title>{__("Платежные данные")}</Title>
                    <Paragraph>
                        <Field
                            name="paymentSystem"
                            label={`${__("Предпочитаемый метод выплаты")} *`}
                            type="react-select"
                            options={paymentSystemOptions}
                            selectFirst={true}
                            isLoading={!!paymentSystemOptions}
                            isSearchable={true}
                            isClearable={false}
                        />
                        {paymentSystemField && (
                            <Field
                                name="paymentSystem.fields[0].value"
                                type="text"
                                label={`${DT(paymentSystemField.name)} *`}
                                validate={value =>
                                    validateField(paymentSystemField, value)
                                }
                            />
                        )}
                    </Paragraph>
                </SignUpWrapper>
            </SignUpContainer>
            <AgreementsMeta
                onPrivacyClick={onPrivacyClick}
                onRulesClick={onRulesClick}
            />
            <PrivacyDisclosure />
            <SignUpSubmit>
                <Paragraph>
                    {partnersGeneralInformation.partnersProgramId !==
                        PARTNERS_1XSTAVKA &&
                    partnersGeneralInformation.partnersProgramId !==
                        AFFILIATES_1XSTAVKA ? (
                        <Field
                            name="rulesConfirmed"
                            type="checkbox"
                            label={__(
                                "Я ознакомился, понимаю и принимаю вышеизложенные условия и политики",
                            )}
                        />
                    ) : (
                        <Field
                            name="rulesConfirmed"
                            type="checkbox"
                            id="CheckboxField"
                            label={"_"}
                            labelComponent={LabelWithLink}
                        />
                    )}
                </Paragraph>
                <SubmitButton>{__("Зарегистрироваться")}</SubmitButton>
            </SignUpSubmit>
            <InfoMessage
                modalProps={InfoMessageState.modalProps}
                companyName={
                    partnersGeneralInformation.agentSiteInformation.companyName
                }
            />
        </Form>
    );
}

export { FormWrapper };
