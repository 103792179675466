import { OurPartnerImgWrapper, OurPartnerWrapper } from "./styled";

interface IInjectedProps {}

interface IProps extends IInjectedProps {
    ourPartnerBG: string;
    ourPartnerImg: string;
    ourPartnerLink: string;
    secondBG: boolean;
}

export const OurPartner = React.memo((props: IProps) => {
    return (
        <OurPartnerWrapper
            ourPartnerBG={props.ourPartnerBG}
            secondBG={props.secondBG}
        >
            <a
                href={props.ourPartnerLink}
                target="_blank"
                rel="nofollow noopener noreferrer"
            >
                <OurPartnerImgWrapper>
                    <img src={props.ourPartnerImg} alt="" />
                </OurPartnerImgWrapper>
            </a>
        </OurPartnerWrapper>
    );
}) as React.ComponentType<Subtract<IProps, IInjectedProps>>;
