import React from "react";
import { TestimonialsWrapper } from "./styled";
import { Testimonials } from "./testimonials";
import { useTestimonials } from "hooks/use-testimonials";
import { SliderGlobalStyles } from "./styled/slider-global-styles";
import { Settings } from "react-slick";

interface IProps {
    textColor: string;
    componentTitle: JSX.Element;
    activeColor: string;
    numberOfSlides: number;
    id?: string;
    settings?: Settings;
}

export const TestimonialsSlider = React.memo((props: IProps) => {
    const { testimonials } = useTestimonials();

    if (testimonials.length === 0) {
        return null;
    }
    return (
        <>
            <SliderGlobalStyles />
            <TestimonialsWrapper textColor={props.textColor}>
                {props.componentTitle}
                <Testimonials
                    activeColor={props.activeColor}
                    numberOfSlides={props.numberOfSlides}
                    settings={props.settings}
                />
            </TestimonialsWrapper>
        </>
    );
}) as React.ComponentType<IProps>;
