import { StyledUniversalField } from "components/formik/universal-field/styled";
import { textInputs } from "polished";
import { inputColor } from "services/styled-components/themes";
import styled from "styled-components";
import { rtl } from "services/styled-components/rtl";

export const SignIn = styled.div`
    ${StyledUniversalField} {
        ${textInputs()} {
            ${rtl`padding-left: 40px;`};
        }
    }
    p {
        position: relative;
    }
    svg {
        color: ${inputColor};
        position: absolute;
        z-index: 1;
        top: 10px;
        ${rtl`left: 12px;`};
    }
`;
