import { Annotation } from "./styled";
import { ListItems } from "./list-items";
import { Title } from "styled/layouts";

import { PARTNERS_MELBETRU } from "./../../../../../../server/partner-programs";
import { usePartnersProgram } from "hooks/use-partners-program-id";

interface IProps {
    firstPartTitle: string;
    secondPartTitle: string;
    annotation: string;
    color?: string;
}

export const BecomeAnAffiliateTitle = ({
    firstPartTitle,
    secondPartTitle,
    annotation,
    color,
}: IProps) => {
    const partnersProgramId = usePartnersProgram()?.programId;
    return (
        <>
            <Title color={color}>
                <span>{firstPartTitle}</span>
                <span>{secondPartTitle}</span>
            </Title>
            <Annotation>{annotation}</Annotation>
            {partnersProgramId === PARTNERS_MELBETRU && <ListItems />}
        </>
    );
};
