import styled from "styled-components";

export const BreadcrumbsSC = styled.div`
    font-size: 14px;
    padding: 20px 0;
`;

export const BreadcrumbsItem = styled.div`
    display: inline-block;

    svg {
        display: inline-block;
        margin: 0 10px;
        color: ${props => props.theme.adminTitleColor};
    }

    a {
        text-decoration: none;
        color: ${props => props.theme.backgroundColor};
        &.active {
            color: ${props => props.theme.accentColor};
        }
    }
`;
