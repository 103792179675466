/* eslint-disable @typescript-eslint/no-unused-vars */
import {
    GetTestimonials,
    GetTestimonialsVariables,
    TestimonialsParams,
} from "gql/types/operation-result-types";
import { useQuery, gql } from "@apollo/client";

const GET_TESTIMONIALS = gql`
    query GetTestimonials($params: TestimonialsParams!) {
        partnersProgram {
            testimonials(params: $params) {
                id
                author
                description
                href
                image
            }
        }
    }
`;

export const useTestimonials = (variables?: TestimonialsParams) => {
    const { data, loading, error } = useQuery<
        GetTestimonials,
        GetTestimonialsVariables
    >(GET_TESTIMONIALS, { ssr: true, variables: { params: variables || {} } });

    return {
        testimonials: data?.partnersProgram.testimonials || [],
        loading,
        error,
    };
};
