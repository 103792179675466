import { auth } from "components/check-roles/rules";
import { usePermissions } from "components/check-roles/use-permissions";
import React from "react";
import { useTranslation } from "react-i18next";
import { RouteComponentProps } from "react-router";
import { AuthLayout } from "../auth-layout";
import { SimpleSignIn } from "components/simple-sign-in";
import { SignIn as SignInSC } from "./styled";
import { TwoFAForm } from "components/two-fa-form";
import _ from "lodash";
import { Loader } from "components/components-common/loaders/cub";
import { SignInHelmet } from "components/helmet/sign-in-helmet";
import { useSignIn } from "hooks/sign-in/use-sign-in";
import { SignInStage } from "hooks/sign-in/stage/use-stage";
import { PartnersProgramGeneralInformationContext } from "views";
import { useDefinedContext } from "hooks/use-context-exist";
import { DefaultTheme, StyledComponent } from "styled-components";
import { _1XBET_PARTNERS_IRELAND } from "server/partner-programs";

interface IInjectedProps extends RouteComponentProps<{ partnerId: string }> {}

interface IProps extends IInjectedProps {
    signBox?: StyledComponent<"div", DefaultTheme>;
}

export const SignInPage = React.memo((props: IProps) => {
    const { match, signBox } = props;
    const {
        stylesTheme,
        partnersProgramId,
        agentSiteInformation: { companyName },
    } = useDefinedContext(PartnersProgramGeneralInformationContext);
    const { checkPermissions } = usePermissions();
    const [__] = useTranslation();
    const partnerId = match.params.partnerId
        ? _.parseInt(match.params.partnerId)
        : undefined;

    const { stageControl, login, _2fa } = useSignIn(partnerId);

    if (
        stageControl.stage === SignInStage.SIMPLE_SIGN_IN &&
        checkPermissions([auth])
    ) {
        stageControl.setStage(SignInStage.FINISH);
    }

    let title = "";
    let description = "";

    if (partnersProgramId === _1XBET_PARTNERS_IRELAND) {
        title = format(__("Вход для партнеров {companyName}"), { companyName });
        description = format(
            __("Вход в ЛК участника партнерской программы {companyName}"),
            { companyName },
        );
    }

    return (
        <AuthLayout signBox={signBox}>
            <SignInHelmet
                stage={stageControl.stage}
                setStage={stageControl.setStage}
                title={title}
                description={description}
            />
            <SignInSC>
                {(() => {
                    switch (stageControl.stage) {
                        case SignInStage.SIMPLE_SIGN_IN:
                        case SignInStage.SIMPLE_SIGN_IN_ERROR:
                            return <SimpleSignIn login={login} />;
                        case SignInStage.TWO_FA:
                        case SignInStage.TWO_FA_ERROR:
                            return (
                                <TwoFAForm
                                    {..._2fa}
                                    submitText={__("Отправить")}
                                />
                            );
                        case SignInStage.SUCCESS:
                        case SignInStage.FINISH:
                            return (
                                <Loader
                                    cubesColor={stylesTheme.accentColor}
                                    bgColor={stylesTheme.backgroundColor}
                                />
                            );
                    }
                })()}
            </SignInSC>
        </AuthLayout>
    );
}) as React.ComponentType<Subtract<IProps, IInjectedProps>>;
