/* eslint-disable no-nested-ternary */
import { useTranslation } from "react-i18next";
import { useDefinedContext } from "hooks/use-context-exist";
import { PartnersProgramGeneralInformationContext } from "views";
import Helmet, { HelmetsOpenedVisor } from "react-helmet-with-visor";

interface IProps {
    script?: string | null;
}
export const SignUpHelmet = (props: IProps) => {
    const partnersGeneralInformation = useDefinedContext(
        PartnersProgramGeneralInformationContext,
    );
    const [__] = useTranslation();
    const titleText = format(
        __("Регистрация в партнерской программе {PartnerCompanyName}."),
        {
            PartnerCompanyName:
                partnersGeneralInformation.partnerProgramCompanyName,
        },
    );
    const descriptionText = format(
        __(
            "Регистрация и стабильный заработок вместе с партнерской программой {PartnerCompanyName}.",
        ),
        {
            PartnerCompanyName:
                partnersGeneralInformation.partnerProgramCompanyName,
        },
    );
    return (
        <Helmet>
            <title>{titleText}</title>
            <meta property="og:title" content={titleText} />
            <meta name="description" content={descriptionText} />
            <meta property="og:description" content={descriptionText} />
            <HelmetsOpenedVisor>{props.script}</HelmetsOpenedVisor>
        </Helmet>
    );
};
