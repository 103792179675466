import styled from "styled-components";
import { darken } from "polished";

export const SubTitleText = styled.div`
    padding: 1.37em 1.33em 0.1em 1.33em;
    margin-top: -1.37em;
    margin-bottom: 1em;
    font-size: 1.5em;
    font-weight: bold;
    color: ${props => darken(0.1, props.theme.landingTextColor)};
    border-left: 0.147em solid ${props => props.theme.accentColor};

    p {
        margin-bottom: 0.78em;
        &:not(:first-child) {
            text-transform: uppercase;
        }
    }
`;
