import { GetPartnersProgramGeneralInfo_partnersProgram_generalInformation_landingMainPage_termsIcons } from "gql/types/operation-result-types";
import { Wrapper, WrapperFullWidth } from "styled/layouts";
import { FeaturesSC } from "./styled";
import { FeaturesItems } from "./features-items";

interface IInjectedProps {}

interface IProps extends IInjectedProps {
    termsIcons: GetPartnersProgramGeneralInfo_partnersProgram_generalInformation_landingMainPage_termsIcons[];
    bgColor?: string;
    color?: string;
}

export const Features = React.memo((props: IProps) => {
    return (
        <WrapperFullWidth>
            <Wrapper>
                <FeaturesSC>
                    <FeaturesItems
                        termsIcons={props.termsIcons}
                        bgColor={props.bgColor}
                        color={props.color}
                    />
                </FeaturesSC>
            </Wrapper>
        </WrapperFullWidth>
    );
}) as React.ComponentType<IProps>;
