import { RouteComponentProps } from "react-router";
import {
    FormikConsoleErrors,
    IFormikConsoleErrorsProps,
} from "components/formik/formik-console-errors";
import { Field, Form } from "components/formik";
import { useTranslation } from "react-i18next";
import { SubmitButton } from "components/formik/submit-button/submit-button";
import { onSubmitWrapper } from "components/formik/on-submit-wrapper";
import { GeneralInfromationContextProvider } from "components/general-information-context-provider";
import { RootHelmetAndScriptsBodyInjector } from "components/root-helmet";
import { ThemeProvider } from "components/theme/theme-provider";
import {
    INewPassword,
    useNewPassword,
} from "hooks/new-password/use-new-password";
import { IPasswordProps } from "components/formik/universal-field";

interface INewPasswordForm
    extends React.FC<
        RouteComponentProps & IFormikConsoleErrorsProps<INewPassword>
    > {
    NewPassword: React.FC<Partial<IPasswordProps>>;
    RepeatPassword: React.FC<Partial<IPasswordProps>>;
    SubmitButton: typeof SubmitButton;
}

export const NewPasswordForm: INewPasswordForm = props => {
    const search = new URLSearchParams(props.location.search);
    const id = search.get("id") || "";
    const { validationSchema, initialValues, onSubmit } = useNewPassword(id);

    return (
        <GeneralInfromationContextProvider>
            <ThemeProvider>
                <RootHelmetAndScriptsBodyInjector />
                <FormikConsoleErrors
                    initialValues={initialValues}
                    validationSchema={validationSchema}
                    onSubmit={onSubmitWrapper(onSubmit, validationSchema)}
                >
                    {formikProps => (
                        <Form>
                            {typeof props.children === "function"
                                ? props.children(formikProps)
                                : props.children}
                        </Form>
                    )}
                </FormikConsoleErrors>
            </ThemeProvider>
        </GeneralInfromationContextProvider>
    );
};

const NewPasswordInputField = (props: IPasswordProps) => {
    const [__] = useTranslation();
    const { placeholder = __("Новый пароль"), ...rest } = props;

    return (
        <Field
            placeholder={placeholder}
            autoComplete="off"
            autoFocus
            {...rest}
            name="password"
            type="password"
        />
    );
};

const RepeatPasswordInputField = (props: IPasswordProps) => {
    const [__] = useTranslation();
    const { placeholder = __("Подтвердите пароль"), ...rest } = props;

    return (
        <Field
            placeholder={placeholder}
            autoComplete="off"
            {...rest}
            name="rePassword"
            type="password"
        />
    );
};

NewPasswordForm.NewPassword = NewPasswordInputField;
NewPasswordForm.RepeatPassword = RepeatPasswordInputField;
NewPasswordForm.SubmitButton = SubmitButton;
