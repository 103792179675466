import { useTranslation } from "react-i18next";
import { Paragraph } from "../../../../../services/styled-components/typogoraphy";
import { useDefinedContext } from "../../../../../hooks/use-context-exist";
import { PartnersProgramGeneralInformationContext } from "../../../../../views";

export function PrivacyDisclosure() {
    const [__] = useTranslation();
    const partnersGeneralInformation = useDefinedContext(
        PartnersProgramGeneralInformationContext,
    );

    return (
        <Paragraph>
            {format(
                __(
                    "{CompanyName} может передавать Ваши личные данные, собранные в связи с регистрацией на данном веб-сайте, своим аффилированным компаниям в разных странах и третьим лицам, оказывающим услуги компании {CompanyName}.",
                ),
                {
                    CompanyName:
                        partnersGeneralInformation.agentSiteInformation
                            .companyName,
                },
            )}
        </Paragraph>
    );
}
