import { ChevronsRight } from "@styled-icons/boxicons-regular/ChevronsRight/ChevronsRight";
import moment from "moment";
import { useTranslation } from "react-i18next";
import {
    NewsContent,
    NewsDataTime,
    NewsHeader,
    NewsImgCon,
    NewsItemSC,
    NewsLink,
    NewsLinkMore,
    NewsTitle,
} from "./styled";

interface IInjectedProps {}

interface IProps extends IInjectedProps {
    newsLink: string;
    newsImgUrl?: string;
    newsDataTime: string;
    newsTitle: string;
    language: string;
}

export const OtherNewsCard = React.memo((props: IProps) => {
    const [__] = useTranslation();

    return (
        <NewsItemSC>
            <NewsLink to={`/news/${props.newsLink}`}>
                <NewsHeader>
                    <NewsImgCon>
                        <div />
                        {props.newsImgUrl && (
                            <img
                                src={props.newsImgUrl}
                                alt="news_preview_image"
                                role="presentation"
                            />
                        )}
                    </NewsImgCon>
                </NewsHeader>
                <NewsContent>
                    <NewsDataTime>
                        {moment(props.newsDataTime)
                            .locale(props.language)
                            .format("L")}
                    </NewsDataTime>
                    <NewsTitle>{props.newsTitle}</NewsTitle>
                    <NewsLinkMore>
                        {__("Подробнее")}
                        <ChevronsRight size="13" />
                    </NewsLinkMore>
                </NewsContent>
            </NewsLink>
        </NewsItemSC>
    );
}) as React.ComponentType<Subtract<IProps, IInjectedProps>>;
