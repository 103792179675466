import {
    DesktopProductsWrapper,
    Bg,
    Pc,
    Tablet,
    Phone,
    PcText,
    TabletGroup,
    TabletText,
    TabletText2,
    PhoneText,
    Mask,
    PhoneGroup,
    PcGroup,
} from "./styled";
import bg from "assets/images/23/bg.png";
import pc from "assets/images/23/pc.png";
import tablet from "assets/images/23/tablet.png";
import phone from "assets/images/23/phone.png";

interface IInjectedProps {}

interface IProps extends IInjectedProps {
    data: any;
}

export const DesktopProducts = React.memo(({ data }: IProps) => {
    return (
        <DesktopProductsWrapper>
            <Bg src={bg} />
            <TabletGroup>
                <Tablet src={tablet} />
                <TabletText>
                    <div>{data.tablet1.tabletTitle}</div>
                    <span>{data.tablet1.tabletText}</span>
                </TabletText>
                <TabletText2>
                    <div>{data.tablet1.tabletTitle}</div>
                    <span>{data.tablet2.tabletText}</span>
                </TabletText2>
            </TabletGroup>
            <PhoneGroup>
                <Phone src={phone} />
                <PhoneText>
                    <div>{data.phone.phoneTitle}</div>
                    <span>{data.phone.phoneText}</span>
                </PhoneText>
            </PhoneGroup>
            <PcGroup>
                <Pc src={pc} />
                <PcText>
                    <div>{data.pc.pcTitle}</div>
                    <span>{data.pc.pcText}</span>
                </PcText>
            </PcGroup>
            <Mask />
        </DesktopProductsWrapper>
    );
}) as React.ComponentType<Subtract<IProps, IInjectedProps>>;
