import styled from "styled-components";
import { rtl } from "services/styled-components/rtl";
import { media } from "services/styled-components/variables";

export const MobileProductsWrapper = styled.div`
    ${media.md`
        display: none;
    `}
`;

export const MobileProductsImage = styled.img`
    display: block;
    max-width: 100%;
    margin: 0 auto 30px;
`;

export const MobileProductsList = styled.ul`
    font-size: 16px;
    color: #000;
    max-width: 380px;
    margin: auto;
    li {
        margin-bottom: 0.625em;
        &:last-child {
            margin-bottom: 0;
        }
    }
    li::before {
        content: "";
        width: 0;
        height: 0;
        border-style: solid;
        ${rtl`border-width: 8px 5px 0 5px;`};
        border-color: ${props => props.theme.accentColor} transparent
            transparent transparent;
        display: inline-block;
        ${rtl`margin-right: 1rem;`};
    }
`;
