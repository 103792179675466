import { Briefcase } from "@styled-icons/fa-solid/Briefcase/Briefcase";
import { Access } from "components/check-roles/access";
import { exceptRoles, auth } from "components/check-roles/rules";
import { useTranslation } from "react-i18next";
import {
    IButtonGradientProps,
    SingUpButton,
} from "components/header/components/sing-up-button";

export const ButtonStartEarning = (props?: IButtonGradientProps) => {
    const [__] = useTranslation();

    return (
        <Access guards={[exceptRoles([auth])]}>
            <SingUpButton id="start-earning_sign-up" {...props}>
                <Briefcase size={18} />
                {__("Начать зарабатывать")}
            </SingUpButton>
        </Access>
    );
};
