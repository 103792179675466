import styled from "styled-components";

export const MailBlock = styled.a`
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 1.25em;
    max-width: 18em;
    padding: 1.875em 0.625em;
    margin: 0 auto 3.75em;
    color: #212121;
    text-transform: uppercase;
    font-weight: bold;
    border-radius: 3px;
    box-shadow: 1px 2px 7px rgba(0, 0, 0, 0.1);
`;
