import { OneNewsHoverMark, OneNewsImgSC } from "./styled";

interface IInjectedProps {}

interface IProps extends IInjectedProps {
    src?: string;
}

export const OneNewsImg = React.memo((props: IProps) => {
    return (
        <OneNewsImgSC>
            <OneNewsHoverMark />
            <img src={props.src} />
        </OneNewsImgSC>
    );
}) as React.ComponentType<Subtract<IProps, IInjectedProps>>;
