import styled from "styled-components";
export const ListItemsSC = styled.ul`
    width: fit-content;
    margin: 0 auto;
    text-align: left;

    li {
        margin-bottom: 1.6em;
        &:before {
            content: "";
            width: 0;
            height: 0;
            border-style: solid;
            border-width: 5px 0 5px 8px;
            border-color: transparent transparent transparent
                ${props => props.theme.accentColor};
            display: inline-block;
            margin-right: 0.625em;
        }
    }
`;
