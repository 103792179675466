import { position } from "polished";
import { backgroundColor3 } from "services/styled-components/themes";
import { media } from "services/styled-components/variables";
import styled, { css } from "styled-components";
import { keyframes } from "styled-components";
import { Wrapper, WrapperFullWidth } from "styled/layouts";
import { MenuSC } from "./components/menu/styled";
import { ServicesSC } from "./components/services/styled";
import { rtl } from "services/styled-components/rtl";
import { AppLink } from "components/android-app/android-logo/styled";

export const BlackSpace = styled.div`
    pointer-events: all;
    transition: visibility 0s linear 0.3s, opacity 0.3s linear;
    visibility: collapse;
    opacity: 0;
`;

export const LandingMenuCloseButton = styled.div`
    pointer-events: all;
    z-index: 10;
    cursor: pointer;
    width: 40px;
    height: 28px;
    span {
        margin: 0 auto;
        position: relative;
        top: 12px;
        transition-duration: 0s !important;
        transition-delay: 0.2s !important;
    }
    span:before,
    span:after {
        position: absolute;
        content: "";
    }
    span,
    span:before,
    span:after {
        width: 40px;
        height: 4px;
        background-color: ${props => props.theme.textColor};
        display: block;
        pointer-events: none;
        border-radius: 2px;
    }
    span:before {
        margin-top: -12px;
        transition-property: margin, transform !important;
        transition-duration: 0.2s !important;
        transition-delay: 0.2s, 0s !important;
    }
    span:after {
        margin-top: 12px;
        transition-property: margin, transform !important;
        transition-duration: 0.2s !important;
        transition-delay: 0.2s, 0s !important;
    }
    ${media.md`
        display: none;
    `}
`;

export const AuxiliaryBlock = styled.div`
    pointer-events: all;
    position: relative;
    min-height: 100%;
    height: 100%;
`;

export const WrapperFullWidthFirst = styled(WrapperFullWidth)`
    background-color: ${props => props.theme.backBackgroundColor};
    ${Wrapper} {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        align-items: center;
    }
    ${media.mdMax`
        display: none;
    `}
`;

export const MessengersHeaderSC = styled.ul`
    li {
        display: inline-block;
        a {
            display: inline-block;
            margin-right: 8px;
            color: ${props => props.theme.textColor};
            line-height: 0;
            font-size: 0;
            transition: 0.3s ease;
            svg {
                ${rtl`margin-right: 0.62em;`};
                path {
                    transition: 0.3s ease;
                }
            }
            &:hover {
                color: ${props => props.theme.activeColor};
                svg path {
                    fill: ${props => props.theme.activeColor};
                }
            }
        }
        &:first-child a {
            margin-right: 12px;
        }
    }
    ${media.md`
        display: none;
    `}
`;

export const WrapperFullWidthSecond = styled(WrapperFullWidth)`
    background-color: ${props => props.theme.landingHeaderColor};
    ${media.mdMax`
        height: 100%;
    `}
    ${Wrapper} {
        height: 100%;
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        flex-direction: row;
    }
    ${MenuSC} {
        flex: 1 0 auto;
    }
`;

const transform = keyframes`
    from {
        transform: translateX(234px);
    }

    to {
        transform: translateX(0);
    }
`;

export const MessengersMenuSC = styled.ul``;

export interface IProps {
    isMenuBurgerClickedOnLanding?: boolean;
}
export const HeaderSC = styled.header<IProps>`
    pointer-events: none;
    font-size: 14px;
    z-index: 2;
    ${props => media.mdMax`
    height: 56px;
    position: fixed;
    width: 100%;
    max-width: 100vw;
    ${ServicesSC} {
        display: flex;
        ${rtl`
            margin-left: auto;
            margin-right: 25px;
        `};
        ${media.xsMax`
            ${rtl`margin-right: 5px;`};
        `}

    }
    ${
        props.isMenuBurgerClickedOnLanding &&
        css`
            ${BlackSpace} {
                visibility: visible;
                opacity: 1;
                transition-delay: 0s;
                ${position("fixed", "0", "0", "0", "0")};
                background-color: rgba(0, 0, 0, 0.8);
            }
            ${LandingMenuCloseButton} {
                span {
                    background-color: rgba(255, 255, 255, 0);
                    transition-delay: 0.2s !important;
                }
                span::before {
                    margin-top: 0;
                    transform: rotate(45deg) !important;
                    transition-delay: 0s, 0.2s !important;
                }
                span::after {
                    margin-top: 0;
                    transform: rotate(-45deg) !important;
                    transition-delay: 0s, 0.2s !important;
                }
            }
            ${MenuSC} {
                animation: ${transform} 0.3s ease 1 normal forwards;
                overflow: auto;
                ${position("fixed", "0", "0", "0", "")};
                padding-bottom: 41px;
                margin-top: 55px;
                width: 234px;
                display: block;
                background-color: ${props.theme.backBackgroundColor};
                font-size: 12.25px;
                text-align: center;
                ul {
                    li {
                        position: relative;
                        & ${AppLink} {
                            text-transform: none;
                        }
                        a {
                            text-transform: uppercase;
                            font-weight: 700;
                            display: flex;
                            justify-content: center;
                            padding-top: 15px;
                            padding-bottom: 15px;
                            border-bottom: 1px solid ${backgroundColor3};
                            & svg {
                                width: 50px;
                            }
                        }
                    }
                }
                ${MessengersMenuSC} {
                    width: 100%;
                    li {
                        display: block;
                        a {
                            justify-content: flex-start;
                            align-items: center;
                            padding-left: 20px;
                            padding-right: 20px;
                            text-transform: none;
                            font-weight: 400;
                            svg {
                                width: auto;
                                ${rtl`margin-right: 0.62em;`};
                            }
                        }
                    }
                }
            }
        `
    }
    `}
`;
