import { AndroidLogo } from "components/android-app/android-logo";
import { HeaderItem } from "components/android-app/android-logo/styled";
import { LanguageSwitcher } from "components/components-common/language/language-switcher";
import { Logo } from "components/logo";
import { useDefinedContext } from "hooks/use-context-exist";
import { connect } from "react-redux";
import { Action } from "redux";
import { Wrapper } from "styled/layouts";
import { PartnersProgramGeneralInformationContext } from "views";
import { StatesActionCreators } from "../../services/redux/root/states/actions";
import { statesSelectors } from "../../services/redux/root/states/selectors";
import { Menu } from "./components/menu";
import { AuthLandingElements } from "./components/services";
import { Messengers } from "./components/social-network";
import { MessengersSC } from "./components/social-network/styled";
import {
    AuxiliaryBlock,
    BlackSpace,
    HeaderSC,
    LandingMenuCloseButton,
    WrapperFullWidthFirst,
    WrapperFullWidthSecond,
} from "./styled";
import { MEGAPARI_PARTNERS } from "server/partner-programs";

interface IInjectedProps {
    isMenuBurgerClickedOnLanding: boolean;
    setIsMenuBurgerClickedOnLanding: (visibility: boolean) => Action;
}

interface IProps extends IInjectedProps {}

export const HeaderLanding = connect(
    state => ({
        isMenuBurgerClickedOnLanding:
            statesSelectors.getIsMenuBurgerClickedOnLanding(state),
    }),
    dispatch => ({
        setIsMenuBurgerClickedOnLanding: (visibility: boolean) =>
            dispatch(
                StatesActionCreators.setIsMenuBurgerClickedOnLanding(
                    visibility,
                ),
            ),
    }),
)(
    React.memo((props: IProps) => {
        const {
            setIsMenuBurgerClickedOnLanding,
            isMenuBurgerClickedOnLanding,
        } = props;

        const partnersGeneralInformation = useDefinedContext(
            PartnersProgramGeneralInformationContext,
        );

        const closeMenu = () => setIsMenuBurgerClickedOnLanding(false);
        const toggleMenu = (event: React.MouseEvent) => {
            setIsMenuBurgerClickedOnLanding(!isMenuBurgerClickedOnLanding);
            event.stopPropagation();
        };

        return (
            <HeaderSC
                isMenuBurgerClickedOnLanding={isMenuBurgerClickedOnLanding}
            >
                <BlackSpace onClick={closeMenu} />

                <AuxiliaryBlock onClick={closeMenu}>
                    <WrapperFullWidthFirst>
                        <Wrapper>
                            <MessengersSC>
                                <Messengers />
                            </MessengersSC>
                            {partnersGeneralInformation.androidAppLinks && (
                                <HeaderItem>
                                    <AndroidLogo
                                        size="30px"
                                        link={
                                            partnersGeneralInformation.androidAppLinks
                                        }
                                        isAbleToHide={
                                            partnersGeneralInformation.partnersProgramId ===
                                            MEGAPARI_PARTNERS
                                        }
                                    />
                                </HeaderItem>
                            )}
                            <LanguageSwitcher
                                textColor={
                                    partnersGeneralInformation.stylesTheme
                                        .textColor
                                }
                                bgColor={
                                    partnersGeneralInformation.stylesTheme
                                        .languageSwitcherBG
                                }
                                accentColor={
                                    partnersGeneralInformation.stylesTheme
                                        .accentColor
                                }
                                screen="desktop"
                            />
                        </Wrapper>
                    </WrapperFullWidthFirst>
                    <WrapperFullWidthSecond>
                        <Wrapper>
                            <Logo />
                            <Menu />
                            <AuthLandingElements />
                            <LandingMenuCloseButton onClick={toggleMenu}>
                                <span />
                            </LandingMenuCloseButton>
                        </Wrapper>
                    </WrapperFullWidthSecond>
                </AuxiliaryBlock>
            </HeaderSC>
        );
    }),
) as React.ComponentType<Subtract<IProps, IInjectedProps>>;
