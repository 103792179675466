import { ConditionalList } from "components/conditional-list";
import { GetActualNews_partnersProgram_news_getActualNews_news } from "gql/types/operation-result-types";
import { useLanguage } from "components/language-provider";
import _ from "lodash";
import { useTranslation } from "react-i18next";
import { useOtherNews } from "../../hooks/use-other-news";
import { OtherNewsCard } from "./other-news-card";
import { OtherNewsSC, OtherNewsTitle } from "./styled";
import { NoData } from "components/no-data";

interface IInjectedProps {}

interface IProps extends IInjectedProps {
    params: string;
}

export const OtherNews = ((props: IProps) => {
    const [__] = useTranslation();
    const { language } = useLanguage();
    const { params } = props;
    const { loading, error, otherNews } = useOtherNews(language.code, params);

    if ((!otherNews || !otherNews.length) && loading === false) {
        return null;
    }

    return (
        <OtherNewsSC>
            <OtherNewsTitle>{__("Другие новости")}</OtherNewsTitle>
            <ConditionalList
                error={error}
                loading={loading}
                data={otherNews}
                renderEmpty={() => <NoData />}
                renderExist={(
                    data: GetActualNews_partnersProgram_news_getActualNews_news[],
                ) => (
                    <>
                        {_.map(data, news => (
                            <OtherNewsCard
                                newsImgUrl={news.PImage}
                                newsLink={news.url}
                                newsDataTime={news.datePublick}
                                newsTitle={news.title}
                                language={language.code}
                            />
                        ))}
                    </>
                )}
            />
        </OtherNewsSC>
    );
}) as React.ComponentType<Subtract<IProps, IInjectedProps>>;
