import { cover, darken } from "polished";
import { media, pseudoElem } from "services/styled-components/variables";
import styled from "styled-components";
interface IFeatureItem {
    bgColor?: string;
    color?: string;
}

export const FeatureItem = styled.div<IFeatureItem>`
    position: relative;
    box-sizing: border-box;
    padding: 1.875em 0.625em;
    /* font-size: 0.9rem; */
    text-align: center;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    min-height: 220px;
    color: ${props => props.color};
    &::before {
        ${pseudoElem}
        ${cover()}
        z-index: -1;
        border-radius: 3px 3px 0 0;
        background-color: ${props =>
            props.bgColor ? props.bgColor : "rgba(255, 255, 255, 0.95)"};
        box-shadow: 1px 2px 7px rgba(0, 0, 0, 0.1),
            inset 0 4px 0 ${props => props.theme.accentColor};
    }
    ${media.smMax`
        min-height:auto;
    `}
`;
export const FeatureImg = styled.div`
    line-height: 1;
    svg {
        color: ${props => props.theme.accentColor};
    }
`;
export const FeatureTitle = styled.h3`
    color: ${props =>
        props.color ? props.color : darken(0.1, props.theme.landingTextColor)};
    font-size: 1em;
    font-weight: bold;
    text-transform: uppercase;
    line-height: 1.2;
    margin-top: 20px;
    margin-bottom: 1.7em;
    padding: 0;
`;
export const FeatureAnnotation = styled.div`
    font-size: 0.938em;
    word-wrap: break-word;
`;
