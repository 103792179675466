import { useTranslation } from "react-i18next";
import { SiteCategoryField } from "components/filters/fields/site-category";

export function WebsiteCategoryField() {
    const [__] = useTranslation();
    return (
        <SiteCategoryField
            name="websiteCategory"
            key="websiteCategory"
            label={`${__("Категория сайта")} *`}
            selectFirst={true}
            isClearable={false}
        />
    );
}
