import { rgba } from "polished";
import { backgroundColor4Polished } from "services/styled-components/themes";
import styled, { css } from "styled-components";
import { ColoredNavLink } from "styled/link";
import { rtl } from "services/styled-components/rtl";

export const AttentionSC = styled.div`
    z-index: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    margin-bottom: 3.75em;
    ${ColoredNavLink} {
        display: inline-block;
        vertical-align: middle;
    }
`;

export const AttentionCube = styled.div`
    position: absolute;
    top: 50%;
    margin-top: -0.375em;
    width: 0.75em;
    height: 0.75em;
    transform: rotate(45deg);
    border: 1px solid ${props => props.theme.accentColor};
    box-shadow: inset 1px 1px 0 ${props => props.theme.textColor},
        inset -1px -1px 0 0 ${props => props.theme.textColor},
        inset -1px -1px 0 6px ${props => props.theme.accentColor};
`;

const AttentionItemTypeLeft = css`
    ${props => rtl`margin-right: 32px;background-image: linear-gradient(
        -90deg,
        ${props.theme.accentColor} 0%,
        ${backgroundColor4Polished(rgba, 0)} 100%
    );`};

    ${AttentionCube} {
        ${rtl`right: 0;`};
    }
`;

const AttentionItemTypeRight = css`
    ${props => rtl`margin-left: 32px;
    background-image: linear-gradient(
        90deg,
        ${props.theme.accentColor} 0%,
        ${backgroundColor4Polished(rgba, 0)} 100%
    );
    `};

    ${AttentionCube} {
        ${rtl`left: 0;`};
    }
`;

export enum AttentionItemType {
    Left,
    Right,
}

interface IProps {
    type: AttentionItemType;
}

export const AttentionItem = styled.div<IProps>`
    position: relative;
    display: inline-block;
    vertical-align: middle;
    background-clip: content-box;
    flex: 1 1 auto;
    height: 1px;
    ${props => props.type === AttentionItemType.Left && AttentionItemTypeLeft};
    ${props =>
        props.type === AttentionItemType.Right && AttentionItemTypeRight};
`;
