import { useCallback, useState, useMemo } from "react";
import { useTranslationList } from "../use-translations-list";
import * as yup from "yup";
import { useMutation } from "@apollo/client";
import {
    ResetPassword,
    ResetPasswordVariables,
} from "gql/types/operation-result-types";
import RESET_PASSWORD from "./reset-password.gql";
import { resetPasswordMutation } from "./use-reset-pass-mutation";
import { useValidationRules } from "hooks/sign-up/use-validation-rules";

export function useResetPassword(throughEmail = true, onSuccess?: () => void) {
    const initialValues = { login: "", email: "" };
    const {
        PASSWORD_RESET_MESSAGE_SENT,
        PASSWORD_RESET_MESSAGE_NO_SENT,
        FAILED_RESET_PASSWORD,
        EMAIL_SENT_TO_CHANGE_PASSWORD,
    } = useTranslationList();
    const noModalMessageSent = throughEmail
        ? EMAIL_SENT_TO_CHANGE_PASSWORD
        : PASSWORD_RESET_MESSAGE_SENT;
    const { login: loginRule, email: emailRule } = useValidationRules();

    const [resetPassResponse, setResetPassResponse] = useState({
        error: false,
        message: "",
    });

    const [mutation] = useMutation<ResetPassword, ResetPasswordVariables>(
        RESET_PASSWORD,
    );

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const resetPassword = useCallback(resetPasswordMutation(), []);

    const validationSchema = useMemo(
        () =>
            yup.object().shape({
                login: loginRule,
                email: emailRule,
            }),
        [emailRule, loginRule],
    );

    const onSubmit = useCallback(
        async ({
            login,
            email,
            isModal,
        }: {
            login: string;
            email: string;
            isModal?: boolean;
        }) => {
            const isSuccess = await resetPassword(
                mutation,
                { login, email, throughEmail },
                setResetPassResponse,
                {
                    messageSent: isModal ? undefined : noModalMessageSent,
                    messageNoSent: PASSWORD_RESET_MESSAGE_NO_SENT,
                    failed: FAILED_RESET_PASSWORD,
                },
            );
            if (isSuccess && onSuccess) {
                onSuccess();
            }
        },
        [
            resetPassword,
            mutation,
            noModalMessageSent,
            PASSWORD_RESET_MESSAGE_NO_SENT,
            FAILED_RESET_PASSWORD,
            throughEmail,
            onSuccess,
        ],
    );

    return {
        validationSchema,
        onSubmit,
        resetPassResponse,
        initialValues,
    };
}
