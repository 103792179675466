import { Paragraph } from "services/styled-components/typogoraphy";
import { MessageBox } from "styled/message-box";
import { useTranslation } from "react-i18next";
import { AuthLayout } from "../../auth-layout";
import { SignUpHelmet } from "components/helmet/sign-up-helmet";
import { useDefinedContext } from "hooks/use-context-exist";
import { PartnersProgramGeneralInformationContext } from "views";
import { ONJABET_PARTNERS } from "server/partner-programs";

interface IInjectedProps {}

interface IProps extends IInjectedProps {}

export const SignUpComplete = React.memo((props: IProps) => {
    const [__] = useTranslation();
    const { seoScriptsAndTags, partnersProgramId } = useDefinedContext(
        PartnersProgramGeneralInformationContext,
    );
    return (
        <AuthLayout>
            <SignUpHelmet
                script={seoScriptsAndTags.successfullRegistrationScript}
            />
            <MessageBox>
                <Paragraph align="center">
                    {__("Спасибо за регистрацию!")}
                </Paragraph>
                <Paragraph align="center">
                    {__(
                        "Ваша заявка принята и находится на рассмотрении модератора. В течение 48 часов, Вы получите по электронной почте письмо о статусе Вашей заявки",
                    )}
                </Paragraph>
                {partnersProgramId === ONJABET_PARTNERS && (
                    <Paragraph align="center">
                        {__(
                            "Электронные письма могут попасть в спам. Проверьте папку «Спам».",
                        )}
                    </Paragraph>
                )}
            </MessageBox>
        </AuthLayout>
    );
}) as React.ComponentType<Subtract<IProps, IInjectedProps>>;
