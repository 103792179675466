import { User } from "@styled-icons/boxicons-regular/User/User";
import { SignInAlt } from "@styled-icons/fa-solid/SignInAlt/SignInAlt";
import { UserCircle } from "@styled-icons/fa-solid/UserCircle/UserCircle";
import { LocalizedNavLinkWithOptionalBackground } from "components/routing/localized-navlink-with-optional-background";
import { useUser } from "hooks/query/use-user";
import { useAccountType } from "hooks/use-account-type";
import { useTranslation } from "react-i18next";
import { ServicesSC } from "./styled";
import { Messengers } from "../social-network";
import { usePartnersProgram } from "hooks/use-partners-program-id";
import {
    BETANDYOU_AFFILIATES,
    MEGAPARI_PARTNERS,
    _1XCASINO_PARTNERS,
} from "../../../../../server/partner-programs";
import { MessengersHeaderSC } from "../../styled";
import {
    IButtonGradientProps,
    SingUpButton,
} from "components/header/components/sing-up-button";

interface IInjectedProps {}

interface IProps extends IInjectedProps {
    onClick?:
        | ((event: React.MouseEvent<HTMLDivElement, MouseEvent>) => void)
        | undefined;
}

export const AuthLandingElements = React.memo((props: IProps) => {
    const [__] = useTranslation();
    const accountType = useAccountType();
    const { user } = useUser();
    const partnersProgramId = usePartnersProgram()?.programId;

    const buttonGradientPropsByPartner: Record<string, IButtonGradientProps> = {
        [MEGAPARI_PARTNERS]: {
            buttonGradient:
                "linear-gradient(150.29deg,rgb(27, 157, 217) -2.02%,rgb(230, 53, 43) 101.94%)",
        },
        [_1XCASINO_PARTNERS]: {
            withRadius: false,
            buttonGradient:
                "linear-gradient(275.76deg, #7158E2 44.33%, #CD84F1 98.56%)",
        },
    };

    const buttonGradientsProps =
        buttonGradientPropsByPartner[partnersProgramId as string];

    const localizedLinkProps =
        partnersProgramId === MEGAPARI_PARTNERS ? buttonGradientsProps : {};

    return (
        <ServicesSC
            onClick={props.onClick}
            hasSpecialButtons={Boolean(localizedLinkProps?.buttonGradient)}
        >
            {partnersProgramId === BETANDYOU_AFFILIATES && (
                <MessengersHeaderSC>
                    <Messengers inMobileMenu />
                </MessengersHeaderSC>
            )}
            {!user ? (
                <>
                    <SingUpButton
                        id="header_sign-up"
                        header={true}
                        {...buttonGradientsProps}
                    >
                        <UserCircle size={18} />
                        {__("Регистрация")}
                    </SingUpButton>
                    <LocalizedNavLinkWithOptionalBackground
                        to="/sign-in"
                        {...localizedLinkProps}
                    >
                        <SignInAlt size={18} /> {__("Вход")}
                    </LocalizedNavLinkWithOptionalBackground>
                </>
            ) : (
                <LocalizedNavLinkWithOptionalBackground
                    to={`/${accountType}`}
                    {...localizedLinkProps}
                >
                    <User size={18} /> {__("Личный кабинет")}
                </LocalizedNavLinkWithOptionalBackground>
            )}
        </ServicesSC>
    );
}) as React.ComponentType<Subtract<IProps, IInjectedProps>>;
