import { LifeRing } from "@styled-icons/fa-regular/LifeRing/LifeRing";
import { Smile } from "@styled-icons/fa-regular/Smile/Smile";
import { Handshake } from "@styled-icons/fa-regular/Handshake";
import { DollarSign } from "@styled-icons/fa-solid/DollarSign/DollarSign";
import { ThumbsUp } from "@styled-icons/fa-solid/ThumbsUp/ThumbsUp";
import { Wrench } from "@styled-icons/fa-solid/Wrench/Wrench";
import { GTranslate } from "@styled-icons/material/GTranslate/GTranslate";
import { StyledIcon } from "@styled-icons/styled-icon";
import { Support } from "@styled-icons/heroicons-solid/Support/Support";
import { Support as Support2 } from "@styled-icons/boxicons-regular/Support";
import { CoinStack } from "@styled-icons/boxicons-regular/CoinStack";
import { Conversation } from "@styled-icons/boxicons-regular/Conversation";
import { DesktopComputer } from "@styled-icons/heroicons-solid/DesktopComputer";
import { Language } from "@styled-icons/ionicons-solid/Language/Language";
import { Language as Language2 } from "@styled-icons/fa-solid/Language";
import { Books } from "@styled-icons/icomoon/Books/Books";
import { SupportAgent } from "@styled-icons/material/SupportAgent/SupportAgent";
import { Payments } from "@styled-icons/material/Payments/Payments";
import { Payments as OutlinedPayments } from "@styled-icons/material-outlined/Payments";
import { Payments as RoundedPayments } from "@styled-icons/material-rounded/Payments";
import { TwentyFourHours } from "@styled-icons/remix-line/TwentyFourHours";
import { PieChart2 } from "@styled-icons/remix-fill/PieChart2";
import { HandCoin } from "@styled-icons/remix-line/HandCoin";
import { Advertisement } from "@styled-icons/remix-line/Advertisement";
import { ChartBar } from "@styled-icons/heroicons-outline/ChartBar";
import { MoneyBillAlt } from "@styled-icons/fa-regular/MoneyBillAlt/MoneyBillAlt";
import { PercentOutline } from "@styled-icons/evaicons-outline/PercentOutline";
import { Percent } from "@styled-icons/bootstrap/Percent";
import { Tools } from "@styled-icons/bootstrap/Tools";
import { People } from "@styled-icons/bootstrap/People";
import { CardImage } from "@styled-icons/bootstrap/CardImage";
import { Wallet2 } from "@styled-icons/bootstrap/Wallet2";
import { News } from "@styled-icons/boxicons-regular/News";
import { BarChartFill } from "@styled-icons/bootstrap/BarChartFill";
import { PersonSupport } from "@styled-icons/fluentui-system-regular/PersonSupport";
import { DollarCircle } from "@styled-icons/boxicons-solid/DollarCircle";
import { BarChartAlt2 } from "@styled-icons/boxicons-solid/BarChartAlt2";
import { PeopleCommunity } from "@styled-icons/fluentui-system-regular/PeopleCommunity";
import { Infinity } from "@styled-icons/fa-solid/Infinity";
import { FastAcceleration } from "@styled-icons/fluentui-system-filled/FastAcceleration";
import { PremiumPerson } from "@styled-icons/fluentui-system-filled/PremiumPerson";
import { Money } from "@styled-icons/fluentui-system-regular/Money";
import { Database } from "@styled-icons/icomoon/Database";
import { Database as Database2 } from "@styled-icons/fa-solid/Database";
import { UserCircle } from "@styled-icons/fa-solid/UserCircle";
import { StatsChart } from "@styled-icons/ionicons-sharp/StatsChart";
import { Cash } from "@styled-icons/ionicons-sharp/Cash";
import { SupportAgent as SupportAgentTwotone } from "@styled-icons/material-twotone/SupportAgent";
import { CashStack } from "@styled-icons/bootstrap/CashStack";
import { GraphUp } from "@styled-icons/bootstrap/GraphUp";
import { LineChart } from "@styled-icons/boxicons-regular/LineChart";
import { PhotoAlbum } from "@styled-icons/boxicons-regular/PhotoAlbum";
import { Percent as Percent2 } from "@styled-icons/fa-solid/Percent";
import { PhotoVideo } from "@styled-icons/fa-solid/PhotoVideo";
import { ShareScreenStop } from "@styled-icons/fluentui-system-filled/ShareScreenStop";
import { PersonSupport as PersonSupport2 } from "@styled-icons/fluentui-system-filled/PersonSupport";
import { TrendingUp } from "@styled-icons/ionicons-outline/TrendingUp";
import { ChatHeart } from "@styled-icons/remix-fill/ChatHeart";
import { Support as Support3 } from "@styled-icons/material-rounded/Support";

export const IconsDict: {
    [index: string]: {
        [index: string]: StyledIcon;
    };
} = {
    "ionicons-outline": {
        TrendingUp,
    },

    "fluentui-system-filled": {
        FastAcceleration,
        PremiumPerson,
        PersonSupport: PersonSupport2,
        ShareScreenStop,
    },

    "fa-regular": {
        LifeRing,
        Smile,
        MoneyBillAlt,
        Handshake,
    },
    "fa-solid": {
        DollarSign,
        ThumbsUp,
        Wrench,
        Language2,
        Infinity,
        Database2,
        UserCircle,
        Percent: Percent2,
        PhotoVideo,
        PersonSupport: PersonSupport2,
    },
    "heroicons-solid": {
        Support,
        DesktopComputer,
    },
    "ionicons-solid": {
        Language,
    },
    icomoon: {
        Books,
        Database,
    },
    material: {
        GTranslate,
        SupportAgent,
        Payments,
    },
    bootstrap: {
        Percent,
        CardImage,
        Wallet2,
        BarChartFill,
        People,
        CashStack,
        Tools,
        ChatHeart,
        GraphUp,
    },
    "remix-line": {
        TwentyFourHours,
        HandCoin,
        Advertisement,
    },
    "remix-fill": {
        PieChart2,
        ChatHeart,
    },
    "heroicons-outline": {
        ChartBar,
    },
    "evaicons-outline": {
        PercentOutline,
    },
    "boxicons-regular": {
        News,
        Support2,
        CoinStack,
        Conversation,
        LineChart,
        PhotoAlbum,
    },
    "fluentui-system-regular": {
        PersonSupport,
        PeopleCommunity,
        Money,
    },
    "boxicons-solid": {
        DollarCircle,
        BarChartAlt2,
    },
    "ionicons-sharp": { StatsChart, Cash },
    "material-twotone": {
        SupportAgent: SupportAgentTwotone,
    },
    "material-outlined": { Payments: OutlinedPayments },
    "material-rounded": { Payments: RoundedPayments, Support: Support3 },
};
