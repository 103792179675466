import styled from "styled-components";
import { media } from "services/styled-components/variables";
import { Title } from "styled/layouts";
import { FeaturesSC as Features } from "../../features/styled";

export const FeaturesSC = styled(Features)`
    justify-content: center;
    gap: 20px;
    margin-top: 40px;
    color: ${props => props.theme.primaryColor};
    > * {
        width: calc((100% - 60px) / 3);
        max-width: 322px;
        ${media.smMax`
            width: calc((100% - 20px) / 2);
        `}
        ${media.xsMax`
            width: 100%;
        `}
    }
`;

export const FeatureTitle = styled(Title)`
    margin-top: 50px;
`;

export const FeatureAnnotation = styled.p`
    text-align: center;
    color: ${props => props.theme.mutedTextColor};
`;
