import { ContentLoader } from "components/components-common/content-loader";
import { List } from "react-content-loader";
import { useTermsAndConditions } from "hooks/query/use-terms-and-conditions";

interface IInjectedProps {}

interface IProps extends IInjectedProps {}

export const TermsAndConditions = React.memo((props: IProps) => {
    const { text, loading } = useTermsAndConditions();
    return (
        <ContentLoader
            component={List}
            times={10}
            loading={loading}
            text={text}
        >
            <div
                dangerouslySetInnerHTML={{
                    // eslint-disable-next-line @typescript-eslint/naming-convention
                    __html: text,
                }}
            />
        </ContentLoader>
    );
}) as React.ComponentType<Subtract<IProps, IInjectedProps>>;
