import { media } from "services/styled-components/variables";
import styled, { css } from "styled-components";
import {
    ColoredNavLink,
    StyledNavLink,
    DefaultColoredLinkButton,
} from "styled/link";
import { rtl } from "services/styled-components/rtl";

interface IHasSpecialButtons {
    hasSpecialButtons?: boolean;
}

export const ServicesSC = styled.div<IHasSpecialButtons>`
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    text-transform: uppercase;
    ${media.smMax`
        font-size: 0.75em;
    `}
    ${ColoredNavLink}, 
    ${DefaultColoredLinkButton} {
        display: inline-flex;
        align-items: center;
        padding: 10px;
        ${rtl`margin-right: 10px;`};
        font-size: 0;
        svg {
            vertical-align: middle;
            ${rtl`margin-right: 0.62em;`};
        }

        ${media.xs`        
            ${rtl`margin-right: 3em;`};
            font-size: 0.813em;
        `}

        ${props =>
            props.hasSpecialButtons &&
            css`
                padding: 10px 20px;
                font-weight: 700;
                font-size: 10px;
                line-height: 12px;

                ${media.xs`        
                    ${rtl`margin-right: 10px;`};
                    font-size: 10px;

                    &:last-child {
                        ${rtl`margin-right: 0;`};
                    }
                `}
            `}
    }
    ${StyledNavLink} {
        padding: 10px;
        color: ${props => props.theme.textColor};
        font-size: 0;
        ${props => css`
            ${media.xs`
                padding: 0.3125em 0;
                font-size: 0.813em;
                border-bottom: 1px dashed ${props.theme.textColor};
            `}
        `}
        svg {
            vertical-align: sub;
            ${rtl`margin-right: 0.62em;`};
        }
        &:hover {
            color: ${props => props.theme.activeColor};
            border-bottom: 1px dashed ${props => props.theme.activeColor};
            svg {
                fill: ${props => props.theme.activeColor};
            }
        }
    }
`;
