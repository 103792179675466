import Helmet from "react-helmet-with-visor";
import { Faq as FaqContent } from "components/components-common/faq";
import { WrapperFullWidth, Wrapper, InfoPageWrapper } from "styled/layouts";
import { useTranslation } from "react-i18next";
import { useDefinedContext } from "hooks/use-context-exist";
import { PartnersProgramGeneralInformationContext } from "views";

export const Faq = React.memo(() => {
    const [__] = useTranslation();
    const partnersGeneralInformation = useDefinedContext(
        PartnersProgramGeneralInformationContext,
    );

    return (
        <WrapperFullWidth>
            <Helmet>
                <title>
                    {format(
                        __(
                            "F.A.Q | {PartnerCompanyName} | Партнерская программа {CompanyName}",
                        ),
                        {
                            PartnerCompanyName:
                                partnersGeneralInformation.partnerProgramCompanyName,
                            CompanyName:
                                partnersGeneralInformation.agentSiteInformation
                                    .companyName,
                        },
                    )}
                </title>
                <meta
                    name="description"
                    content={format(
                        __(
                            "Ответы на все ваши вопросы о партнерской программе {PartnerCompanyName}.",
                        ),
                        {
                            PartnerCompanyName:
                                partnersGeneralInformation.partnerProgramCompanyName,
                        },
                    )}
                />
            </Helmet>
            <Wrapper>
                <InfoPageWrapper>
                    <FaqContent
                        questionTextColor={
                            partnersGeneralInformation.stylesTheme.primaryColor
                        }
                        answerTextColor={
                            partnersGeneralInformation.stylesTheme.primaryColor
                        }
                        tabTextColor={
                            partnersGeneralInformation.stylesTheme.textColor
                        }
                        tabBgColor={
                            partnersGeneralInformation.stylesTheme.accentColor
                        }
                        tabActiveBgColor={
                            partnersGeneralInformation.stylesTheme.activeColor
                        }
                    />
                </InfoPageWrapper>
            </Wrapper>
        </WrapperFullWidth>
    );
});
