import { cover, darken } from "polished";
import { media } from "services/styled-components/variables";
import styled from "styled-components";
import { rtl } from "services/styled-components/rtl";
import { BaseTitle } from "styled/layouts";

interface IProps {
    textColor: string;
    bgColor: string;
}

export const WelcomeTitle = styled.h1`
    ${BaseTitle};
    ${props => rtl`border-left: 0.111em solid ${props.theme.accentColor};`};
    padding: 0 1em;
    line-height: 1.2;
    margin-bottom: 25px;
`;

export const WelcomePanelSC = styled.div<IProps>`
    position: relative;
    top: 105px;
    max-width: 600px;
    display: inline-block;
    padding: 36px 0;
    color: ${props => props.textColor};
    background-color: ${props => props.bgColor};
    box-shadow: 1px 2px 7px rgba(0, 0, 0, 0.1);

    ${WelcomeTitle} {
        color: ${props => darken(0.1, props.textColor)};
    }

    ${media.mdMax`
        top: 0;
    `}

    ${media.smMax`
        left: 50%;
        transform: translateX(-50%);
    `}

    ${media.xsMax`
        top: 0;
        font-size: 12px;
    `}
`;

export const WelcomeList = styled.ul`
    padding: 0 2.5em;
    li {
        margin-bottom: 0.625em;
        &:last-child {
            margin-bottom: 0;
        }
    }
    li::before {
        content: "";
        width: 0;
        height: 0;
        border-style: solid;
        ${rtl`border-width: 8px 5px 0 5px;`};
        border-color: ${props => props.theme.accentColor} transparent
            transparent transparent;
        display: inline-block;
        ${rtl`margin-right: 0.5rem;`};
    }
`;

export const WelcomeBtn = styled.div`
    position: relative;
    bottom: 0;
    display: flex;
    flex-direction: column;
    height: auto;
    padding: 0 2.5em;
    margin-top: 36px;
    ${media.xs`
        bottom: -32px;
        flex-direction: row;
        justify-content: space-between;
        height: 20px;
        margin-top: 0;
    `}
    &:empty {
        bottom: 0;
        height: 0;
        margin-top: 0;
    }
    & div {
        & + div {
            margin-top: 10px;
            ${media.xs`
                margin-top: 0;
            `}
        }
        &:not(:last-of-type) {
            ${media.xs`
                ${rtl`margin-right: 15px;`};
            `}
        }
    }

    a {
        position: relative;
        display: block;
        backface-visibility: hidden;
        overflow: hidden;
        transform: translateZ(0);
        transition-duration: 0.3s;
        background-color: ${props =>
            props.theme.buttons.welcomeButton.background};
        border-width: 0;
        font-weight: 500;
        color: ${props => props.theme.textColor} !important;
        border-radius: 0;
        ${media.xsMax`
            width: 100%;
            font-size: 16px;
        `}
        svg {
            color: ${props =>
                props.theme.buttons.welcomeButton.iconColor} !important;
            transition: color 0.3s cubic-bezier(0.75, 0, 0.125, 1);
            ${rtl`margin-right: .625em;`};
        }
        &::after,
        &::before {
            content: "";
            position: absolute;
            ${cover()}
            transform: translate3d(0, -100%, 0);
            z-index: -1;
            transition: transform 0.3s cubic-bezier(0.75, 0, 0.125, 1);
        }
        &::before {
            background: ${props =>
                props.theme.buttons.welcomeButton.backgroundOnHover1};
            /* background: ${props => props.theme.activeColor}; as variant*/
        }
        &::after {
            background: ${props =>
                props.theme.buttons.welcomeButton.backgroundOnHover2};
            transition-delay: 0.175s;
        }
        &:hover {
            background-color: ${props =>
                props.theme.buttons.welcomeButton.background};
            svg {
                color: ${props => props.theme.textColor} !important;
            }
            &::after,
            &::before {
                transform: translate3d(0, 0, 0);
            }
        }
    }
`;
