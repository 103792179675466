import styled from "styled-components";
import { WrapperFullWidth, Wrapper } from "styled/layouts";

interface IPicture {
    bgColor?: string;
}

export const WrapperFullWidthPicture = styled(WrapperFullWidth)<IPicture>`
    position: relative;
    top: -24px;
    margin-top: -3.75em;
    margin-bottom: -24px;
    padding-top: 4.5em;
    padding-bottom: 80px;
    background-color: ${props => (props.bgColor ? props.bgColor : "#ffffff")};
`;

interface IWrapperGeography {
    textColor: string;
}

export const WrapperGeography = styled(Wrapper)<IWrapperGeography>`
    text-align: center;
    color: ${props => props.textColor};
    img {
        max-width: 100%;
        height: auto;
    }
`;
