import React from "react";
import Helmet from "react-helmet-with-visor";
import { useTranslation } from "react-i18next";
import { WrapperFullWidth, Wrapper, InfoPageWrapper } from "styled/layouts";
import { ContactsInformation } from "./contacts-information";
import { useDefinedContext } from "hooks/use-context-exist";
import { PartnersProgramGeneralInformationContext } from "views";
import { WINWIN_PARTNERS } from "server/partner-programs";

interface IInjectedProps {}

interface IProps extends IInjectedProps {}

export const Contacts = React.memo((props: IProps) => {
    const [__] = useTranslation();
    const {
        partnersProgramId,
        partnerProgramCompanyName,
        agentSiteInformation,
    } = useDefinedContext(PartnersProgramGeneralInformationContext);

    let title = format(
        __(
            "Контакты | {PartnerCompanyName} | Партнерская программа {CompanyName}",
        ),
        {
            PartnerCompanyName: partnerProgramCompanyName,
            CompanyName: agentSiteInformation.companyName,
        },
    );

    let description = format(
        __(
            "Наши Контакты. Связавшись с нами Вы получите поддержку по любым вопросам. Партнерская программа {PartnerCompanyName}.",
        ),
        {
            PartnerCompanyName: partnerProgramCompanyName,
        },
    );

    if (partnersProgramId === WINWIN_PARTNERS) {
        title = format(
            __("Контакты партнерской программы - {PartnerCompanyName}"),
            {
                PartnerCompanyName: partnerProgramCompanyName,
            },
        );
        description = format(
            __(
                "Свяжитесь с командой партнерской программы {PartnerCompanyName} для консультаций и сотрудничества. Мы всегда готовы помочь!",
            ),
            {
                PartnerCompanyName: partnerProgramCompanyName,
            },
        );
    }

    return (
        <WrapperFullWidth>
            <Helmet>
                <title>{title}</title>
                <meta name="description" content={description} />
            </Helmet>
            <Wrapper>
                <InfoPageWrapper>
                    <ContactsInformation />
                </InfoPageWrapper>
            </Wrapper>
        </WrapperFullWidth>
    );
}) as React.ComponentType<Subtract<IProps, IInjectedProps>>;
