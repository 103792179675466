import { useCountries } from "hooks/query/use-countries";
import { find } from "lodash";
import { SignUpValues } from "./use-initial-values";

export const useChangeCountryPhoneCode = (
    setFieldValue: (field: string, value: any) => void,
    values: SignUpValues,
) => {
    const { countries } = useCountries();
    const selectedCountry =
        values.country &&
        find(countries, {
            id: values.country.id,
        });

    if (
        selectedCountry &&
        !values.phone.startsWith(`+${selectedCountry.phoneCode}`)
    ) {
        setTimeout(() =>
            setFieldValue("phone", `+${selectedCountry.phoneCode}`),
        );
    }

    return selectedCountry;
};
