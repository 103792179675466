import styled, { css } from "styled-components";
import Arrow from "./assets/images/sbm-slider-black.png";
import { media } from "services/styled-components/variables";

export const TestimonialsSlider = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    max-width: 1170px;
    margin: 0 auto;
    outline: none;

    ${media.lgMax`
        max-width: 100%;
    `}
`;

interface IProps {
    activeColor: string;
    numberOfSlides: number;
}

export const SlickStyles = styled.div<IProps>`
    max-width: 1150px;
    width: 100%;
    padding: 0 0 50px;
    height: auto;

    .slick-slide {
        > div {
            display: flex;
            justify-content: center;
        }
    }

    .slick-arrow.slick-prev {
        ${props =>
            props.numberOfSlides === 1 &&
            css`
                right: 50%;
                margin-right: 220px !important;

                ${media.mdMax`
                    margin-right: 0 !important;
                    left: 0;
                `}

                ${media.ltMax`
                    display: none!important;
                `}
            `}
    }

    .slick-next,
    .slick-prev {
        top: 30%;
        height: 50px;
    }

    .slick-arrow:hover,
    .slick-arrow:active {
        opacity: 0.5;
    }

    .slick-arrow::before {
        content: " ";
        position: absolute;
        top: 50%;
        left: 50%;
        width: 17px;
        height: 31px;
        margin: 0 0 0 -9px;
        background: url(${Arrow}) no-repeat top;
        transform: translateY(-50%);
    }

    .slick-arrow.slick-next {
        right: 0;

        ${props =>
            props.numberOfSlides === 1 &&
            css`
                left: 50%;
                margin-left: 220px !important;

                ${media.mdMax`
                    margin-left: 0 !important;
                    left: auto;
                `}

                ${media.ltMax`
                    display: none!important;
                `}
            `}
    }

    .slick-arrow.slick-next::before {
        margin: 0 0 0 -7px;
        transform: rotate(180deg) translateY(50%);
    }

    /* Dots */

    .slick-dots {
        bottom: 0;

        ${media.mdMax`
            bottom: -25px;
        `}

        position: relative;
    }

    .slick-dots li button {
        border: solid 2px;
        background: transparent;
    }

    li.slick-active button {
        background: ${props => props.activeColor};
    }

    .slick-dots li {
        padding: 10px;
    }
`;

export const SlideWrapper = styled.div`
    padding: 5px 15px 15px;
    display: flex;
    align-items: center;
    flex-direction: column;
    outline: none;
`;

export const SlideAnchor = styled.a`
    padding: 15px;
    height: 200px;
    max-width: 400px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 auto 20px auto;
    outline: none;
    border: none;
    background: transparent;

    ${media.xsMax`
        width: 100%;
    `}
`;

export const SlideImage = styled.img`
    max-width: 100%;
    max-height: 100%;
`;

export const SlideText = styled.p`
    display: block;
    position: relative;
    padding: 0;
    max-width: 620px;
    margin: 0 auto;
    font-size: 16px;
    text-align: center;

    ${media.mdMax`
        max-width: 100%;
     `}
`;
