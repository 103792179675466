import { useTranslation } from "react-i18next";
import { Access } from "../../../../../components/check-roles/access";
import { Paragraph } from "../../../../../services/styled-components/typogoraphy";
import { useDefinedContext } from "../../../../../hooks/use-context-exist";
import { PartnersProgramGeneralInformationContext } from "../../../../../views";
import { AgreementLink } from "./styles";

interface IProps {
    onPrivacyClick: () => void;
    onRulesClick: () => void;
}

export function AgreementsMeta(props: IProps) {
    const { onPrivacyClick, onRulesClick } = props;

    const [__] = useTranslation();
    const partnersGeneralInformation = useDefinedContext(
        PartnersProgramGeneralInformationContext,
    );
    const [rulesPart1, rulesPart2, rulesPart3, rulesPart4] = format(
        __(
            "Использование веб - сайта {siteAddress} осуществляется в соответствии с{separator}правилами и условиями {PartnerProgramCompanyName}{separator}и{separator}политикой конфиденциальности {PartnerProgramCompanyName}.",
        ),
        {
            separator: "{separator}",
            PartnerProgramCompanyName:
                partnersGeneralInformation.partnerProgramCompanyName,
            siteAddress: partnersGeneralInformation.siteAddress,
        },
    ).split("{separator}");

    return (
        <Paragraph>
            {rulesPart1}{" "}
            <AgreementLink role="button" tabIndex={0} onClick={onRulesClick}>
                {" "}
                {rulesPart2}
            </AgreementLink>{" "}
            <Access name="privacy">
                <>
                    {rulesPart3}{" "}
                    <AgreementLink
                        role="button"
                        tabIndex={0}
                        onClick={onPrivacyClick}
                    >
                        {rulesPart4}
                    </AgreementLink>
                </>
            </Access>
        </Paragraph>
    );
}
