import { ReactNode } from "react";
import { DefaultTheme, StyledComponent } from "styled-components";
import { AuthContent, MainSign } from "styled/layouts";
import { SignBox } from "../styled";

interface IInjectedProps {}

interface IProps extends IInjectedProps {
    children: ReactNode;
    signBox?: StyledComponent<"div", DefaultTheme>;
}

export const AuthLayout = React.memo(({ signBox, children }: IProps) => {
    const SignBoxWrapper = signBox || SignBox;

    return (
        <AuthContent>
            <MainSign>
                <SignBoxWrapper>{children}</SignBoxWrapper>
            </MainSign>
        </AuthContent>
    );
}) as React.ComponentType<Subtract<IProps, IInjectedProps>>;
