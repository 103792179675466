import { useTranslation } from "react-i18next";
import { Field } from "components/formik";

export function LoginField() {
    const [__] = useTranslation();
    return (
        <Field
            name="login"
            type="text"
            label={`${__("Логин")} *`}
            aria-autocomplete="none"
            autoFocus={true}
        />
    );
}
