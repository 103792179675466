import React from "react";
import Slider, { Settings } from "react-slick";
import {
    SlickStyles,
    TestimonialsSlider,
    SlideWrapper,
    SlideAnchor,
    SlideImage,
    SlideText,
} from "./styled";
import { compact } from "lodash";
import { useTestimonials } from "hooks/use-testimonials";
import { ArrowIosForwardOutline } from "@styled-icons/evaicons-outline/ArrowIosForwardOutline";
import { ArrowIosBackOutline } from "@styled-icons/evaicons-outline/ArrowIosBackOutline";
import { Variables } from "services/variables";

interface IProps {
    activeColor: string;
    numberOfSlides: number;
    settings?: Settings;
}

export const Testimonials = React.memo((props: IProps) => {
    const { testimonials } = useTestimonials();

    if (testimonials.length === 0) {
        return null;
    }

    const settings: Settings = {
        dots: true,
        speed: 500,
        slidesToShow: props.numberOfSlides,
        slidesToScroll: 1,
        infinite: props.numberOfSlides < testimonials.length ? true : false,
        nextArrow: <ArrowIosForwardOutline size="18" />,
        prevArrow: <ArrowIosBackOutline size="18" />,
        responsive: [
            {
                breakpoint: 920,
                settings: {
                    slidesToShow: 1,
                    infinite: true,
                },
            },
        ],
        ...props.settings,
    };

    // Чтобы заменить стандартные срелочки в Carousel, передай ему renderArrowNext и renderArrowPrev.

    return (
        <TestimonialsSlider>
            <SlickStyles
                activeColor={props.activeColor}
                numberOfSlides={props.numberOfSlides}
            >
                <Slider {...settings}>
                    {compact(
                        testimonials.map((t, index) => {
                            if (!t) {
                                return null;
                            }
                            return (
                                <SlideWrapper key={index}>
                                    <SlideAnchor
                                        href={t.href || undefined}
                                        target="_blank"
                                        rel="nofollow"
                                    >
                                        <SlideImage
                                            src={`${Variables.fileStoragePath}/${t.image}`}
                                            alt={t.href || undefined}
                                        />
                                    </SlideAnchor>
                                    <SlideText>{t.description}</SlideText>
                                </SlideWrapper>
                            );
                        }),
                    )}
                </Slider>
            </SlickStyles>
        </TestimonialsSlider>
    );
});
