import { media } from "services/styled-components/variables";
import styled from "styled-components";
import { EmptyDiv } from ".";

export const OnlyMobile = styled(EmptyDiv)`
    ${media.md`
        display:none;
    `}
`;

export const OnlyDesktop = styled(EmptyDiv)`
    ${media.smMax`
        display:none;
    `}
`;
