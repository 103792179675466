import { ListItemsSC } from "./styled";

export const ListItems = () => (
    <ListItemsSC>
        {[
            {
                item: "1. Зарегистрируйтесь в нашей партнерской программе",
            },
            { item: "2. Заполните все поля" },
            {
                item: "3. Подтверждение регистрации придет на почту",
            },
        ].map((el, i) => (
            <li key={i}>{el.item}</li>
        ))}
    </ListItemsSC>
);
