import { useLogin } from "./login/use-login";
import { SignInStage, useStage } from "./stage/use-stage";
import { use2fa } from "./2fa/use-2fa-submit";
import { useEffect } from "react";
import urljoin from "url-join";
import { useApolloClient } from "@apollo/client";
import { useAccountType } from "../use-account-type";
import { useLanguage } from "../../components/language-provider";

export function useSignIn(partnerId?: number) {
    const { stage, setStage } = useStage();
    const login = useLogin(setStage, partnerId);
    const _2fa = use2fa(setStage, partnerId);
    const client = useApolloClient();
    const accountType = useAccountType();
    const {
        language: { urlPrefix },
    } = useLanguage();
    useEffect(() => {
        if (stage === SignInStage.FINISH && accountType) {
            const accountPath = urljoin("/", urlPrefix, accountType);
            document.location.assign(accountPath);
        }
    }, [stage, accountType, client, urlPrefix]);

    return {
        stageControl: { stage, setStage },
        login,
        _2fa,
    };
}

export type SignInType = ReturnType<typeof useSignIn>;
