import { useTranslation } from "react-i18next";
import { Field, Form, useFormikContext } from "components/formik";
import { SubmitButton } from "components/formik/submit-button/submit-button";
import React from "react";
import { Paragraph, Title } from "services/styled-components/typogoraphy";
import {
    SignUpContainer,
    SignUpSubmit,
    SignUpWrapper,
} from "../../../../../styled";
import { LoginField } from "../../../../components/fields/login";
import { PasswordField } from "../../../../components/fields/password";
import { ConfirmPasswordField } from "../../../../components/fields/confirm-password";
import { WebsiteField } from "../../../../components/fields/website";
import { FirstnameField } from "../../../../components/fields/firstname";
import { LastnameField } from "../../../../components/fields/lastname";
import { EmailField } from "../../../../components/fields/email";
import { CountryField } from "../../../../components/fields/country";
import { AgreementsMeta } from "../../../../components/agreements";
import { PrivacyDisclosure } from "../../../../components/privacy-disclosure";
import { Stage } from "hooks/sign-up/use-on-submit";
import { SignUpValues } from "hooks/sign-up/use-initial-values";
import { PartnersProgramGeneralInformationContext } from "views";
import { useDefinedContext } from "hooks/use-context-exist";
import { InfoMessage } from "components/info-message-registration-form";
import { useModalState } from "hooks/use-modal-state";
import { ModalSize } from "components/components-common/modal/modal-size";
import { MessengerField } from "components/filters/fields/messenger";
import { MessengerLoginField } from "components/filters/fields/messengerLoginField";
import { usePaymentSystemsForRegistration } from "hooks/sign-up/use-payment-systems-registration";

interface IProps {
    setStage: React.Dispatch<React.SetStateAction<Stage>>;
    onPrivacyClick: () => void;
    onRulesClick: () => void;
    color?: string;
    label?: string;
}

function FormWrapper(props: IProps) {
    const [__] = useTranslation();
    const [DT] = useTranslation();
    const { setFieldValue, values } = useFormikContext<SignUpValues>();
    const { getOptions, changePaymentSystemIfUkraine, validateField } =
        usePaymentSystemsForRegistration();
    const paymentSystemOptions = getOptions(values.country);
    const paymentSystemField = values.paymentSystem?.fields[0];
    const partnersGeneralInformation = useDefinedContext(
        PartnersProgramGeneralInformationContext,
    );
    const InfoMessageState = useModalState({ size: ModalSize.Medium });

    return (
        <Form>
            <SignUpContainer>
                <SignUpWrapper>
                    <Title>{__("Логин и пароль")}</Title>
                    <Paragraph>
                        <LoginField />
                    </Paragraph>
                    <Paragraph>
                        <PasswordField />
                        <ConfirmPasswordField />
                    </Paragraph>
                    <Title>{__("Дополнительная информация")}</Title>
                    <Paragraph>
                        <WebsiteField />
                    </Paragraph>
                    <Paragraph>
                        <CountryField
                            fieldOnChange={(e: React.ChangeEvent<any>) => {
                                const newSelectedCountry = e.target?.value;
                                void setFieldValue(
                                    "paymentSystem",
                                    changePaymentSystemIfUkraine(
                                        values.paymentSystem,
                                        newSelectedCountry,
                                    ),
                                );
                            }}
                            label={__("Страна основного трафика")}
                        />
                    </Paragraph>
                </SignUpWrapper>
                <SignUpWrapper>
                    <Title>{__("Контактная информация")}</Title>
                    <Paragraph>
                        <FirstnameField />
                        <LastnameField />
                    </Paragraph>
                    <Paragraph>
                        <EmailField />
                    </Paragraph>
                    <Paragraph>
                        <MessengerField />
                        <MessengerLoginField />
                    </Paragraph>
                    <Title>{__("Платежные данные")}</Title>
                    <Paragraph>
                        <Field
                            name="paymentSystem"
                            label={`${__("Предпочитаемый метод выплаты")} *`}
                            type="react-select"
                            options={paymentSystemOptions}
                            selectFirst
                            isLoading={!!paymentSystemOptions}
                            isSearchable
                            isClearable={false}
                        />
                        {paymentSystemField && (
                            <Field
                                name="paymentSystem.fields[0].value"
                                type="text"
                                label={`${DT(paymentSystemField.name)} *`}
                                validate={value =>
                                    validateField(paymentSystemField, value)
                                }
                            />
                        )}
                    </Paragraph>
                </SignUpWrapper>
            </SignUpContainer>
            <AgreementsMeta
                onPrivacyClick={props.onPrivacyClick}
                onRulesClick={props.onRulesClick}
            />
            <PrivacyDisclosure />
            <SignUpSubmit>
                <Paragraph>
                    <Field
                        name="rulesConfirmed"
                        type="checkbox"
                        label={__(
                            "Я ознакомился, понимаю и принимаю вышеизложенные условия и политики",
                        )}
                    />
                </Paragraph>
                <SubmitButton>{__("Зарегистрироваться")}</SubmitButton>
            </SignUpSubmit>
            <InfoMessage
                modalProps={InfoMessageState.modalProps}
                companyName={
                    partnersGeneralInformation.agentSiteInformation.companyName
                }
            />
        </Form>
    );
}

export { FormWrapper };
