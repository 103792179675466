import React from "react";
import { Wrapper, WrapperFullWidth } from "styled/layouts";
import { MailBlock } from "./styled";
import { Title } from "styled/layouts";
import { Envelope } from "@styled-icons/fa-solid/Envelope/Envelope";
import { useDefinedContext } from "hooks/use-context-exist";
import { PartnersProgramGeneralInformationContext } from "views";
import { AnimateWhenInViewport } from "components/components-common/animate";

export const StartWorking = React.memo(() => {
    const { email } = useDefinedContext(
        PartnersProgramGeneralInformationContext,
    );

    return (
        <WrapperFullWidth>
            <Wrapper>
                <Title>Начните работать с нами</Title>
                <AnimateWhenInViewport
                    animateType="fadeInUpShort"
                    animateDelay={250}
                >
                    <MailBlock
                        href={`mailto:${email}`}
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        <Envelope size={36} />
                        {email}
                    </MailBlock>
                </AnimateWhenInViewport>
            </Wrapper>
        </WrapperFullWidth>
    );
});
