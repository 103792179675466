import { useTranslation } from "react-i18next";
import { HowDidYouKnowField as _HowDidYouKnowField } from "components/filters/fields/how-did-you-know";

export function HowDidYouKnowField() {
    const [__] = useTranslation();
    return (
        <_HowDidYouKnowField
            name="howDidYouKnow"
            label={`${__("Как вы узнали о нас?")} *`}
            key="howDidYouKnow"
            selectFirst={true}
            isClearable={false}
        />
    );
}
