import { centerBlock, media } from "services/styled-components/variables";
import styled from "styled-components";
import { darken } from "polished";
import { Inboxes } from "@styled-icons/bootstrap/Inboxes/Inboxes";

export const NewsContainer = styled.div`
    display: flex;
    flex-wrap: wrap;
`;
export const CategoryTitle = styled.h2`
    color: ${props => darken(0.1, props.theme.landingTextColor)};
    text-align: center;
    padding: 45px 0 30px;
    font-size: 2em;
    text-transform: uppercase;
    font-weight: bold;
    span + span {
        color: ${props => props.theme.accentColor};
        ${media.smMax`
            display: block;
        `}
    }
`;
export const CategoryAnnotation = styled.p`
    text-align: center;
    color: ${props => props.theme.mutedTextColor};
    max-width: 450px;
    margin-bottom: 2em;
    ${centerBlock}
`;

export const NoDataContainer = styled.div`
    text-align: center;
`;
export const NoData = styled(Inboxes)`
    fill: ${props => props.theme.accentColor};
`;
