import { media } from "services/styled-components/variables";
import styled from "styled-components";

export const OurPartnersSC = styled.div`
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin-bottom: 80px;

    > * {
        margin-bottom: 20px;
        width: calc(100% / 2);
        ${media.lt`
        width: calc(100% / 4);
    `}
        ${media.lg`
        width: calc(100% / 5);
    `}
    }
`;

export const OurPartnersTitle = styled.h2`
    text-align: center;
    padding: 0 0 30px;
    font-size: 2em;
    text-transform: uppercase;
    font-weight: bold;
    color: ${props => props.theme.accentColor};
`;
