import {
    ResetPassword,
    ResetPasswordVariables,
} from "gql/types/operation-result-types";
import { Dispatch, SetStateAction } from "react";
import { MutationFunction } from "@apollo/client";
import { errorHandler } from "services/error-handler";
import { ErrorType } from "../../../server/errors/error-type";
import { toast } from "react-toastify";

const resetPassword = async (
    mutate: MutationFunction<ResetPassword, ResetPasswordVariables>,
    state: { login: string; email: string; throughEmail?: boolean },
    setMessage: Dispatch<SetStateAction<any>>,
    translations: {
        messageSent?: () => string;
        messageNoSent: () => string;
        failed: () => string;
    },
) => {
    try {
        const fetchResult = await mutate({
            variables: {
                filter: state,
            },
        });
        if (fetchResult && fetchResult.data) {
            if (translations.messageSent) {
                setMessage({
                    error: false,
                    message: translations.messageSent,
                });

                toast.success(translations.messageSent, {
                    autoClose: 15000,
                });
            }

            return true;
        }
    } catch (e: any) {
        void errorHandler(e, error => {
            switch (error.data.code) {
                case ErrorType.PASSWORD_RESET_MESSAGE_NO_SEND:
                    toast.error(translations.messageNoSent, {
                        autoClose: 15000,
                    });
                    break;
                case ErrorType.PASSWORD_RESET_ERROR:
                    toast.error(translations.failed, {
                        autoClose: 15000,
                    });
                    break;
                default:
                    toast.error(translations.failed, {
                        autoClose: 15000,
                    });
                    break;
            }
        });
    }
};

export const resetPasswordMutation = () => resetPassword;
