import { media } from "services/styled-components/variables";
import styled, { keyframes } from "styled-components";
import { StyledLink } from "styled/link";

export const NewsItemSC = styled.div`
    padding: 0 0.7rem 2rem;
    width: 100%;
    ${media.xs`
        width: 50%;
    `}
    ${media.sm`
        width: 33.3333%;
    `}
`;

export const NewsLink = styled(StyledLink)`
    display: block;
    color: ${props => props.theme.backgroundColor};
    &:hover {
        color: ${props => props.theme.backgroundColor};
    }
`;

export const NewsHeader = styled.div`
    display: block;
    margin-bottom: 1.25em;
`;

export const NewsImgCon = styled.div`
    position: relative;
    &::before {
        transition: opacity 0.25s ease-in-out;
        opacity: 0;
        position: absolute;
        top: 0;
        left: 0;
        z-index: 1;
        width: 100%;
        height: 100%;
        content: "";
        background: rgba(60, 60, 60, 0.33);
    }
    > div {
        transition: opacity 0.25s ease-in-out;
        opacity: 0;
        position: absolute;
        top: 50%;
        left: 50%;
        width: 15%;
        height: 1px;
        z-index: 2;
        transform: translate(-50%, -50%);
        &::after,
        &::before {
            content: "";
            position: absolute;
            height: 1px;
            width: 100%;
            background: #fff;
            top: 50%;
            left: 0;
            margin-top: -0.5px;
        }
        &::before {
            transform: rotate(90deg);
        }
    }
    > img {
        display: block;
        width: 100%;
        height: auto;
    }
    &:hover {
        &:before,
        > div {
            opacity: 1;
        }
    }
`;

export const NewsContent = styled.div`
    display: block;
    margin-bottom: 1.25em;
`;

export const NewsDataTime = styled.div`
    font-size: 0.875em;
    color: ${props => props.theme.accentColor};
    line-height: 1.2;
`;

export const NewsTitle = styled.div`
    font-weight: 700;
    font-size: 1em;
    margin: 1.25em 0;
`;

export const NewsExcerpt = styled.div`
    font-size: 0.9375em;
    margin: 1em 0;
`;

const anim = keyframes`
0%, 100% {
    transform: translateX(0);
}

33% {
    transform: translateX(2px);
}
66% {
    transform: translateX(-2px);
}
`;

export const NewsLinkMore = styled.div`
    font-size: 0.8125em;
    text-transform: uppercase;
    text-decoration: none;
    color: ${props => props.theme.accentColor};
    line-height: 1.2;
    > svg {
        margin-left: 0.3125em;
    }
    &:hover {
        > svg {
            animation: ${anim} 1s infinite linear;
        }
    }
`;
