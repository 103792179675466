import { ReactChild } from "react";
import {
    AttentionCube,
    AttentionItem,
    AttentionItemType,
    AttentionSC,
} from "./styled";
import { BETANDYOU_AFFILIATES } from "../../../server/partner-programs";
import { PartnersProgramGeneralInformationContext } from "views";
import { useDefinedContext } from "hooks/use-context-exist";

interface IInjectedProps {}

interface IProps extends IInjectedProps {
    children: ReactChild;
}

export const Attention = React.memo((props: IProps) => {
    const partnersGeneralInformation = useDefinedContext(
        PartnersProgramGeneralInformationContext,
    );
    return (
        <AttentionSC>
            {partnersGeneralInformation.partnersProgramId !==
                BETANDYOU_AFFILIATES && (
                <AttentionItem type={AttentionItemType.Left}>
                    <AttentionCube />
                </AttentionItem>
            )}
            {props.children}
            {partnersGeneralInformation.partnersProgramId !==
                BETANDYOU_AFFILIATES && (
                <AttentionItem type={AttentionItemType.Right}>
                    <AttentionCube />
                </AttentionItem>
            )}
        </AttentionSC>
    );
}) as React.ComponentType<Subtract<IProps, IInjectedProps>>;
