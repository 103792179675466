import { useTranslation } from "react-i18next";
import { useChangeCountryPhoneCode } from "hooks/sign-up/use-change-country-phone-code";
import { Field, Form, useFormikContext } from "components/formik";
import { SubmitButton } from "components/formik/submit-button/submit-button";
import { UniversalField } from "components/formik/universal-field";
import React from "react";
import { Paragraph, Title } from "services/styled-components/typogoraphy";
import {
    SignUpContainer,
    SignUpSubmit,
    SignUpWrapper,
} from "../../../../../styled";
import { LoginField } from "../../../../components/fields/login";
import { PasswordField } from "../../../../components/fields/password";
import { ConfirmPasswordField } from "../../../../components/fields/confirm-password";
import { LanguageField } from "../../../../components/fields/language";
import { FirstnameField } from "../../../../components/fields/firstname";
import { LastnameField } from "../../../../components/fields/lastname";
import { EmailField } from "../../../../components/fields/email";
import { CountryField } from "../../../../components/fields/country";
import { PhoneField } from "../../../../components/fields/phone";
import { AgreementsMeta } from "../../../../components/agreements";
import { PrivacyDisclosure } from "../../../../components/privacy-disclosure";
import { Stage } from "hooks/sign-up/use-on-submit";
import { SignUpValues } from "hooks/sign-up/use-initial-values";
import { ICountry } from "hooks/query/query-with-translations/use-countries-with-translations";
import { MessengerField } from "components/filters/fields/messenger";
import { MessengerLoginField } from "components/filters/fields/messengerLoginField";
import { usePaymentSystemsForRegistration } from "hooks/sign-up/use-payment-systems-registration";

interface IProps {
    setStage: React.Dispatch<React.SetStateAction<Stage>>;
    onPrivacyClick: () => void;
    onRulesClick: () => void;
}

function FormWrapper(props: IProps) {
    const [__] = useTranslation();
    const [DT] = useTranslation();
    const { setFieldValue, values } = useFormikContext<SignUpValues>();
    const selectedCountry = useChangeCountryPhoneCode(setFieldValue, values);
    const { getOptions, changePaymentSystemIfUkraine, validateField } =
        usePaymentSystemsForRegistration();
    const paymentSystemOptions = getOptions(values.country);
    const paymentSystemField = values.paymentSystem?.fields[0];
    return (
        <Form>
            <SignUpContainer>
                <SignUpWrapper>
                    <Title>{__("Логин и пароль")}</Title>
                    <Paragraph>
                        <LoginField />
                    </Paragraph>
                    <Paragraph>
                        <PasswordField />
                        <ConfirmPasswordField />
                    </Paragraph>
                    <Title>{__("Контактная информация")}</Title>
                    <Paragraph>
                        <FirstnameField />
                        <LastnameField />
                    </Paragraph>
                    <Paragraph>
                        <EmailField />
                        <div></div>
                    </Paragraph>
                    <Paragraph>
                        <MessengerField />
                        <MessengerLoginField />
                    </Paragraph>
                    <Paragraph>
                        <PhoneField
                            countryCode={
                                selectedCountry && selectedCountry.code
                            }
                        />
                        <CountryField
                            fieldOnChange={(e: React.ChangeEvent<ICountry>) => {
                                void setFieldValue(
                                    "paymentSystem",
                                    changePaymentSystemIfUkraine(
                                        values.paymentSystem,
                                        e.target.value,
                                    ),
                                );
                            }}
                        />
                    </Paragraph>
                    <Title>{__("Дополнительная информация")}</Title>
                    <Paragraph>
                        <LanguageField />
                        <div></div>
                    </Paragraph>
                    <Title>{__("Платежные данные")}</Title>
                    <Paragraph>
                        <Field
                            name="paymentSystem"
                            label={`${__("Предпочитаемый метод выплаты")} *`}
                            type="react-select"
                            options={paymentSystemOptions}
                            selectFirst
                            isLoading={!!paymentSystemOptions}
                            isSearchable
                            isClearable={false}
                        />
                        {paymentSystemField ? (
                            <Field
                                name="paymentSystem.fields[0].value"
                                type="text"
                                label={`${DT(paymentSystemField.name)} *`}
                                validate={value =>
                                    validateField(paymentSystemField, value)
                                }
                            />
                        ) : (
                            <div></div>
                        )}
                    </Paragraph>
                </SignUpWrapper>
            </SignUpContainer>
            <AgreementsMeta
                onPrivacyClick={props.onPrivacyClick}
                onRulesClick={props.onRulesClick}
            />
            <PrivacyDisclosure />
            <SignUpSubmit>
                <Paragraph>
                    <Field
                        name="rulesConfirmed"
                        type="checkbox"
                        label={__(
                            "Я ознакомился, понимаю и принимаю вышеизложенные условия и политики",
                        )}
                        component={UniversalField}
                    />
                </Paragraph>
                <SubmitButton>{__("Зарегистрироваться")}</SubmitButton>
            </SignUpSubmit>
        </Form>
    );
}

export { FormWrapper };
