import styled from "styled-components";

interface IProps {
    textColor: string;
}

export const TestimonialsWrapper = styled.div<IProps>`
    color: ${props => props.textColor};
    button,
    h2 {
        color: ${props => props.textColor};
    }
`;
