import { GetPartnersProgramGeneralInfo_partnersProgram_generalInformation_landingMainPage_capabilities } from "gql/types/operation-result-types";
import { Wrapper, WrapperFullWidth } from "styled/layouts";
import { Capability } from "./capability";
import { CapabilitiesSC } from "./styled";

interface IProps {
    textColor: string;
    capabilities: GetPartnersProgramGeneralInfo_partnersProgram_generalInformation_landingMainPage_capabilities[];
}

export const Capabilities = React.memo((props: IProps) => {
    return (
        <WrapperFullWidth>
            <Wrapper>
                <CapabilitiesSC textColor={props.textColor}>
                    {props.capabilities.map((item, index) => {
                        return (
                            <Capability
                                key={index}
                                capabilityNumber={item.capabilityNumber}
                                capabilityTitle={item.capabilityTitle}
                                textColor={props.textColor}
                            />
                        );
                    })}
                </CapabilitiesSC>
            </Wrapper>
        </WrapperFullWidth>
    );
}) as React.ComponentType<IProps>;
