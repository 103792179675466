/* eslint-disable no-irregular-whitespace */
import styled from "styled-components";
import { keyframes } from "styled-components";
import { LocalizedNavLink } from "components/language-provider";
import { darken } from "polished";

interface IWithTextColor {
    textColor?: string;
}

export const OneNewsSC = styled(LocalizedNavLink)`
    display: block;
    img {
        width: 100%;
        height: auto;
    }
`;
export const OneNewsTime = styled.time`
    color: ${props => props.theme.accentColor};
`;

export const OneNewsTitle = styled.div<IWithTextColor>`
    color: ${props =>
        props.textColor || darken(0.1, props.theme.landingTextColor)};
    font-weight: 700;
    margin-top: 20px;
    margin-bottom: 20px;
`;

export const OneNewsIntroduction = styled.p<IWithTextColor>`
    color: ${props => props.textColor || props.theme.landingTextColor};
    font-size: 0.9375em;
    margin: 1em 0;
    line-height: 1.43;
`;

const anim = keyframes`
    0%, 100% {
        transform: translateX(0);
    }
    33% {
        transform: translateX(2px);
    }
    66% {
        transform: translateX(-2px);
    }
`;
export const OneNewsMore = styled.div`
    text-transform: uppercase;
    font-size: 0.813em;
    color: ${props => props.theme.accentColor} !important;
    &::after {
        content: " »";
        display: inline-block;
        font-size: 1.231em;
    }
    &:hover {
        &::after {
            animation: ${anim} 1s infinite linear;
        }
    }
`;
