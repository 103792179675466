import { internalPages } from "services/styled-components/typogoraphy";
import { media } from "services/styled-components/variables";
import styled from "styled-components";
import { darken } from "polished";

export const OneNewsSC = styled.div`
    margin-bottom: 20px;
    & img {
        max-width: 100%;
        height: auto;
    }
    ${media.sm`
        display: flex;
    `}
`;

export const NewsContent = styled.section`
    flex: 1 1 65%;
    overflow: hidden;
`;

export const MainImg = styled.img`
    width: 100%;
    max-width: 100%;
    height: auto;
    margin-bottom: 1.25em;
`;

export const NewsDataTime = styled.div`
    font-size: 0.875em;
    color: ${props => props.theme.accentColor};
    line-height: 1.2;
`;

export const NewsText = styled.div`
    ${internalPages}
    color: ${props => props.theme.landingTextColor};
    & h1 {
        color: ${props => darken(0.1, props.theme.landingTextColor)};
    }
`;
