import { centerBlock, media } from "services/styled-components/variables";
import styled from "styled-components";

interface IProps {
    textColor: string;
}

export const CapabilitiesSC = styled.div<IProps>`
    position: relative;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: stretch;
    ${centerBlock};
    margin-bottom: 80px;
    color: ${props => props.textColor};
    ${media.mdMax`
        flex-wrap: wrap;
    `}
`;
