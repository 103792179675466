import * as yup from "yup";
import { useState } from "react";
import { useTranslationList } from "hooks/use-translations-list";

export function useValidationSchema() {
    const { FIELD_SHOULD_BE_FILLED, CONTAINS_INVALID_CHARACTERS } =
        useTranslationList();
    const [validationSchema] = useState(() =>
        yup.object().shape({
            login: yup
                .string()
                .required(FIELD_SHOULD_BE_FILLED)
                .matches(/^[a-zA-Z0-9@._-]+$/, CONTAINS_INVALID_CHARACTERS),
            password: yup.string().required(FIELD_SHOULD_BE_FILLED),
        }),
    );

    return validationSchema;
}
