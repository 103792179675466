import { useTranslation } from "react-i18next";
import { Field } from "components/formik";
import { CountryFlagField } from "components/country-flag-field";

interface IProps {
    countryCode: string | undefined | null;
}

export function PhoneField(props: IProps) {
    const [__] = useTranslation();
    const { countryCode } = props;

    return (
        <Field
            name="phone"
            type="text"
            label={`${__("Номер телефона")} *`}
            prefixComponent={<CountryFlagField country={countryCode} />}
        />
    );
}
