import { media } from "services/styled-components/variables";
import styled, { css } from "styled-components";
import { BaseTitle, Wrapper, WrapperFullWidth } from "styled/layouts";
import { rtl } from "services/styled-components/rtl";

export const WrapperFullWidthPartner = styled(WrapperFullWidth)`
    position: relative;
    background-position: center bottom;
    background-repeat: no-repeat;
    background-size: cover;
    background-image: url("/config-files/section_bg");

    &::before {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: ${props => props.theme.frontBackgroundColor};
        opacity: 0;
    }

    & + * {
        margin-top: 60px;
    }

    ${Wrapper} {
        position: relative;
        ${rtl`padding: 30px 10px 60px;`};
        > a {
            position: relative;
        }
        img {
            position: absolute;
            bottom: 0;
            ${rtl`right: 0;`};
            ${props =>
                props.theme.dir === "rtl"
                    ? css`
                          transform: scaleX(-1);
                      `
                    : css`
                          transform: scale(1);
                      `}
            max-width: 285px;
            height: auto;
            ${media.md`
                max-width: 356px;
            `}
            ${media.smMax`
                display: none;
            `}
        }
        ${media.smMax`
            text-align: center;
            img{
                display: none;
            }
        `}
    }
`;
export const PartnerAmount = styled.div<{ colorPartner: string }>`
    position: relative;
    font-style: italic;
    margin-top: 20px;
    margin-bottom: 10px;
    padding-top: 25px;
    padding-bottom: 25px;
    color: ${props => props.colorPartner};
    span {
        font-style: normal;
        ${rtl`margin: 0 10px 0 0;`};
        font-size: 3em;
    }
`;
export const PartnerTitle = styled.h2`
    ${BaseTitle};
    position: relative;
    padding-top: 25px;
    padding-bottom: 25px;
    color: ${props => props.theme.textColor};

    ${media.smMinmdMax`
        max-Width: 650px;
    `};

    span + span {
        color: ${props => props.theme.accentColor};
        ${media.smMax`
            display: block;
        `};
    }
`;
