import styled from "styled-components";
import { rtl } from "services/styled-components/rtl";
export const MessengersSC = styled.ul`
    position: relative;
    display: inline-block;
    vertical-align: middle;
    li {
        display: inline-block;
        vertical-align: middle;
        a {
            display: inline-block;
            color: ${props => props.theme.textColor};
            line-height: 0;
            transition: 0.3s ease;
            svg {
                ${rtl`margin-right: 0.62em;`};
                path {
                    transition: 0.3s ease;
                }
            }
            &:hover {
                color: ${props => props.theme.activeColor};
                svg path {
                    fill: ${props => props.theme.activeColor};
                }
            }
        }
        &:last-of-type a {
            ${rtl`border-right: 0 none;`};
        }
    }
`;

export const Sep = styled.li`
    margin: 0 20px;
    ${props => rtl`border-left: 1px solid ${props.theme.textColor};`}
    height: 16px;
`;
