import { useDefinedContext } from "hooks/use-context-exist";
import { PartnersProgramGeneralInformationContext } from "views";
import { CompanyNews } from "../company-news";
import { Features } from "./features";
import { FirstScreen } from "../first-screen";
import { WelcomePanel } from "../first-screen/welcome-panel";
import { BecomeAnAffilate } from "../become-an-affilate";
import { OurPartners } from "../our-partners";
import { Partner } from "./partner";
import { BecomeAnAffiliateTitle } from "../become-an-affilate/become-an-affilate-title";
import { useTranslation } from "react-i18next";
import { darken } from "polished";
import { StartWorking } from "./start-working";
import { TestimonialsSlider } from "components/components-common/slider";
import { Title } from "styled/layouts";

interface IInjectedProps {}

interface IProps extends IInjectedProps {}

export const Home = React.memo((props: IProps) => {
    const partnersGeneralInformation = useDefinedContext(
        PartnersProgramGeneralInformationContext,
    );
    const [__] = useTranslation();

    const landingMainPage = partnersGeneralInformation.landingMainPage;
    // этот раздел относиться только к регулярному лендингу,
    // без этой инфы нет смысла продолжать
    if (!landingMainPage) {
        return null;
    }

    const [firstPartTitle, secondPartTitle] = __(
        "География {separator}наших партнеров",
    ).split("{separator}");

    return (
        <>
            <FirstScreen>
                <WelcomePanel
                    textColor={
                        partnersGeneralInformation.stylesTheme.landingTextColor
                    }
                    bgColor={partnersGeneralInformation.stylesTheme.textColor}
                />
            </FirstScreen>
            <Features termsIcons={landingMainPage.termsIcons} />
            <BecomeAnAffilate
                title={
                    <BecomeAnAffiliateTitle
                        firstPartTitle={firstPartTitle}
                        secondPartTitle={secondPartTitle}
                        annotation={
                            "Мы сотрудничаем на самых выгодных условиях"
                        }
                    />
                }
                picture={<img src={"/config-files/map"} alt="" />}
                textColor={
                    partnersGeneralInformation.stylesTheme.mutedTextColor
                }
            />
            {partnersGeneralInformation.visibility.showingNews.showNews ? (
                <CompanyNews />
            ) : null}
            <Partner
                importantPartnerTitle={landingMainPage.importantPartnerTitle}
                currencyName={landingMainPage.currencyName}
            />
            <StartWorking />
            {landingMainPage.partnersLogos.length !== 0 && (
                <OurPartners partnersLogos={landingMainPage.partnersLogos} />
            )}
            <TestimonialsSlider
                textColor={darken(
                    0.1,
                    partnersGeneralInformation.stylesTheme.landingTextColor,
                )}
                componentTitle={<Title>{__("Отзывы")}</Title>}
                activeColor={partnersGeneralInformation.stylesTheme.accentColor}
                numberOfSlides={1}
            />
        </>
    );
}) as React.ComponentType<Subtract<IProps, IInjectedProps>>;
