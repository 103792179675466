import { useDefinedContext } from "hooks/use-context-exist";
import { PartnersProgramGeneralInformationContext } from "views";
import { Capabilities } from "../capabilities";
import { Features } from "../features";
import { FirstScreen } from "../first-screen";
import { WelcomePanel } from "../first-screen/welcome-panel";
import { BecomeAnAffilate } from "../become-an-affilate";
import { Partner } from "../partner";
import { BecomeAnAffiliateTitle } from "../become-an-affilate/become-an-affilate-title";
import { useTranslation } from "react-i18next";
import { ContentBg } from "./styled";
import { TestimonialsSlider } from "components/components-common/slider";
import { CompanyNews } from "../company-news";
import { Title } from "styled/layouts";

interface IProps {}

export const Home = React.memo((props: IProps) => {
    const partnersGeneralInformation = useDefinedContext(
        PartnersProgramGeneralInformationContext,
    );
    const [__] = useTranslation();
    const [firstPartTitle, secondPartTitle] = __(
        "География {separator}наших партнеров",
    ).split("{separator}");
    const landing = partnersGeneralInformation.landingMainPage;
    // этот раздел относиться только к регулярному лендингу,
    // без этой инфы нет смысла продолжать
    if (!landing) {
        return null;
    }
    const accentColor = partnersGeneralInformation.stylesTheme.accentColor;
    const textColor = partnersGeneralInformation.stylesTheme.textColor;

    const buttonGradientProps = {
        buttonGradient:
            "linear-gradient(275.76deg, #7158E2 44.33%, #CD84F1 98.56%)",
        withRadius: false,
    };

    return (
        <ContentBg>
            <FirstScreen>
                <>
                    <WelcomePanel
                        textColor={textColor}
                        bgColor={"transparant"}
                        buttonGradientProps={buttonGradientProps}
                    />
                </>
            </FirstScreen>
            <Features
                termsIcons={landing.termsIcons}
                bgColor="#1E2226"
                color={textColor}
            />
            <Capabilities
                capabilities={landing.capabilities}
                textColor={textColor}
            />
            <BecomeAnAffilate
                bgColor="#14181C"
                title={
                    <BecomeAnAffiliateTitle
                        firstPartTitle={firstPartTitle}
                        secondPartTitle={secondPartTitle}
                        annotation={__(
                            "Мы сотрудничаем на самых выгодных условиях и осуществляем максимально широкий охват аудитории",
                        )}
                        color={textColor}
                    />
                }
                picture={<img src={"/config-files/map"} alt="" />}
                textColor={textColor}
                buttonGradientProps={buttonGradientProps}
            />
            <Partner
                importantPartnerTitle={landing.importantPartnerTitle}
                currencyName={landing.currencyName}
                colorPartner={accentColor}
                buttonGradientProps={buttonGradientProps}
            />
            <CompanyNews
                textColor={textColor}
                buttonGradientProps={buttonGradientProps}
            />
            <TestimonialsSlider
                textColor={textColor}
                componentTitle={<Title>{__("Отзывы")}</Title>}
                activeColor={accentColor}
                numberOfSlides={1}
            />
        </ContentBg>
    );
}) as React.ComponentType<IProps>;
