import { MessengersBlockContainer } from "../styled";
import { useDefinedContext } from "hooks/use-context-exist";
import { PartnersProgramGeneralInformationContext } from "views";

export const MessengersBlock = () => {
    const skypeIcon = "/config-files/skype";
    const telegramIcon = "/config-files/telegram";
    const emailIcon = "/config-files/email";
    const { email, telegramm, skype } = useDefinedContext(
        PartnersProgramGeneralInformationContext,
    );

    return (
        <MessengersBlockContainer>
            {telegramm && (
                <a href={telegramm} target="_blank" rel="noopener noreferrer">
                    <img src={telegramIcon} />
                </a>
            )}
            {skype && (
                <a href={skype} target="_blank" rel="noopener noreferrer">
                    <img src={skypeIcon} />
                </a>
            )}
            <a
                href={`mailto:${email}`}
                target="_blank"
                rel="noopener noreferrer"
            >
                <img src={emailIcon} />
            </a>
        </MessengersBlockContainer>
    );
};
