import { media, pseudoElem } from "services/styled-components/variables";
import styled from "styled-components";
import { rtl } from "services/styled-components/rtl";

interface IProps {
    textColor: string;
}

export const CapabilityWrapper = styled.div<IProps>`
    margin-top: 20px;
    margin-bottom: 20px;
    position: relative;
    /* font-size: 0.9rem; */
    text-align: center;
    z-index: 1;
    width: 100%;
    ${props => media.xs`
        flex: 1 1 50%;
        &:not(:last-child)::after {
            ${pseudoElem}
            position: absolute;
            bottom: 0.1875em;           
            ${rtl`
                right: 0;
                transform: skew(-20deg);
            `};          
            width: 1.5px;
            height: 4.5625em;
            background-color: ${props.textColor};
        }
        &:nth-child(2n)::after{
            display: none;
        }
    `}
    ${media.md`
        flex: 1 1 auto;
        width:auto;
        &:nth-child(2n)::after{
            display: block;
        }
    `}
`;
/* width: 100%;
${media.sm`
    width: calc((100% - 2rem) / 2);
`}
${media.md`
    width: calc((100% - 2rem) / 4);
`} */

export const CapabilityNumber = styled.h3`
    color: ${props => props.theme.accentColor};
    line-height: 0.8;
    margin-bottom: 15px;
    font-size: 3em;
    font-weight: bold;
    text-transform: uppercase;
    padding: 0;
`;

export const CapabilityDescription = styled.div`
    padding: 0 15px;
`;
