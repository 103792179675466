import { LocalizedNavLinkWithOptionalBackground } from "components/routing/localized-navlink-with-optional-background";
import React from "react";
import { ReactNode } from "react";
import { DefaultColoredLinkButton } from "../../../../styled/link";
import { useLanguage } from "@webx-react/i18n";
import { _22BET_PARTNERS } from "server/partner-programs";
import { useDefinedContext } from "../../../../hooks/use-context-exist";
import { PartnersProgramGeneralInformationContext } from "../../../../views";

export interface IButtonGradientProps {
    buttonGradient?: string;
    withRadius?: boolean;
}

interface IProps extends IButtonGradientProps {
    // для SEO аналитики, чтобы они могли повесить обрабтчики на кнопки
    id?: string;
    header?: boolean;
    children: ReactNode;
}

export const SingUpButton = React.memo((props: IProps) => {
    const { partnersProgramId, seoDomain } = useDefinedContext(
        PartnersProgramGeneralInformationContext,
    );
    const generalProps = {
        id: props.id,
        buttonGradient: props.buttonGradient,
        withRadius:
            typeof props.withRadius !== undefined
                ? props.withRadius
                : Boolean(props.buttonGradient),
    };
    const { language } = useLanguage();
    let registerLink;
    switch (language.code) {
        case "zh-CN":
            registerLink = `https://registration.${seoDomain}/zh/`;
            break;
        case "fr-FR":
            registerLink = `https://registration.${seoDomain}/fr/`;
            break;
        default:
            registerLink = `https://registration.${seoDomain}/`;
            break;
    }

    return partnersProgramId !== _22BET_PARTNERS ? (
        <LocalizedNavLinkWithOptionalBackground
            showBackground={true}
            to="/sign-up"
            {...generalProps}
        >
            {props.children}
        </LocalizedNavLinkWithOptionalBackground>
    ) : (
        <DefaultColoredLinkButton href={registerLink} {...generalProps}>
            {props.children}
        </DefaultColoredLinkButton>
    );
});
