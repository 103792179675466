import { AnimateWhenInViewport } from "components/components-common/animate";
import { GetPartnersProgramGeneralInfo_partnersProgram_generalInformation_landingMainPage_partnersLogos } from "gql/types/operation-result-types";
import { useTranslation } from "react-i18next";
import { Wrapper, WrapperFullWidth } from "styled/layouts";
import { OurPartner } from "./our-partner/index";
import { OurPartnersSC, OurPartnersTitle } from "./styled";
interface IProps {
    partnersLogos: GetPartnersProgramGeneralInfo_partnersProgram_generalInformation_landingMainPage_partnersLogos[];
}

export const OurPartners = React.memo((props: IProps) => {
    const [__] = useTranslation();
    return (
        <WrapperFullWidth>
            <Wrapper>
                <OurPartnersTitle>{__("Наши партнеры")}</OurPartnersTitle>
                <OurPartnersSC>
                    {props.partnersLogos.map((logo, index) => (
                        <AnimateWhenInViewport
                            key={index}
                            animateType="fadeInUpShort"
                            animateDelay={index * 250}
                        >
                            <OurPartner
                                ourPartnerBG={logo.partnerBG}
                                ourPartnerImg={logo.partnerImg}
                                ourPartnerLink={logo.partnerLink}
                                secondBG={logo.secondBG}
                            />
                        </AnimateWhenInViewport>
                    ))}
                </OurPartnersSC>
            </Wrapper>
        </WrapperFullWidth>
    );
}) as React.ComponentType<IProps>;
