import { PRO1BET_AFFILIATES } from "server/partner-programs";

export const getSpecialTelegramName = (partnerId: string) => {
    const names = {
        [PRO1BET_AFFILIATES]: "Support-Pro1BetAffiliates",
    };

    if (partnerId in names) {
        return names[partnerId as keyof typeof names];
    }
};
