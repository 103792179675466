import { useEffect } from "react";
import { MenuLanding } from "components/menu/menu-landing";
import { connect } from "react-redux";
import { Action } from "redux";
import { statesSelectors } from "services/redux/root/states/selectors";
import { OnlyMobile } from "./only-mobile-items/styled";
import { MenuSC } from "./styled";
import { useDefinedContext } from "hooks/use-context-exist";
import { PartnersProgramGeneralInformationContext } from "views";
import { LanguageSwitcher } from "components/components-common/language/language-switcher";

interface IInjectedProps {
    isMenuBurgerClickedOnLanding: boolean;
    setIsMenuBurgerClickedOnLanding: (visibility: boolean) => Action;
}

interface IProps extends IInjectedProps {}

export const Menu = connect(state => ({
    isMenuBurgerClickedOnLanding:
        statesSelectors.getIsMenuBurgerClickedOnLanding(state),
}))(
    React.memo(({ isMenuBurgerClickedOnLanding }: IProps) => {
        const partnersGeneralInformation = useDefinedContext(
            PartnersProgramGeneralInformationContext,
        );

        useEffect(() => {
            document.body.style.overflow = isMenuBurgerClickedOnLanding
                ? "hidden"
                : "auto";

            return () => {
                document.body.style.overflow = "auto";
            };
        }, [isMenuBurgerClickedOnLanding]);

        return (
            <MenuSC>
                <MenuLanding />
                <OnlyMobile>
                    <LanguageSwitcher
                        textColor={
                            partnersGeneralInformation.stylesTheme.textColor
                        }
                        bgColor={
                            partnersGeneralInformation.stylesTheme
                                .languageSwitcherBG
                        }
                        accentColor={
                            partnersGeneralInformation.stylesTheme.accentColor
                        }
                        screen={"mobile"}
                    />
                </OnlyMobile>
            </MenuSC>
        );
    }),
) as React.ComponentType<Subtract<IProps, IInjectedProps>>;
