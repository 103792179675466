import React from "react";
import { CountryField as _CountryField } from "components/filters/fields/country";
import { FormikHandlers } from "components/formik";
import { useTranslation } from "react-i18next";

interface IProps {
    fieldOnChange?: FormikHandlers["handleChange"];
    label?: string;
    selectFirst?: boolean;
}

export function CountryField(props: IProps) {
    const [__] = useTranslation();
    return (
        <_CountryField
            name="country"
            label={props.label || `${__("Страна")} *`}
            key="country"
            selectFirst={props.selectFirst}
            isClearable={false}
            fieldOnChange={props.fieldOnChange}
        />
    );
}
