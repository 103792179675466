import styled from "styled-components";
import { media } from "services/styled-components/variables";

export const ProductsWrapper = styled.div`
    padding-bottom: 50px;
    text-align: left;

    ${media.mdMax`
       padding-bottom: 0;
    `}
`;
