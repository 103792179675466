import { AnimateWhenInViewport } from "components/components-common/animate";
import { useDefinedContext } from "hooks/use-context-exist";
import { Wrapper } from "styled/layouts";
import { PartnersProgramGeneralInformationContext } from "views";
import { MobileBackgroundImage, WrapperFullWidthFirstScreen } from "./styled";
import {
    _1XBET_PARTNERS_IRELAND,
    _1XCASINO_PARTNERS,
} from "server/partner-programs";

interface IProps {
    children: JSX.Element;
}

export const FirstScreen = React.memo(({ children }: IProps) => {
    const { landingMainPage, partnersProgramId } = useDefinedContext(
        PartnersProgramGeneralInformationContext,
    );

    if (!landingMainPage) {
        return null;
    }

    const animateDelay =
        partnersProgramId !== _1XBET_PARTNERS_IRELAND ? 1500 : 0;

    return (
        <WrapperFullWidthFirstScreen>
            <Wrapper>
                <AnimateWhenInViewport
                    animateType="bounceInRight"
                    animateDelay={animateDelay}
                >
                    {children}
                </AnimateWhenInViewport>
            </Wrapper>
            {partnersProgramId === _1XCASINO_PARTNERS && (
                <MobileBackgroundImage src="/config-files/main_mobile" alt="" />
            )}
        </WrapperFullWidthFirstScreen>
    );
}) as React.ComponentType<IProps>;
