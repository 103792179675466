import { useTranslation } from "react-i18next";
import { WrapperFullWidth, DecorLine } from "styled/layouts";
import { MessengersBlock } from "./messengers-block";
import { ItemWrapper, RightContainer, Title, WrapperMainInfo } from "./styled";

export const MainInformation = () => {
    const [__] = useTranslation();

    const CompanyName = "Coinplay";

    const items = [
        {
            image: "/config-files/infinite-1",
            title: __("Пожизненная комиссия"),
            text: format(
                __(
                    "Ты получаешь комиссию с привлеченных игроков всякий раз, когда они ставят в {CompanyName}. Без ограничений по времени. Комиссия растёт вместе с твоей прибылью.",
                ),
                {
                    CompanyName,
                },
            ),
        },
        {
            image: "/config-files/regular-2",
            title: __("Регулярные и удобные выплаты"),
            text: __(
                "Выплата комиссии производится каждые 7 дней. Выбирай то, что удобно тебе.",
            ),
        },
        {
            image: "/config-files/tools-3",
            title: __("Широкий выбор промо-инструментов"),
            text: __(
                "Обои для сайтов, анимированные и статичные баннеры, персональные промокоды. У тебя будет доступ к нашей базе промо-материалов, созданных для самых разных каналов траффика.",
            ),
        },
        {
            image: "/config-files/course-4",
            title: __("Будь всегда в курсе событий"),
            text: __(
                "Вся необходимая информация и подробная статистика твоих результатов предоставляется в режиме реального времени.",
            ),
        },
        {
            image: "/config-files/unical-5",
            title: __("Особым партнёрам — особые условия"),
            text: __(
                "Для СРА сетей и арбитражных команд мы рассматриваем индивидуальные условия сотрудничества. Мы с радостью обсудим их с Вами. Пожалуйста, свяжитесь с нами.",
            ),
            component: <MessengersBlock />,
        },
    ];

    return (
        <WrapperFullWidth>
            <WrapperMainInfo>
                {items.map(el => (
                    <ItemWrapper key={el.title}>
                        <img src={el.image} alt={el.title} loading="lazy" />
                        <RightContainer>
                            <Title>{el.title}</Title>
                            <DecorLine />
                            <div>{el.text}</div>
                            {el.component}
                        </RightContainer>
                    </ItemWrapper>
                ))}
            </WrapperMainInfo>
        </WrapperFullWidth>
    );
};
