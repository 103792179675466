import { useTranslation } from "react-i18next";
import { Field } from "components/formik";

export function ConfirmPasswordField() {
    const [__] = useTranslation();
    return (
        <Field
            name="confirmPassword"
            type="password"
            label={`${__("Повторите пароль")} *`}
        />
    );
}
