import { GetCountries_countries } from "gql/types/operation-result-types";
import { usePaymentSystemsWithFields } from "hooks/query/use-payment-systems-with-fields";

export function usePaymentSystemsForRegistration() {
    const { paymentSystems: allPaymentSystems, validateField } =
        usePaymentSystemsWithFields(false);
    const paymentSystems = allPaymentSystems.filter(ps => ps.fields.length < 2);

    // Специальное условие для Украины.
    // Если было пределено что страна - Украина,
    // то проставляем украинскую платежную систему
    const UA_PAYMENT_SYSTYEM_ID = 27;
    const uaSystemIndex = paymentSystems.findIndex(
        ps => ps.id === UA_PAYMENT_SYSTYEM_ID,
    );
    function isUAandHasUaSystem(country: GetCountries_countries | null) {
        return country?.code === "UA" && uaSystemIndex >= 0;
    }

    function getOptions(country: GetCountries_countries | null) {
        let sortedSystems = [...paymentSystems];
        if (isUAandHasUaSystem(country)) {
            sortedSystems = [
                sortedSystems[uaSystemIndex],
                ...sortedSystems.slice(0, uaSystemIndex),
                ...sortedSystems.slice(uaSystemIndex + 1),
            ];
        }
        return sortedSystems.map(ps => ({
            label: ps.name,
            value: ps,
        }));
    }

    function getInitialPaymentSystem(country: GetCountries_countries | null) {
        let initialPaymentSystem = paymentSystems[0] ? paymentSystems[0] : null;

        if (isUAandHasUaSystem(country)) {
            initialPaymentSystem = paymentSystems[uaSystemIndex];
        }

        return initialPaymentSystem;
    }

    function changePaymentSystemIfUkraine(
        paymentSystem: typeof paymentSystems[0] | null,
        country: GetCountries_countries,
    ) {
        if (isUAandHasUaSystem(country)) {
            return paymentSystems[uaSystemIndex];
        } else {
            return paymentSystem;
        }
    }

    function validatePaymentSystemField(
        paymentSystem: typeof paymentSystems[0] | null,
        errors: Record<string, any>,
    ) {
        const paymentSystemField = paymentSystem?.fields[0];
        if (paymentSystemField) {
            const paymentSystemErrorMsg = validateField(
                paymentSystemField,
                paymentSystemField.value,
            );
            if (paymentSystemErrorMsg) {
                errors.paymentSystem = {
                    ...errors.paymentSystem,
                    fields: [{ value: paymentSystemErrorMsg }],
                };
            }
        }
    }

    return {
        getOptions,
        getInitialPaymentSystem,
        changePaymentSystemIfUkraine,
        validateField,
        validatePaymentSystemField,
    };
}
