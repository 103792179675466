import { SubTitleText } from "./styled";
import { useDefinedContext } from "hooks/use-context-exist";
import { PartnersProgramGeneralInformationContext } from "views";

export const SubTitle = () => {
    const { agentSiteInformation } = useDefinedContext(
        PartnersProgramGeneralInformationContext,
    );

    return (
        <SubTitleText>
            <p>Зарабатывай деньги вместе с нами!</p>
            <p>
                Арбитражник? Вебмастер? Администратор групп в социальных сетях?
            </p>
            <p>
                Начни зарабатывать вместе с {agentSiteInformation.companyName}.
            </p>
        </SubTitleText>
    );
};
