import { CSSTransition } from "react-transition-group";
import { Answer, TabQuestion } from "./styled";
import { useState } from "react";

export const QuestionContent = (props: {
    defaultState: boolean;
    question: string;
    answer: string | null;
    questionTextColor: string;
    answerTextColor: string;
    image: string | null;
}) => {
    const [isOpenQuestion, setIsOpenQuestion] = useState(props.defaultState);
    return (
        <>
            <TabQuestion
                questionTextColor={props.questionTextColor}
                onClick={() => setIsOpenQuestion(!isOpenQuestion)}
                dangerouslySetInnerHTML={{
                    // eslint-disable-next-line @typescript-eslint/naming-convention
                    __html: props.question,
                }}
            />
            <CSSTransition
                in={isOpenQuestion}
                timeout={300}
                classNames="answer-content"
                unmountOnExit
            >
                <>
                    {props.answer && (
                        <>
                            <Answer
                                answerTextColor={props.answerTextColor}
                                dangerouslySetInnerHTML={{
                                    // eslint-disable-next-line @typescript-eslint/naming-convention
                                    __html: props.answer,
                                }}
                            />
                            {props.image && <img src={props.image} />}
                        </>
                    )}
                </>
            </CSSTransition>
        </>
    );
};
