import moment from "moment";
import { useTranslation } from "react-i18next";
import { OneNewsImg } from "./one-news-img";
import {
    OneNewsIntroduction,
    OneNewsMore,
    OneNewsSC,
    OneNewsTime,
    OneNewsTitle,
} from "./styles";
import { Variables } from "services/variables";

interface IProps {
    t?: any;
    oneNewsLink: string;
    oneNewsImg: string;
    oneNewsTitle: string;
    oneNewsTime: string;
    oneNewsIntroduction: string;
    language: string;
    textColor?: string;
}

interface IInjectedProps {}

interface IProps extends IInjectedProps {}

export const OneNews = React.memo((props: IProps) => {
    const [__] = useTranslation();
    return (
        <OneNewsSC to={props.oneNewsLink}>
            {props.oneNewsImg && (
                <OneNewsImg
                    src={`${Variables.fileStoragePath}/${props.oneNewsImg}`}
                />
            )}
            <OneNewsTime>
                {moment(props.oneNewsTime).locale(props.language).format("L")}
            </OneNewsTime>
            <OneNewsTitle textColor={props.textColor}>
                {props.oneNewsTitle}
            </OneNewsTitle>
            <OneNewsIntroduction textColor={props.textColor}>
                {props.oneNewsIntroduction}
            </OneNewsIntroduction>
            <OneNewsMore>{__("Подробнее")}</OneNewsMore>
        </OneNewsSC>
    );
}) as React.ComponentType<Subtract<IProps, IInjectedProps>>;
