import { TermsAndConditions } from "gql/types/operation-result-types";
import { useQuery, gql } from "@apollo/client";

const GET_TERMS_AND_CONDITIONS = gql`
    query TermsAndConditions {
        partnersProgram {
            termsAndConditions
        }
    }
`;

export const useTermsAndConditions = () => {
    const { data, loading } = useQuery<TermsAndConditions>(
        GET_TERMS_AND_CONDITIONS,
        {
            ssr: true,
        },
    );
    let text = data?.partnersProgram?.termsAndConditions || "";
    text = text.replace(/<p>&nbsp;<\/p>/gm, "").replace(/&nbsp;/gm, "");

    return {
        text,
        loading,
    };
};
