import { RouteComponentProps } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Lock } from "@styled-icons/fa-solid/Lock/Lock";
import { AuthLayout } from "../auth-layout";
import { SignIn } from "../sign-in-form/styled";
import { NewPasswordForm } from "./new-password-form";
import { DefaultTheme, StyledComponent } from "styled-components";
import {
    FormDirectionColumn,
    FormGroup,
    FormGroupButton,
} from "styled/layouts";

interface IProps extends RouteComponentProps {
    signBox?: StyledComponent<"div", DefaultTheme>;
}

export function DefaultNewPasswordForm(props: IProps) {
    const [__] = useTranslation();

    return (
        <AuthLayout signBox={props.signBox}>
            <SignIn>
                <NewPasswordForm {...props}>
                    <FormDirectionColumn>
                        <FormGroup>
                            <Lock size={18} />
                            <NewPasswordForm.NewPassword />
                        </FormGroup>
                        <FormGroup>
                            <Lock size={18} />
                            <NewPasswordForm.RepeatPassword />
                        </FormGroup>
                        <FormGroupButton>
                            <NewPasswordForm.SubmitButton>
                                {__("Изменить пароль")}
                            </NewPasswordForm.SubmitButton>
                        </FormGroupButton>
                    </FormDirectionColumn>
                </NewPasswordForm>
            </SignIn>
        </AuthLayout>
    );
}
